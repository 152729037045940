import React, { Component } from "react";
import "./HomeLocationWidget.css";
import PhoneIcon from "../../images/assets/phone-30.png";
import TargetIcon from "../../images/assets/teal-map-marker_edit.png";
import { connect } from "react-redux";
import { RESET_COOR, SET_COOR } from "../../sagas/actions";
import BookNowButton from "../Nav/BookNowButton";
import "./Locations.css";
import { SWEATWORKS_URL } from "../../TestingVariables";

class LocationsCards extends Component {
  createCards = (locations) => {
    return locations.map((location, i) => (
      <div className="location-card-wrapper" key={i}>
        <div className="card-section">
          <div className="location-card-title">{location.title}</div>
          <div className="location-address-group">
            <div className="LocationAddressText">
              {location.addressFH}
              <br />
              {location.addressSH}
            </div>
            <div>
              <a href={`${SWEATWORKS_URL}/book`}>
                <BookNowButton />
              </a>
            </div>
          </div>
        </div>
        <div className="CardBottomSection">
          <div className="CardPhoneGroup">
            <a href={`tel:${location.phoneNumber}`}>
              <img
                src={PhoneIcon}
                alt="Map Icon"
                className="LocationCardIcon"
              />
            </a>
            <a
              href={`tel:${location.phoneNumber}`}
              className="LocationPhoneText"
            >
              {location.phoneNumber}
            </a>
          </div>
          <div
            className="location-card-target-group"
            onClick={() => {
              this.props.handleLocationClick(location.lat, location.lng);
            }}
          >
            <div>Locate</div>
            <img
                  className="TargetIcon"
                  src={TargetIcon}
                  alt="Center Map to Location"
                  onClick={() => {
                    this.handleLocationClick(location.lat, location.lng);
                  }}
                />
          </div>
        </div>
      </div>
    ));
  };

  mapLocations = () => {
    return this.props.locations.map((state, i) => {
      return (
        <div className="locations-state-wrapper" key={i}>
          <div className="locations-state-title-wrapper ColorCharcoal TitleFont XL">
            {state.title}
          </div>
          <div className="location-cards-wrapper">
            {this.createCards(state.locations)}
          </div>
        </div>
      );
    });
  };

  render() {
    return <div className="locations-wrapper">{this.mapLocations()}</div>;
  }
}
const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetCoor: () => {
      dispatch({
        type: RESET_COOR,
      });
    },
    setCoor: (payload) => {
      dispatch({
        type: SET_COOR,
        payload: payload,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationsCards);
