import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import { REQUEST_ADD_UVB_LIGHT, SEND_REQUEST } from "../../sagas/actions";
import addUvbLightApiCall from "../../modules/api/addUvbLightApiCall";
// import { Button } from "react-bootstrap";
import UvbButtonStatusIconManager from "./UvbButtonStatusIconManager";
import MessageBox from "./MessageBox";

export class AddUvbLightView extends Component {
  render() {
    return (
      <div className="AddUvbLightViewWrapper">
        <h2 className="CardBodyText">Would you like to add UVB Light to your appointment?</h2>
        <h3 className="CardBodyText">A $5 charge will be added to your account.</h3>
        <div className="ButtonWrapper">
          <button
            className={
              !this.props.reduxState.uvbLightMessageBox.buttonDisabled ? "access-button" : "btn btn-primary-outline"
            }
            type="button"
            size="medium"
            // className="AccessButtons"
            style={{ zIndex: 0, minWidth: "150px", minHeight: "50px" }}
            disabled={this.props.reduxState.accessMessageBox.buttonDisabled}
            id="addUvbLightButton"
            onClick={() => {
              this.props.addUvbLight(this.props.reduxState.access.data.accessKey);
            }}>
            ADD UVB
          </button>
          <UvbButtonStatusIconManager className="ButtonStatusIcon" id="addRedLightIconStaus" />
        </div>
        {this.props.reduxState.uvbLightMessageBox.message ? (
          <MessageBox message={this.props.reduxState.uvbLightMessageBox.message} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addUvbLight: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_ADD_UVB_LIGHT,
        apiCall: addUvbLightApiCall,
        apiCallParams: { accessKey: accessKey },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUvbLightView);
