export const SEND_REQUEST = "SEND_REQUEST";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const REQUEST_USER_ACCESS_DATA = "REQUEST_USER_ACCESS_DATA";
export const REQUEST_USER_ACCESS_DATA_LOADING =
    "REQUEST_USER_ACCESS_DATA_LOADING";
export const REQUEST_USER_ACCESS_DATA_SUCCESS =
    "REQUEST_USER_ACCESS_DATA_SUCCESS";
export const REQUEST_USER_ACCESS_DATA_FAILURE =
    "REQUEST_USER_ACCESS_DATA_FAILURE";
export const REQUEST_ENTRY_UNLOCK_LOADING = "REQUEST_ENTRY_UNLOCK_LOADING";
export const REQUEST_ENTRY_UNLOCK_SUCCESS = "REQUEST_ENTRY_UNLOCK_SUCCESS";
export const REQUEST_ENTRY_UNLOCK_FAILURE = "REQUEST_ENTRY_UNLOCK_FAILURE";
export const REQUEST_ENTRY_UNLOCK_RESET = "REQUEST_ENTRY_UNLOCK_RESET";
export const UPDATE_ICON_STATUS_ACCESS_FRONT_DOOR =
    "UPDATE_ICON_STATUS_ACCESS_FRONT_DOOR";
export const UPDATE_ICON_STATUS_ACCESS_TOWEL_DOOR =
    "UPDATE_ICON_STATUS_ACCESS_TOWEL_DOOR";
export const REQUEST_ENTRY_UNLOCK = "REQUEST_ENTRY_UNLOCK";
export const REQUEST_SAUNA_RESET = "REQUEST_SAUNA_RESET";
export const ACCESS_MESSAGE_BOX_RESET = "ACCESS_MESSAGE_BOX_RESET";
export const ACCESS_MESSAGE_BOX_NOT_YET = "ACCESS_MESSAGE_BOX_NOT_YET";
export const ACCESS_MESSAGE_BOX_EXPIRED = "ACCESS_MESSAGE_BOX_EXPIRED";
export const ACCESS_MESSAGE_BOX_INVALID = "ACCESS_MESSAGE_BOX_INVALID";
export const ACCESS_MESSAGE_BOX_FAILED = "ACCESS_MESSAGE_BOX_FAILED";
export const ACCESS_MESSAGE_BOX_DISABLED = "ACCESS_MESSAGE_BOX_DISABLED";
export const ACCESS_MESSAGE_BOX_UNAUTHORIZED = "ACCESS_MESSAGE_BOX_UNAUTHORIZED";

export const SAUNA_CONTROLS_BOX_RESET = "SAUNA_CONTROLS_MESSAGE_BOX_RESET";
export const SAUNA_CONTROLS_BOX_NOT_YET = "SAUNA_CONTROLS_MESSAGE_BOX_NOT_YET";
export const SAUNA_CONTROLS_BOX_EXPIRED = "SAUNA_CONTROLS_BOX_EXPIRED";
export const SAUNA_CONTROLS_BOX_INVALID = "SAUNA_CONTROLS_BOX_INVALID";
export const SAUNA_CONTROLS_BOX_FAILED = "SAUNA_CONTROLS_BOX_FAILED";


export const REQUEST_ADD_RED_LIGHT = "REQUEST_ADD_RED_LIGHT";
export const REQUEST_ADD_UVB_LIGHT = "REQUEST_ADD_UVB_LIGHT";
export const REQUEST_ADD_TOWEL_SERVICE = "REQUEST_ADD_TOWEL_SERVICE";
export const RED_LIGHT_MESSAGE_BOX_RESET = "RED_LIGHT_MESSAGE_BOX_RESET";
export const RED_LIGHT_MESSAGE_BOX_EXPIRED = "RED_LIGHT_MESSAGE_BOX_EXPIRED";
export const RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR = "RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR";
export const RED_LIGHT_MESSAGE_BOX_INVALID = "RED_LIGHT_MESSAGE_BOX_INVALID";
export const RED_LIGHT_MESSAGE_BOX_DISABLED = "RED_LIGHT_MESSAGE_BOX_DISABLED";
export const REQUEST_ADD_RED_LIGHT_LOADING = "REQUEST_ADD_RED_LIGHT_LOADING";
export const REQUEST_ADD_RED_LIGHT_SUCCESS = "REQUEST_ADD_RED_LIGHT_SUCCESS";
export const REQUEST_ADD_RED_LIGHT_FAILURE = "REQUEST_ADD_RED_LIGHT_FAILURE";
export const REQUEST_ADD_RED_LIGHT_RESET = "REQUEST_ADD_RED_LIGHT_RESET";
export const UVB_LIGHT_MESSAGE_BOX_RESET = "UVB_LIGHT_MESSAGE_BOX_RESET";
export const UVB_LIGHT_MESSAGE_BOX_EXPIRED = "UVB_LIGHT_MESSAGE_BOX_EXPIRED";
export const UVB_LIGHT_MESSAGE_BOX_INTERNAL_ERROR = "UVB_LIGHT_MESSAGE_BOX_INTERNAL_ERROR";
export const UVB_LIGHT_MESSAGE_BOX_INVALID = "UVB_LIGHT_MESSAGE_BOX_INVALID";
export const UVB_LIGHT_MESSAGE_BOX_DISABLED = "UVB_LIGHT_MESSAGE_BOX_DISABLED";
export const REQUEST_ADD_UVB_LIGHT_LOADING = "REQUEST_ADD_UVB_LIGHT_LOADING";
export const REQUEST_ADD_UVB_LIGHT_SUCCESS = "REQUEST_ADD_UVB_LIGHT_SUCCESS";
export const REQUEST_ADD_UVB_LIGHT_FAILURE = "REQUEST_ADD_UVB_LIGHT_FAILURE";
export const REQUEST_ADD_UVB_LIGHT_RESET = "REQUEST_ADD_UVB_LIGHT_RESET";
export const TOWEL_SERVICE_MESSAGE_BOX_RESET = "TOWEL_SERVICE_MESSAGE_BOX_RESET";
export const TOWEL_SERVICE_MESSAGE_BOX_EXPIRED = "TOWEL_SERVICE_MESSAGE_BOX_EXPIRED";
export const TOWEL_SERVICE_MESSAGE_BOX_INTERNAL_ERROR = "TOWEL_SERVICE_MESSAGE_BOX_INTERNAL_ERROR";
export const TOWEL_SERVICE_MESSAGE_BOX_INVALID = "TOWEL_SERVICE_MESSAGE_BOX_INVALID";
export const TOWEL_SERVICE_MESSAGE_BOX_DISABLED = "TOWEL_SERVICE_MESSAGE_BOX_DISABLED";
export const REQUEST_ADD_TOWEL_SERVICE_LOADING = "REQUEST_ADD_TOWEL_SERVICE_LOADING";
export const REQUEST_ADD_TOWEL_SERVICE_SUCCESS = "REQUEST_ADD_TOWEL_SERVICE_SUCCESS";
export const REQUEST_ADD_TOWEL_SERVICE_FAILURE = "REQUEST_ADD_TOWEL_SERVICE_FAILURE";
export const REQUEST_ADD_TOWEL_SERVICE_RESET = "REQUEST_ADD_TOWEL_SERVICE_RESET";
export const RED_LIGHT_ADDED = "RED_LIGHT_ADDED";
export const UVB_LIGHT_ADDED = "UVB_LIGHT_ADDED";
export const TOWEL_SERVICE_ADDED = "TOWEL_SERVICE_ADDED";
export const ADD_TOWEL_ACCESS_BUTTON = "ADD_TOWEL_ACCESS_BUTTON";
//SWEATWORKS MAIN WEBSITE
export const RESET_COOR = "RESET_COOR";
export const SET_COOR = "SET_COOR";
export const SET_POSITION = "SET_POSITION";
export const INITIALIZE_BENEFIT_STATUSES = "INITIALIZE_BENEFIT_STATUSES";
export const OPEN_BENEFIT = "OPEN_BENEFIT";
export const CLOSE_BENEFIT = "CLOSE_BENEFIT";
export const CLOSE_ALL_BENEFITS = "CLOSE_ALL_BENEFITS";
//EMPLOYEE INTERFACE
//eiAuth
export const GET_EI_AUTH_TOKEN = "GET_EI_AUTH_TOKEN";
export const SET_EI_AUTH_TOKEN = "SET_EI_AUTH_TOKEN";
//eiView
export const EI_SET_VIEW_LOCATIONS = "EI_SET_VIEW_LOCATIONS";
export const EI_SET_VIEW_LOGIN = "EI_SET_VIEW_LOGIN";
export const EI_SET_VIEW_ROOM = "EI_SET_VIEW_ROOM";
//loginMessageBox
export const EI_LOGIN_MESSAGE_BOX_RESET = "EI_LOGIN_MESSAGE_BOX_RESET";
export const EI_LOGIN_MESSAGE_BOX_INVALID = "EI_LOGIN_MESSAGE_BOX_INVALID";
export const EI_LOGIN_MESSAGE_BOX_EXPIRED = "EI_LOGIN_MESSAGE_BOX_EXPIRED";
export const EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR =
    "EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR";
//signupMessageBox
export const EI_SIGNUP_MESSAGE_BOX_RESET = "EI_SIGNUP_MESSAGE_BOX_RESET";
export const EI_SIGNUP_MESSAGE_BOX_SUCCESS = "EI_SIGNUP_MESSAGE_BOX_SUCCESS";
export const EI_SIGNUP_MESSAGE_BOX_FAILED = "EI_SIGNUP_MESSAGE_BOX_FAILED";
export const EI_SIGNUP_MESSAGE_BOX_INVALID = "EI_SIGNUP_MESSAGE_BOX_INVALID";
export const EI_SIGNUP_MESSAGE_BOX_INTERNAL_ERROR = "EI_SIGNUP_MESSAGE_BOX_INTERNAL_ERROR";
//login loading statuses
export const EI_LOGIN = "EI_LOGIN";
export const EI_LOGIN_LOADING = "EI_LOGIN_LOADING";
export const EI_LOGIN_SUCCESS = "EI_LOGIN_SUCCESS";
export const EI_LOGIN_FAILURE = "EI_LOGIN_FAILURE";
export const EI_LOGIN_RESET = "EI_LOGIN_RESET";
//signup loading statuses
export const EI_SIGNUP = "EI_SIGNUP";
export const EI_SIGNUP_LOADING = "EI_SIGNUP_LOADING";
export const EI_SIGNUP_SUCCESS = "EI_SIGNUP_SUCCESS";
export const EI_SIGNUP_FAILURE = "EI_SIGNUP_FAILURE";
export const EI_SIGNUP_RESET = "EI_SIGNUP_RESET";
//saunas
export const EI_SAUNAS = "EI_SAUNAS";
export const EI_SAUNAS_LOADING = "EI_SAUNAS_LOADING";
export const EI_SAUNAS_SUCCESS = "EI_SAUNAS_SUCCESS";
export const EI_SAUNAS_FAILURE = "EI_SAUNAS_FAILURE";
export const EI_SET_SAUNAS = "EI_SET_SAUNAS";
//saunaData
export const EI_SAUNA_DATA = "EI_SAUNA_DATA";
export const EI_SAUNA_DATA_LOADING = "EI_SAUNA_DATA_LOADING";
export const EI_SAUNA_DATA_SUCCESS = "EI_SAUNA_DATA_SUCCESS";
export const EI_SAUNA_DATA_FAILED = "EI_SAUNA_DATA_FAILED";
export const EI_SAUNA_DATA_RESET = "EI_SAUNA_DATA_RESET";
export const EI_SET_SAUNA_DATA = "EI_SET_CURRENT_SAUNA_DATA";

export const EI_AUTOMATIONS_UPDATE = "EI_AUTOMATIONS_UPDATE";
export const EI_UPDATE_SAUNA_DATA_AUTOMATIONS = "EI_UPDATE_SAUNA_DATA_AUTOMATIONS";

export const EI_SET_COMMANDS_MESSAGE = "EI_SET_COMMANDS_MESSAGE";
export const EI_RESET_COMMANDS_MESSAGE = "EI_RESET_COMMANDS_MESSAGE";

export const EI_ROOM_COMMAND = "EI_ROOM_COMMAND";
export const EI_ROOM_COMMAND_LOADING = "EI_ROOM_COMMAND_LOADING";
export const EI_ROOM_COMMAND_SUCCESS = "EI_ROOM_COMMAND_SUCCESS";
export const EI_ROOM_COMMAND_FAILED = "EI_ROOM_COMMAND_FAILED";

export const EI_LOCATION_DATA = "EI_LOCATION_DATA";
export const EI_LOCATION_DATA_LOADING = "EI_LOCATION_DATA_LOADING";
export const EI_LOCATION_DATA_SUCCESS = "EI_LOCATION_DATA_SUCCESS";
export const EI_LOCATION_DATA_FAILED = "EI_LOCATION_DATA_FAILED";
export const EI_RESET_LOCATION_DATA = "EI_RESET_LOCATION_DATA";
export const EI_SET_LOCATION_DATA = "EI_SET_LOCATION_DATA";
export const EI_REQUEST_UPDATE_LOCATION_DATA = "EI_REQUEST_UPDATE_LOCATION_DATA";

export const EI_SET_SCHEDULE_MESSAGE = "EI_SET_SCHEDULE_MESSAGE";
export const EI_RESET_SCHEDULE_MESSAGE = "EI_RESET_SCHEDULE_MESSAGE";

export const EI_REQUEST_ADD_SCHEDULE_EXCEPTION = "EI_REQUEST_ADD_SCHEDULE_EXCEPTION";

// ACCESS SYSTEMS
export const EI_UNLOCK_ENTRY = "EI_UNLOCK_ENTRY";

// SWEATWORKS STUDIO
export const SEND_LOCATION_DATA = "SEND_LOCATION_DATA";