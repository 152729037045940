import CollagenIcon from "../../images/assets/custom/SW_3_collagen.png";
import PainIcon from "../../images/assets/custom/SW_1_pain.png";
import DefenseIcon from "../../images/assets/custom/SW_1_medical-protection.png";
import BrainIcon from "../../images/assets/custom/SW_1_power.png";
import GendersIcon from "../../images/assets/custom/SW_2_genders.png";
import MuscleIcon from "../../images/assets/custom/SW_1_muscle.png";

const BenefitsContent = [
  {
    id: 1,
    title: "Skin Therapy",
    url: "collagen",
    content:
      `It is not just our skin that thrives off healthy
      collagen. In fact, did you know that 30% of your
      body is protein and 90% of that protein is collagen!?
      It is your main building block for tendons, skin,
      elasticity of muscles and blood vessels, our organs,
      and our bones!

      By stimulating the fibroblasts that make collagen,
      Red light and near infrared help to create more
      collagen and which leads to healthier skin and so
      much more!`,
    icon: CollagenIcon,
    position: {
      icon: { id: 2, top: "0%", left: "74%", facing: "left" },
      pointer: {
        lineOne: 80,
        topOne: 50,
        leftOne: 30,
        angleOne: 45,
        lineTwo: 75,
        topTwo: -28,
        leftTwo: 68,
        angleTwo: 135,
      },
    },
  },
  {
    id: 2,
    title: "Immune Boost",
    url: "immune_system",
    content:
      `
      The culmination of red light and near infrared
      benefits result in an immune system that is more
      robust. The increased function of the powerhouse of
      your cells (mitochondria), the increased function and
      number of capillaries (blood flow), and the reduced
      inflammation all result in an immune system that
      has the tools to maximize its ability to do its job.
      Thyroid function also is improved under red light
      which has a direct impact on the immune function of the body!
      `,
    icon: DefenseIcon,
    position: {
      icon: { id: 4, top: "70%", left: "75%", facing: "left" },
      pointer: {
        lineOne: 90,
        topOne: -30,
        leftOne: 40,
        angleOne: -45,
        lineTwo: 50,
        topTwo: 15,
        leftTwo: 80,
        angleTwo: 45,
      },
    },
  },
  {
    id: 3,
    title: "Brain Health",
    url: "brain_health",
    content:
      `Known for its benefits in treating traumatic brain
      injury, near infrared light has the ability to heal,
      regenerate, and protect brain tissue. The ability to
      increase cellular oxygen creates healthier and
      metabolically stronger cells that not only help
      unhealthy brain tissue but can reduce inflammation
      enhance brain health in any individual. Red light
      has the ability to improve sleep and can be a
      powerful tool in stress reduction through its brain
      boosting benefits.`,
    icon: BrainIcon,
    position: {
      icon: { id: 5, top: "-15%", left: "65%", facing: "right" },
      pointer: {
        lineOne: 80,
        topOne: 30,
        leftOne: -110,
        angleOne: 135,
        lineTwo: 30,
        topTwo: 9,
        leftTwo: 76,
        angleTwo: 45,
      },
    },
  },
  {
    id: 4,
    title: "Ease Your Aches",
    url: "pain_relief",
    content:
      `The key to the efficacy of infrared light therapy may
      be nitric oxide, a gas that is vital to the health of the
      body’s blood vessels. Nitric oxide is a potent cell
      signaling molecule that helps relax the arteries,
      battles free radicals to reduce oxidative stress,
      prevents platelet clumping in the vessels, and
      regulates the blood pressure. Hence, this molecule
      enhances blood circulation to deliver vital nutrients
      and oxygen to damaged and injured tissues in the
      body.`,
    icon: PainIcon,
    position: {
      icon: { id: 6, top: "5%", left: "78%", facing: "right" },
      pointer: {
        lineOne: 80,
        topOne: 30,
        leftOne: -120,
        angleOne: -225,
        lineTwo: 60,
        topTwo: 20,
        leftTwo: 70,
        angleTwo: 45,
      },
    },
  },
  {
    id: 5,
    title: "Muscle Recovery",
    url: "muscle_repair",
    content:
      `By stimulating, healing, and regenerating tissue,
      near infrared light has shown to increase repair to
      acutely damaged muscle tissue after workouts.
      The direct impact of red light to absorb cellular
      oxygen increases the energy of the cell by way of
      increased ATP production which enables muscle
      cells to function properly and effectively. This
      therapy stimulates the regeneration and repair of
      injured tissues, reducing pain and inflammation.`,
    icon: MuscleIcon,
    position: {
      icon: { id: 7, top: "34%", left: "80%", facing: "right" },
      pointer: {
        lineOne: 100,
        topOne: 10,
        leftOne: -160,
        angleOne: 0,
        lineTwo: 0,
        topTwo: 35,
        leftTwo: 65,
        angleTwo: 45,
      },
    },
  },
  {
    id: 6,
    title: "Reproductive Health",
    url: "reproductive_support",
    content:
      `Multiple studies have concluded that red light
      increases motility and quality of sperm. An increase
      of testosterone and blood flow also can improve
      sexual function in men. Studies have also concluded
      an increase of oocytes production . An oocyte is the
      very beginning of human life - an immature egg
      cell. The potential of red light and near infrared to
      improve reproductive health in multiple facets is
      promising!`,
    icon: GendersIcon,
    position: {
      icon: { id: 8, top: "60%", left: "70%", facing: "right" },
      pointer: {
        lineOne: 100,
        topOne: 15,
        leftOne: -130,
        angleOne: 0,
        lineTwo: 0,
        topTwo: 10,
        leftTwo: 76,
        angleTwo: 45,
      },
    },
  },
];

export { BenefitsContent };
