import React, { Component } from "react";
import { AccessNavBar } from "../Nav/AccessNavBar";
import "./AccessContent.css";
import SweatWorksBackgroundImage from "../../images/SweatworksStudiosHeaderImageTransparent.png";
// import NFCComponent from "./NFCComponent";

export class AccessContentFailed extends Component {
  render() {
    return (
      <div className="AccessContentLoadingWrapper">
        <AccessNavBar id="navBar" navColorPrimary="#353d3b" />
        <div className="bgColor">
          <div className="Access">
            <div className="WindowPane"></div>
            <img
              className="SweatWorksBackgroundImage"
              alt="SweatWorks Studio Welcome Header"
              src={SweatWorksBackgroundImage}
            />
            <p id="InvalidText" className="AccessCell">
              Sorry! There is a problem with this Access Pass.
            </p>
            {/* <NFCComponent/> */}
          </div>
        </div>
      </div>
    );
  }
}
export default AccessContentFailed;
