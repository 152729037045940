import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import eiAddLocationScheduleException from "../../../modules/api/ei/eiAddLocationScheduleException";
import eiRemoveLocationScheduleException from "../../../modules/api/ei/eiRemoveLocationScheduleException";
import { EI_REQUEST_ADD_SCHEDULE_EXCEPTION, SEND_REQUEST } from "../../../sagas/actions";
import XButton from "../components/XButton";
import "./EmployeeInterfaceLocationView.css";
class LocationScheduleExceptions extends Component {
  state = {
    date: moment().format("MM/DD/YYYY"),
    open: "9:00 am",
    close: "9:00 pm",
  };

  handleAddException = (locationId, date, open, close) => {
    if (
      (moment(open, "hh:mm a", true).isValid() || moment(open, "h:mm a", true).isValid() || open === "0:00 am") &&
      (moment(close, "hh:mm a", true).isValid() || moment(close, "h:mm a", true).isValid() || close === "0:00 am") &&
      moment(date, "MM/DD/YYYY").isValid()
    ) {
      const token = localStorage.getItem("token");
      this.props.requestAddSchedException(this.props.siteId, locationId, token, date, open, close);
      this.props.refreshLocationData(this.props.siteId, locationId, this.props.locationName);
    } else {
      console.log("Add schedule exception error! timestamps are invalid");
    }
  };
  handleRemoveException = (locationId, date) => {
    if (moment(date, "MM/DD/YYYY").isValid() || !locationId) {
      const token = localStorage.getItem("token");
      this.props.requestRemoveSchedException(this.props.siteId, locationId, token, date);
      this.props.refreshLocationData(this.props.siteId, locationId, this.props.locationName);
    } else {
      console.log("Add schedule exception error! timestamps are invalid");
    }
  };

  mapExceptions = (exceptions) => {
    console.log("exceptions: ", exceptions);
    return exceptions.map((exception, i) => {
      console.log("exception: ", exception);
      console.log("schedule_date: ", exception.schedule_date);
      return (
        <tr className="ei-exceptions-tr  ei-exceptions-tr-body" key={i}>
          <td className="ei-exceptions-td">{exception.schedule_date}</td>
          <td className="ei-exceptions-td">{exception.schedule_open}</td>
          <td className="ei-exceptions-td">{exception.schedule_close}</td>
          <td className="ei-exceptions-td">
            <XButton
              onClick={() => {
                this.handleRemoveException(this.props.locationId, exception.schedule_date);
              }}
            />
          </td>
        </tr>
      );
    });
  };
  ExceptionTitles = (extra) => {
    return (
      <tr className="ei-exceptions-tr ei-exceptions-tr-header">
        <td className="ei-exceptions-td">DATE</td>
        <td className="ei-exceptions-td">OPEN</td>
        <td className="ei-exceptions-td">CLOSE</td>
        {extra ? <td className="ei-exceptions-td"></td> : null}
      </tr>
    );
  };

  input = (stateName) => {
    return (
      <input
        className="ei-schedule-time-input"
        value={this.state[`${stateName}`] || ""}
        onChange={(e) => {
          console.log("e.target.value: ", e.target.value);
          this.setState({
            [stateName]: e.target.value,
          });
        }}
      />
    );
  };
  render() {
    return (
      <div className="location-exception-container">
        <div className="ei-schedule-title">Create New Schedule Exception</div>
        <div className="ei-new-exception-wrapper">
          <div className="ei-new-exception-content">
            <table className="ei-exceptions-table">
              <tbody className="ei-exceptions-tbody">
                {this.ExceptionTitles(true)}
                <tr className="ei-exceptions-tr ei-exceptions-inputs">
                  <td>{this.input("date")}</td>
                  <td>{this.input("open")}</td>
                  <td>{this.input("close")}</td>
                  <td>
                    <button
                      className="ei-schedule-time-closed-button  glow-on-hover"
                      onClick={(e) => {
                        this.setState({
                          open: "0:00 am",
                          close: "0:00 am",
                        });
                      }}
                    >
                      CLOSED
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              className="glow-on-hover"
              onClick={() => {
                this.handleAddException(this.props.locationId, this.state.date, this.state.open, this.state.close);
              }}
            >
              ADD
            </button>
          </div>
        </div>
        <div className="ei-schedule-title">Upcoming Schedule Exceptions</div>
        <table className="ei-exceptions-table">
          <thead>{this.ExceptionTitles(true)}</thead>
          <tbody>{this.mapExceptions(this.props.exceptions)}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  exceptions: reduxState.eiLocationData?.payload?.exceptions,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestAddSchedException: async (siteId, locationId, token, date, open, close) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_REQUEST_ADD_SCHEDULE_EXCEPTION,
        apiCall: eiAddLocationScheduleException,
        apiCallParams: {
          siteId: siteId,
          locationId: locationId,
          token: token,
          date: date,
          open: open,
          close: close,
        },
      });
    },
    requestRemoveSchedException: async (siteId, locationId, token, date) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_REQUEST_ADD_SCHEDULE_EXCEPTION,
        apiCall: eiRemoveLocationScheduleException,
        apiCallParams: {
          siteId: siteId,
          locationId: locationId,
          token: token,
          date: date,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationScheduleExceptions);
