import accessDataApiCall from "../../modules/api/accessDataApiCall";
import React, { useEffect } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import AccessContent from "./AccessContent";
import AccessContentLoading from "./AccessContentLoading";
import { SEND_REQUEST, REQUEST_USER_ACCESS_DATA } from "../../sagas/actions";
import AccessContentFailed from "./AccessContentFailed";
import { useParams } from "react-router-dom";

const AccessContentManager = ({requestUserData, credentialValid}) => {
  const { key } = useParams();
  useEffect(() => {
    requestUserData(key);
  }, [requestUserData, key]);

  const displayComponent = () => {
    if (credentialValid) {
      return <AccessContent />;
    } else if (credentialValid === false) {
      return <AccessContentFailed />;
    } else if (credentialValid === null) {
      return <AccessContentLoading />;
    }
  };
  return <div className="ContentManagerWrapper">{displayComponent()}</div>;
};

const mapStateToProps = (reduxState) => {
  return {
    credentialValid: reduxState.access.credentialValid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserData: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_USER_ACCESS_DATA,
        apiCall: accessDataApiCall,
        apiCallParams: { accessKey: accessKey, entry: null },
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessContentManager);
