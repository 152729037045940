import React, { Component } from "react";
import "../Benefits/Benefits.css";
import './Ray.css'
class Ray extends Component {
  orientation = () => {
    if (this.props.startFrom === 'left' ) {
      return {transform: 'rotate(90deg) scale(-1)'};
    } else if (this.props.startFrom === 'right') {
      return {transform: 'scale(-1)'}
    }
  }
  
  render() {
    return (
      <div className="RayWrapper"   style={{...this.orientation(), ...this.props?.style}}>
        <svg id={this.props.id} className="RayAnimation" x="0px" y="0px" viewBox={`0 0 300 40`}>
          <path
            className="ray-path"
            d="M6.5,6.5c30,0,30,26.9,60,26.9c30,0,30-26.9,60-26.9c30,0,30,26.9,60,26.9c30,0,30-26.9,60-26.9
	                  c30,0,30,26.9,60,26.9"
          />
        </svg>
      </div>
    );
  }
}
export default Ray;
