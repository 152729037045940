import React, { Component } from "react";
import PhoneAccessImage from "../../images/sweatworks_access_overlay.png";
import "./ExperiencePage.css";
import { SWEATWORKS_URL } from "../../TestingVariables";
import AwakenRoomPic from "../../images/AwakenRoomSauna2.jpg";
import BookNowButton from "../Nav/BookNowButton";
import { connect } from "react-redux";
import RedLightAddon from "../../images/red_light_addon.png";
import SweatIconNum from "./SweatIconNum";
import { ReactComponent as TheSWExperience } from "../../images/assets/sweatworks_experience.svg";
class Experience extends Component {
  
  render() {
    return (
      <div className="ExperienceContent">
        <div className="experience-intro-wrapper">
          <div className="background-shader">
            <div
              className="experience-title-image"
              alt="The Experience Background pic"
              style={{ backgroundImage: `url(${AwakenRoomPic})` }}
            >
              <div className="TheExperienceTitle XXL TitleFont ColorWhite">
                <div className="TheExperienceTitleGroup">
                  <TheSWExperience
                    className="the-sw-experience-img"
                    alt="the sweatworks experience"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="BenefitsListGroup">
          <div className="experience-content-wrapper">
            <div
              className="experience-intro experience-step-text-sub
              L"
            >
              <div className="XL TitleFont experience-intro-text">
                Enjoy our Saunas at all hours of the day with our All Access
                Pass!
              </div>
              <div className="L ItalicContentFont ">
                Take advantage of our in-room appointment add-ons such as red
                light or towel service.{" "}
                <small>(availiable at select locations)</small>
              </div>
            </div>
            <div className="experience-step">
              <div className="experience-step-title TitleFont XXL">
                <SweatIconNum num={1} />
                <strong className="experience-step-title-text">
                  {" "}
                  PURCHASE A SESSION
                </strong>
              </div>
              <div className="experience-step-text">
                Navigate to{" "}
                <a className="href-link" href={`${SWEATWORKS_URL}/pricing`}>
                  pricing
                </a>
                , then select a package and checkout.
              </div>
            </div>
            <div className="experience-step">
              <div className="experience-step-title TitleFont XXL">
                <SweatIconNum num={2} />
                <strong className="experience-step-title-text">
                  {" "}
                  BOOK AN APPOINTMENT
                </strong>
              </div>
              <div className="experience-step-text">
                <div className="book-now-text-wrapper">
                  <div className="book-now-text-item">Click on </div>
                  <a href={`${SWEATWORKS_URL}/book`}>
                    <BookNowButton />
                  </a>
                  <div className="book-now-text-item">then select</div>
                  <div className="book-now-text-subitem">
                    an availiable time and checkout.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="experience-step">
              <div className="experience-step-title TitleFont XXL">
                <SweatIconNum num={3} />
                <strong className="experience-step-title-text">
                  {" "}
                  RECIEVE THE ACCESS TEXT
                </strong>
              </div>
              <div className="TitleFont S">(if appointment is after-hours)</div>
              <div className="experience-step-text-sub">
                Once you have booked an after-hours appointment you will receive
                a confirmation text with your appoinmtent details.
              </div>
              <div className="experience-step-divided">
                <div className="experience-step-left">
                  <div className="experience-step-text">
                    <div className="experience-step-text-sub">
                      On the day of your appointment, we will send you a text
                      containing an access link to our building. This pass will
                      allow you to enter our building when our locations are
                      unstaffed.
                    </div>
                    <div className="experience-step-text-sub">
                      <strong>
                        PLEASE NOTE: This Access Pass will only be valid 5
                        minutes prior to your appointment starting, it will then
                        expire 15 minutes after your appointment has started.
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="experience-step-right">
                  <img
                    className="PhoneAccessImage"
                    src={PhoneAccessImage}
                    alt="Phone displaying Sweatworks Access Pass"
                  />
                </div>
              </div>
            </div>
            <div className="experience-step">
              <div className="experience-step-title TitleFont XXL">
                <SweatIconNum num={4} />
                <strong className="experience-step-title-text">
                  {" "}
                  SELECT ADD-ONS
                </strong>
              </div>
              <div className="experience-step-divided">
                <div className="experience-step-left">
                  <div className="experience-step-text experience-step-text-sub">
                    You can upgrade your appointment at any time once you have
                    received the pass. Choose between ONYX Red Light Therapy, or
                    Towel Service!
                  </div>
                </div>
                <div className="experience-step-right">
                  <img
                    className="PhoneAccessImage"
                    src={RedLightAddon}
                    alt="Phone displaying Sweatworks Access Pass"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(Experience);
