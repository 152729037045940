import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
// import redLightImageOne from "../../images/red_light_pic_1.jpg";
export class AlreadyPurchasedUvbLight extends Component {
  render() {
    return (
      <div className="AlreadyPurchasedUvbLightWrapper">
        <div className="ImageWrapper">
        </div>
        <h2 className="CardBodyText">UVB light therapy has already been added to your appointment, your all set!</h2>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(AlreadyPurchasedUvbLight);
