import React from "react";
import "./Modal.css";
import {FiAlertCircle} from 'react-icons/fi'
export const SWFailed = (props) => {
  return (
    <div className="sw-failed-modal-wrapper">
      <FiAlertCircle className="sw-loading-failed-icon"/>
      <div className="sw-loading-failed-mesage">{props.message}</div>
    </div>
  );
};

export default SWFailed;
