import React, { Component } from "react";
import "./AccessNavBar.css";
import awakenLogo from "../../images/squared-up-awaken-logo-for-web-1.png";

export class AccessNavBar extends Component {
  render() {
    return (
      <div className="navBarContainer">
        <div
          className="accessNavWrapper"
          style={{ backgroundColor: this.props.navColorPrimary, zIndex: 100 }}
        >
          <a className="iconWrapper" href="https://awakenforwellness.com/">
            <img
              className="access-nav-logo"
              alt="Awaken For Wellness Home Button"
              src={awakenLogo}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default AccessNavBar;
