import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import ThankYouPurchasedView from "./ThankYouPurchasedView";
import AddTowelServiceLightView from "./AddTowelServiceLightView";
import AlreadyPurchasedTowelService from "./AlreadyPurchasedTowelService";
import TowelsPic from "../../images/Towels_1.jpeg";
import { Card } from "../General/Card";

export class Uvb extends Component {
  state = {
    addUvbLightApiCall: false,
  };
  towelServiceComnponentManager = () => {
    const towelService = this.props.reduxState.access.data.appointment.towelService;
    if (this.props.reduxState.addonStatuses.towelServiceAdded) {
      return <ThankYouPurchasedView type="Towel service" towelService={true} />;
    } else if (!towelService) {
      return <AddTowelServiceLightView />;
    } else if (towelService) {
      return <AlreadyPurchasedTowelService />;
    }
  };
  render() {
    return (
      <div className="towelServiceLightWrapper">
        <div className="WindowPane"></div>
        <Card header="TOWEL SERVICE" className="AddonCard" bg="dark" text="light">
            <img src={TowelsPic} alt="Towel Service" id="TowelsImage" />
            {this.towelServiceComnponentManager()}
            {this.props.reduxState.uvbLightMessageBox.buttonDisabled}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(Uvb);
