import axios from "axios";
import { put } from "redux-saga/effects";
import { EI_SET_COMMANDS_MESSAGE } from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEISaunaCommand(apiCallParams) {
  const { token, staffId, siteId, command } = apiCallParams;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
    siteId: siteId
  };
  const result = yield axios
    .get(`${SWEATWORKS_API}/ei/command/${command}/${staffId}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("res: ", res);
      console.log("res.data.message: ", res.data.message);
      console.log("res.data.status: ", res.data.status);
      return { status: res.data.status, message: res.data.message };
    })
    .catch((e) => {
      console.log("requestEISaunaCommand error: ", e);
      return { status: 500, message: `Internal Server Error! | error: ${e}` };
    });
  yield put({
    type: EI_SET_COMMANDS_MESSAGE,
    payload: { status: result.status, message: result.message },
  });
}

export default requestEISaunaCommand;
