import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ isOn, handleToggle, onColor, id }) => {
    return (
      <>
        <input
          checked={isOn}
          onChange={handleToggle}
          className="react-switch-checkbox"
          id={id}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={id}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

export default ToggleSwitch;
