import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
// import redLightImageOne from "../../images/red_light_pic_1.jpg";
export class AlreadyPurchasedTowelService extends Component {
  render() {
    return (
      <div className="AlreadyPurchasedTowelServiceWrapper">
        <h2 className="CardBodyText">Towel service has already been added to your appointment. </h2>
        <h5 className="CardBodyText">Please use the access button at the top of this page to unlock the towel door. </h5>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(AlreadyPurchasedTowelService);
