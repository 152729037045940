import React from 'react';
import sweatworksDropOrange  from '../../images/assets/Sweatworks_Drop_Orange.png';
import './Marker.css';

const Marker = (props) => {
    return (
      <img src={sweatworksDropOrange} alt="Sweatworks Studio Location" className="marker" onClick={() => {
        props.handleClick(props.lat, props.lng) }}
        style={{ backgroundColor: 'rgb(0,0,0,0.0)', cursor: 'pointer'}}
      />
    );
  };

  export default Marker;
