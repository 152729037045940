import React, { Component } from "react";
import LoadingAnimation from "./LoadingAnimation";
import NavigationBar from "../Nav/AccessNavBar";

export class AccessContentLoading extends Component {
  render() {
    return (
      <div className="AccessContentLoadingWrapper">
        <NavigationBar />
        <LoadingAnimation />
      </div>
    );
  }
}
export default AccessContentLoading;
