import React, { useEffect, useState } from "react";
import "./roomViewHeader.css";
import { connect } from "react-redux";
import ToggleSwitch from "../components/ToggleSwitch";
import genSaunaImage from "../components/getSaunaImage";
import { EI_AUTOMATIONS_UPDATE, SEND_REQUEST } from "../../../sagas/actions";
import requestEIAutomationsUpdate from "../../../modules/api/ei/requestEIAutomationsUpdate";
import { BackButton } from "../components/BackButton";
import switchIcon from "../assets/power-switch.png";
import tempIcon from "../assets/heat.png";
const formatType = require("../formatType");

function RoomViewHeader(props) {
  const [saunaAuto, setSaunaAuto] = useState(props.saunaAuto);
  const [lastSavedSaunaAuto, setLastSaunaAuto] = useState(props.saunaAuto);
  const [redLightAuto, setRedLightAuto] = useState(props.redLightAuto);
  const [lastSavedRedLightAuto, setLastRedLightAuto] = useState(props.redLightAuto);
  const [tempGoal, setTempGoal] = useState(props.preheatTempGoal);
  const [maxTemp, setMaxTemp] = useState(props.maxSaunaTemp);
  const [lastTempGoal, setLastTempGoal] = useState(props.preheatTempGoal);
  const [lastMaxTemp, setLastMaxTemp] = useState(props.maxSaunaTemp);

  useEffect(() => {
    setSaunaAuto(props.saunaAuto);
    // setLastSaunaAuto(props.saunaAuto);
    setRedLightAuto(props.redLightAuto);
    // setLastRedLightAuto(props.redLightAuto);

    setTempGoal(props.preheatTempGoal);
    // setLastTempGoal(props.preheatTempGoal);
    setMaxTemp(props.maxSaunaTemp);
    // setLastMaxTemp(props.maxSaunaTemp);
  }, [props.saunaAuto, props.redLightAuto, props.preheatTempGoal, props.maxSaunaTemp]);

  const settingsChanged = (curFirstAuto, curSecondAuto, lastFirstAuto, lastSecondAuto) => {
    console.log('settingsChanged saunaAuto, redLightAuto, lastSavedSaunaAuto, lastSavedRedLightAuto: ', curFirstAuto, curSecondAuto, lastFirstAuto, lastSecondAuto);
    const firstSame = curFirstAuto === lastFirstAuto;
    const secondSame = curSecondAuto === lastSecondAuto;
    if (firstSame && secondSame) {
      return false;
    } else {
      return true;
    }
  };
  const makeButonClassName = (status) => {
    return status === true ? "glow-on-hover" : "glow-on-hover-disabled";
  };

  const handleAutomationsUpdate = (sA, rA, siteId, staffId) => {
    console.log("handleUpdate sA, rA: ", sA, rA);
    const success = props.requestUpdateAutomations(localStorage.getItem("token"), siteId, staffId, sA, rA);
    if (success) {
      setLastSaunaAuto(sA);
      setLastRedLightAuto(rA);
    }
  };

  const handleTempGoalUpdate = (tempGoal, maxTemp, siteId, staffId) => {
    console.log("handleUpdate tempGoal, maxTemp: ", tempGoal, maxTemp);
    const success = props.requestUpdateTempGoal(localStorage.getItem("token"), siteId, staffId, tempGoal, maxTemp);
    if (success) {
      setLastTempGoal(tempGoal);
      setLastMaxTemp(maxTemp);
    }
  };

  return (
    <div className="ei-room-header-wrapper">
      <div className="room-header-row">
        <BackButton set={props.set} viewValue="home" />
        <strong className="ei-room-name">{props.roomName}</strong>
        <div className="ei-status-online-wrapper">
          <strong className="ei-room-status-online-title">STATUS:</strong>
          <div className="ei-room-status-wrapper">
            {props.status ? (
              props.status !== 200 ? (
                <div className="ei-room-status-online-status ei-room-status-offline">OFFLINE</div>
              ) : (
                <div className="ei-room-status-online-status ei-room-status-online">ONLINE</div>
              )
            ) : null}
          </div>
        </div>
      </div>
      <div className="ei-room-statuses-wrapper">
        <div className={`room-sauna-picture-wrapper-${formatType()}`}>
          <img
            className="ei-room-view-sauna-pic"
            alt={`Sauna_pic_${props.saunaType}`}
            src={genSaunaImage(props.saunaType)}
          />
        </div>
        <div className="ei-room-status-group">
          <strong className="ei-room-statuses-title">CURRENT STATUSES</strong>
          <div className="ei-room-status-row">
            <strong>TEMP:</strong>
            <strong className="ei-room-status">{props.temp ? Math.round(props.temp) + "°" : "N/A"}</strong>
          </div>
          <div className="ei-room-status-row">
            <strong>PREHEATING:</strong>
            <strong className={"ei-room-status " + (props.preheatMode ? "ei-room-status-green" : "")}>
              {props.preheatMode ? "ENGAGED" : "NOT ACTIVE"}
            </strong>
          </div>
          <div className="ei-room-status-row">
            <strong>RED LIGHT:</strong>
            <strong className={"ei-room-status " + (props.redLightStatus ? "ei-room-status-green" : "")}>
              {props.redLightStatus ? "ENGAGED" : "NOT ON"}
            </strong>
          </div>
        </div>
        <div className="">
          <div className={`ei-room-automations-group-${formatType()}`}>
            <div className="si-room-automations-title-group">
              <img alt="back_button" src={switchIcon} className={`si-title-icon`} />
              <div className="ei-room-automations-title">Automation Enabling</div>
            </div>
            <div className="ei-room-automations-subtitle">
              If disabled, this feature will block any future automations that get sent to the room. If the sauna or red
              light is malfunctioning you can independently disable either one until it is ready to be re-enabled. This
              will NOT stop any currently active sauna preheat cycles.
              <br />
              (See "ABORT CURRENT PREHEAT REQUEST" below for this)
            </div>
            <div className="ei-room-automations-enabling-wrapper">
              <div className="ei-room-automations-enabling-group">
                <div className="ei-room-automations-button-wrapper">
                  <strong className="ei-room-automations-button-title">SAUNA</strong>
                  <div className="ei-room-automations-button-group">
                    <div className="ei-room-automations-disabled">{saunaAuto ? "" : "DISABLED"}</div>
                    <ToggleSwitch
                      isOn={saunaAuto}
                      id="react-switch-sauna"
                      onColor={"#42bca9"}
                      handleToggle={() => setSaunaAuto(!saunaAuto)}
                    />
                  </div>
                </div>
                <div className="ei-room-automations-button-wrapper">
                  <strong className="ei-room-automations-button-title">REDLIGHT</strong>
                  <div className="ei-room-automations-button-group">
                    <div className="ei-room-automations-disabled">{redLightAuto ? "" : "DISABLED"}</div>
                    <ToggleSwitch
                      isOn={redLightAuto}
                      id="react-switch-red-light"
                      onColor={"#42bca9"}
                      handleToggle={() => setRedLightAuto(!redLightAuto)}
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                disabled={!settingsChanged(saunaAuto, redLightAuto, lastSavedSaunaAuto, lastSavedRedLightAuto)}
                onClick={() => {
                  handleAutomationsUpdate(saunaAuto, redLightAuto, props.siteId, props.staffId);
                }}
                className={
                  "ei-room-update-button " +
                  makeButonClassName(
                    settingsChanged(saunaAuto, redLightAuto, lastSavedSaunaAuto, lastSavedRedLightAuto)
                  )
                }
              >
                UPDATE
              </button>
            </div>
          </div>
          {props.preheatTempGoal && props.maxSaunaTemp ? (
            <div className={`ei-room-automations-group-${formatType()}`}>
              <div className="si-room-automations-title-group">
                <img alt="back_button" src={tempIcon} className={`si-title-icon`} />
                <div className="ei-room-automations-title">Preheat Temperature Goal</div>
              </div>
              <div className="ei-room-automations-subtitle">
                Change the temperature goal that the automation will try to set during a preheat. The maximum temp this
                sauna can be set to MUST be entered correctly or this feature will not work properly!
              </div>
              <div className="ei-room-automations-enabling-wrapper">
                <div className="si-room-temp-goal-input-group">
                  <div className="si-room-temp-input-group">
                    <input
                      className="si-temp-goal-input"
                      type="number"
                      value={tempGoal}
                      onChange={(e) => {
                        console.log("tempGoal e.target.value: ", e.target.value);
                        setTempGoal(e.target.value);
                      }}
                    />
                    <div className="si-temp-goal-description">°F is the Preheat Temperature Goal</div>
                  </div>
                  <div className="si-room-temp-input-group">
                    <input
                      className="si-temp-goal-input"
                      type="number"
                      value={maxTemp}
                      onChange={(e) => {
                        console.log("maxTemp e.target.value: ", e.target.value);
                        setMaxTemp(e.target.value);
                      }}
                    />
                    <div className="si-temp-goal-description">
                      °F is the Max Temperature that this sauna can be set to
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  disabled={!settingsChanged(Number(tempGoal), Number(maxTemp), lastTempGoal, lastMaxTemp)}
                  onClick={() => {
                    handleTempGoalUpdate(tempGoal, maxTemp, props.siteId, props.staffId);
                  }}
                  className={
                    "ei-room-update-button " +
                    makeButonClassName(settingsChanged(Number(tempGoal), Number(maxTemp), lastTempGoal, lastMaxTemp))
                  }
                >
                  UPDATE
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUpdateAutomations: async (token, siteId, staffId, saunaAuto, redLightAuto) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_AUTOMATIONS_UPDATE,
        apiCall: requestEIAutomationsUpdate,
        apiCallParams: {
          route: "enabling",
          token: token,
          siteId: siteId,
          staffId: staffId,
          data: {
            sauna: saunaAuto,
            redLight: redLightAuto,
          },
        },
      });
    },
    requestUpdateTempGoal: async (token, siteId, staffId, tempGoal, maxTemp) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_AUTOMATIONS_UPDATE,
        apiCall: requestEIAutomationsUpdate,
        apiCallParams: {
          route: "temp_goal",
          token: token,
          siteId: siteId,
          staffId: staffId,
          data: {
            tempGoal: Number(tempGoal),
            maxTemp: Number(maxTemp),
          },
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewHeader);
