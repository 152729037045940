import React, { Component } from "react";
import Card from "../General/Card";
import "./CardGroup.css";

class SingleSessionGroup extends Component {
  cardData = [
    {
      cost: "20",
      text: "New Clients",
      linkId: "10752",
      context: "First Time Client",
    },
    {
      cost: "40",
      text: "Return Clients",
      linkId: "10108",
      context: "Single Infrared Sauna Session",
    },
  ];
  createWidgetLink = (id, buttonName) => {
    return (
      <>
        <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript" />
        <healcode-widget
          data-version="0.2"
          data-link-class="healcode-pricing-option-text-link"
          data-site-id="26503"
          data-mb-site-id="43256"
          data-bw-identity-site="false"
          data-type="pricing-link"
          data-inner-html={buttonName}
          data-service-id={id}
        />
      </>
    );
  };
  createCards = () => {
    return this.cardData.map((card, i) => {
      return (
        <Card key={i} header={card.text} className="single-card">
          <div className="CardMoneyGroup">
            <div className="CardMoneySign SWMedium">$</div>
            <div className="CardMoneyValue SWMedium">{card.cost}</div>
          </div>
          <div className="SW-Card-Context">{card.context}</div>
          <div style={{ height: "40px" }}>
            <small className="text-muted">{this.createWidgetLink(card.linkId, "Add To Cart")}</small>
          </div>
        </Card>
      );
    });
  };

  render() {
    return (
      <div>
        <h1 className="MembershipsIntroText TitleFont" style={{ zIndex: 10 }}>
          Individual Sauna Session
        </h1>
        <div className="CardGroup">{this.createCards()}</div>
      </div>
    );
  }
}
export default SingleSessionGroup;

// import React, { useEffect, useState } from "react";
// import Card from "../General/Card";
// import "./CardGroup.css";
// import loadMBody from "../mindbody/loadMBody";

// const SingleSessionGroup = () => {
//   const [mindBody, setMindBody] = useState(false);

//   useEffect(() => {
//     loadMBody(() => {
//       setMindBody(true);
//     });
//   }, []);

//   const cardData = [
//     {
//       cost: "20",
//       text: "New Clients",
//       linkId: "10752",
//       context: "First Time Client",
//     },
//     {
//       cost: "40",
//       text: "Return Clients",
//       linkId: "10108",
//       context: "Single Infrared Sauna Session",
//     },
//   ];

//   const createWidgetLink = (id, buttonName) => {
//     return (
//       <>
//         {mindBody && (
//           <healcode-widget
//             data-version="0.2"
//             data-link-class="healcode-pricing-option-text-link"
//             data-site-id="26503"
//             data-mb-site-id="43256"
//             data-bw-identity-site="false"
//             data-type="pricing-link"
//             data-inner-html={buttonName}
//             data-service-id={id}
//           />
//         )}
//       </>
//     );
//   };

//   const createCards = () => {
//     return cardData.map((card, i) => {
//       return (
//         <Card key={i} header={card.text} className="single-card">
//           <div className="CardMoneyGroup">
//             <div className="CardMoneySign SWMedium">$</div>
//             <div className="CardMoneyValue SWMedium">{card.cost}</div>
//           </div>
//           <div className="SW-Card-Context">{card.context}</div>
//           <div style={{ height: "40px" }}>
//             <small className="text-muted">{createWidgetLink(card.linkId, "Add To Cart")}</small>
//           </div>
//         </Card>
//       );
//     });
//   };

//   return (
//     <div>
//       <h1 className="MembershipsIntroText TitleFont" style={{ zIndex: 10 }}>
//         Individual Sauna Session
//       </h1>
//       <div className="CardGroup">{createCards()}</div>
//     </div>
//   );
// };

// export default SingleSessionGroup;
