import axios from "axios";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* sendLocationDataApiCall(apiCallParams) {
  const position = apiCallParams.position;
  const type = apiCallParams.type;
  const headers = {
    "Content-Type": "application/json",
  };
  const data = {
    position: position,
  };
  return yield axios
    .post(`${SWEATWORKS_API}/sweatworks/data/location/${type}`, data, {
      headers: headers,
    })
    .then((res) => {
      return res.data;
    });
}

export default sendLocationDataApiCall;
