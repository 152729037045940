import {
  EI_SIGNUP_MESSAGE_BOX_RESET,
  EI_SIGNUP_MESSAGE_BOX_SUCCESS,
  EI_SIGNUP_MESSAGE_BOX_FAILED,
  EI_SIGNUP_MESSAGE_BOX_INVALID,
} from "../../sagas/actions";

const initAccessState = {
  message: null,
};

const signupMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case EI_SIGNUP_MESSAGE_BOX_RESET:
      return {
        message: null,
      };
    case EI_SIGNUP_MESSAGE_BOX_SUCCESS:
      return {
        message: "Employee account created Successfully!",
      };
    case EI_SIGNUP_MESSAGE_BOX_FAILED:
      return {
        message: `Failed to create a new account!`,
      };
    case EI_SIGNUP_MESSAGE_BOX_INVALID:
      return {
        message: `Sorry! The invite code is either invalid or has expired.`,
      };
    default:
      return state;
  }
};

export default signupMessageBox;
