import React from "react";
import "./Modal.css";
export const SWLoadingModal = (props) => {
  return (
    <div className="sw-loading-modal-wrapper">
      <div className="ei-loader-wheel">{props.message}</div>
    </div>
  );
};

export default SWLoadingModal;
