import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
export class AlreadyPurchasedRedLight extends Component {
  render() {
    return (
      <div className="AlreadyPurchasedRedLightWrapper">
        <div className="ImageWrapper">
        </div>
        <h2 className="CardBodyText">Red light therapy has already been added to your appointment, you're all set!</h2>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(AlreadyPurchasedRedLight);
