import React from "react";
import "./roomViewData.css";
import { connect } from "react-redux";
// import requestEISaunaData from "../../../modules/api/ei/requestEISaunaData";
// import { EI_SAUNA_DATA, SEND_REQUEST } from "../../../sagas/actions";
import moment from "moment-timezone";
import "./RoomViewAppointments.css";
import { BsKeyFill } from "react-icons/bs";
function RoomViewAppointments(props) {
  const makeCredentialRow = (credential, sentLink) => {
    const { start_date_time, end_date_time } = credential;
    console.log("start_date_time, end_date_time: ", start_date_time, end_date_time);
    const makeClassName = (sent) => {
      const t = "ei-appointments-sent-text ";
      if (!!sent) {
        return t + "ei-appointments-sent-text-green";
      } else if (sent === null) {
        return t + "ei-appointments-sent-text-black";
      } else {
        return t + "ei-appointments-sent-text-black";
      }
    };

    const makeTextSentResponse = (sent) => {
      if (!!sent) {
        return "TRUE";
      } else if (sent === null) {
        return "Not Yet";
      } else {
        return "FALSE";
      }
    };

    return (
      <div className="ei-credential-wrapper">
        <div className="ei-credential-title">
          <BsKeyFill />
          <div className="ei-credential-title-text">Credential</div>
        </div>
        <div className="ei-credential-content">
          <div className="ei-credential-time-group">
            <div>start: {moment(start_date_time).format("h:mm a")}</div>
            <div>end: {moment(end_date_time).format("h:mm a")}</div>
          </div>
          <div>
            <div>SENT ACCESS TEXT:</div>
            <div className={makeClassName(sentLink)}>{makeTextSentResponse(sentLink)}</div>
          </div>
        </div>
      </div>
    );
  };

  const matchCredential = (id, credentials, sentAccessLinkText) => {
    console.log("in matchCredential");
    console.log("credentials: ", credentials);
    for (let i = 0; i < credentials.length; i++) {
      const credential = credentials[i];
      console.log("credential: ", credential);
      console.log("id: ", id);
      if (credential.appointment_id === id) {
        console.log("Found matching credential");
        return makeCredentialRow(credential, sentAccessLinkText);
      }
    }
  };

  const mapAppointments = (appointments, credentials) => {
    console.log("appointments, credentials: ", appointments, credentials);
    // if (appointments?.length > 0) {
    return appointments.map((appointment, i) => {
      console.log("appointment: ", appointment);
      const {
        appointment_id,
        client_first_name,
        start_date_time,
        end_date_time,
        red_light,
        sent_access_link_text,
        towel,
      } = appointment;
      return (
        <div className="ei-room-view-appointment-row" key={i}>
          <div className="ei-appointment-wrapper">
            <div className="ei-appointment-row-title">
              <div className="ei-appointment-name">{client_first_name}</div>
              <div className="ei-appointment-time-wrapper">
                <div className="ei-appointment-time-data">{moment(start_date_time).format("dddd")}</div>
                <div className="ei-appointment-time-data">{moment(start_date_time).format("h: mm a")}</div>
                <div className="ei-appointment-time-data">-</div>
                <div className="ei-appointment-time-data">{moment(end_date_time).format("h: mm a")}</div>
              </div>
              <div className="ei-appointment-time-data">
                {console.log(
                  'moment(moment(end_date_time).format()).format("(MM/DD/YYYY)"): ',
                  moment(moment(end_date_time).format()).format("(MM/DD/YYYY)")
                )}
                {moment(moment(start_date_time).format()).format("(MM/DD/YYYY)")}
              </div>
            </div>
            <div className="ei-appointment-row-content">
              {/* <div className="ei-appointment-details">
                <div>Sauna:</div>
                <div className={"ei-detail-" + (infrared_sauna ? "blue" : "orange")}>
                  {infrared_sauna ? "True" : "False"}
                </div>
              </div> */}
              <div className="ei-appointment-details">
                <div>Red Light Added:</div>
                <div className={"ei-detail-" + (red_light ? "blue" : "orange")}>{red_light ? "True" : "False"}</div>
              </div>
              {towel ? (
                <div className="ei-appointment-details">
                  <div>Towel:</div>
                  <div className={"ei-detail-blue"}>True</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ei-credential-wrapper">
            {matchCredential(appointment_id, credentials, sent_access_link_text)}
          </div>
        </div>
      );
    });
    // }
  };

  return (
    <div className="ei-room-appointments-container">
      <div className="ei-schedule-title">Room Appointments and Credentials</div>
      <div className="ei-room-view-appointments">
        {props.appointments ? mapAppointments(props.appointments, props.credentials) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    //   requestUpdateSaunaData: async (token, staffId, date, dayQuarter) => {
    //     dispatch({
    //       type: SEND_REQUEST,
    //       loadActionType: EI_SAUNA_DATA,
    //       apiCall: requestEISaunaData,
    //       apiCallParams: {
    //         token: token,
    //         staffId: staffId,
    //         date: date,
    //         dayQuarter: dayQuarter,
    //       },
    //     });
    //   },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewAppointments);
