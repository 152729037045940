import React, { useState } from "react";
import "./MobileNav.css";
import { SWEATWORKS_URL } from "../../TestingVariables";
import BookNowButton from "./BookNowButton";
import SweatworksLogo from "../../images/sweatworks_logo_transparent_bg.png";
import { colorIsFaded } from "./colorIsFaded";
import { GrFormClose } from "react-icons/gr";
import { HiMenuAlt4 } from "react-icons/hi";
// import StickyBox from "react-sticky-box";

const MobileNav = ({ active, color, type }) => {
  const [navClass, setNavClass] = useState("MobileNavSideBar-closed");

  return (
    <>
      <div
        className={
          type === "fixed"
            ? "MobileNavTopBar"
            : active
            ? "SWMobileNavBarContainer-dropdown-active"
            : "SWMobileNavBarContainer-dropdown"
        }
        style={{
          backgroundColor: color,
        }}
      >
        <div
          className="mobile-menu-icon-group"
          onClick={() => {
            setNavClass("MobileNavSideBar-open");
          }}
        >
          <div className="mobile-menu-icon-wrapper">
            <HiMenuAlt4 className="mobile-menu-icon" />
          </div>
        </div>
        <a
          className="mobile-sweatworks-logo-wrapper"
          href={`${SWEATWORKS_URL}/home`}
        >
          <img
            className="sweatworks-logo-mobile"
            src={SweatworksLogo}
            alt="Sweatworks Logo Home Button"
          />
        </a>
      </div>
      <div className="mobile-nav-side-bar-wrapper">
        <div className={`mobile-nav-side-bar-group ${navClass}`}>
          <div className={`MobileNavSideBar ${navClass} NavContent`}>
            <div className="mobile-nav-top-row">
              <a
                className="mobile-nav-link ColorCharcoal"
                href={`${SWEATWORKS_URL}/home`}
              >
                HOME
              </a>
              <div
                className="mobile-nav-closer-wrapper"
                onClick={() => {
                  setNavClass("MobileNavSideBar-closed");
                }}
              >
                <GrFormClose className="mobile-nav-closer-icon" />
              </div>
            </div>
            <a
              className={
                "mobile-nav-link ColorCharcoal" +
                colorIsFaded("/benefits", window)
              }
              href={`${SWEATWORKS_URL}/benefits`}
            >
              BENEFITS
            </a>
            <a
              className={
                "mobile-nav-link ColorCharcoal" + colorIsFaded("/blog", window)
              }
              href={`${SWEATWORKS_URL}/blog`}
            >
              BLOG
            </a>
            <a
              className={
                "mobile-nav-link ColorCharcoal" +
                colorIsFaded("/experience", window)
              }
              href={`${SWEATWORKS_URL}/experience`}
            >
              EXPERIENCE
            </a>
            <a
              className={
                "mobile-nav-link ColorCharcoal" + colorIsFaded("/shop", window)
              }
              href={`${SWEATWORKS_URL}/shop`}
            >
              SHOP
            </a>
            <a
              className={
                "mobile-nav-link ColorCharcoal" +
                colorIsFaded("/benefits", window)
              }
              href="/book"
            >
              <BookNowButton />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
