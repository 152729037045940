import React, { useEffect, useRef } from 'react';

const NeWellnessAppointmentWidget = () => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // Add HTML content to the container
    if (widgetContainerRef.current) {
      widgetContainerRef.current.innerHTML = '<div class="mindbody-widget" data-widget-type="Appointments" data-widget-id="7c60744a23"></div>';

      // Create and append the script element
      const script = document.createElement('script');
      script.src = 'https://brandedweb.mindbodyonline.com/embed/widget.js';
      script.async = true;

      // Append the script to the body
      document.body.appendChild(script);

      // Clean up script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return <div ref={widgetContainerRef}></div>;
};

export default NeWellnessAppointmentWidget;
