import React, { Component } from "react";
import RedLightVideoPlayer from "./RedLightVideoPlayer";
import "./RedLightPage";
import BenefitGridOnyx from "./BenefitGridOnyx";
import { connect } from "react-redux";
import { SET_POSITION } from "../../sagas/actions";
import SWPageTemplate from "../General/Pages/PageTemplate";
// import RayRack from "../General/RayRack";
class DesktopHome extends Component {
  componentDidMount() {
    document.addEventListener("scroll", this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent = () => {
    const scrollPos = document.scrollingElement.scrollTop;
    const navPos = this.props.reduxState.navBarPostition;
    const lessThen = scrollPos <= 56;
    const moreThen = scrollPos > 56;
    if (lessThen) {
      return navPos ? this.props.setNavPos(false) : null;
    } else if (moreThen) {
      return !navPos ? this.props.setNavPos(true) : null;
    }
  };

  render() {
    return (
      <div id="HomeContentWrapper">
        <SWPageTemplate>
          <RedLightVideoPlayer />
          <BenefitGridOnyx />
        </SWPageTemplate>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNavPos: (payload) => {
      dispatch({
        type: SET_POSITION,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHome);
