import {
  EI_LOGIN_MESSAGE_BOX_RESET,
  EI_LOGIN_MESSAGE_BOX_EXPIRED,
  EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR,
  EI_LOGIN_MESSAGE_BOX_INVALID
} from "../../sagas/actions";

const initAccessState = {
  message: null,
};

const loginMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case EI_LOGIN_MESSAGE_BOX_RESET:
      return {
        message: null,
      };
    case EI_LOGIN_MESSAGE_BOX_EXPIRED:
      return {
        message: `This session has expired! Please login again`,
      };
      case EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR:
      return {
        message: `Error: SERVER ERROR OCCURED!`,
      };
    case EI_LOGIN_MESSAGE_BOX_INVALID:
      return {
        message: `Username or password is incorrect!`,
      };
    default:
      return state;
  }
};

export default loginMessageBox;
