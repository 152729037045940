import axios from "axios";

import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEILocationData(apiCallParams) {
  const token = apiCallParams.token;
  console.log('apiCallParams: ', apiCallParams);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    siteId: apiCallParams.siteId
  };
  return yield axios
    .get(`${SWEATWORKS_API}/ei/data/location/${apiCallParams.locationId}`, {
      headers: headers,
    })
    .then((res) => {
      console.log("requestEILocationData res.data: ", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("requestEILocationData error: ", err);
    });
}

export default requestEILocationData;