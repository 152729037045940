import React, { Component } from "react";
import "./NavBar.css";
import { SWEATWORKS_URL } from "../../TestingVariables";
import BookNowButton from "./BookNowButton";
import SweatworksLogo from "../../images/sweatworks_logo_transparent_bg.png";
import { connect } from "react-redux";
import { colorIsFaded } from "./colorIsFaded";

class NavBar extends Component {
  render() {
    return (
      <div
        className={
          this.props.type === "fixed"
            ? "SWNavBarContainer"
            : this.props.active
            ? "SWNavBarContainer-dropdown-active"
            : "SWNavBarContainer-dropdown"
        }
        // style={{
        //   backgroundColor: this.props.color,
        // }}
      >
        <div className="NavBar">
          <div className="NavLinksLeft">
            <a href={`${SWEATWORKS_URL}/home`}>
              <img
                className="sweatworks-logo"
                src={SweatworksLogo}
                alt="Sweatworks Logo Home Button"
              />
            </a>
          </div>
          <div className="NavLinksRight">
            <a
              className={
                "nav-link ColorCharcoal" + colorIsFaded("/benefits", window)
              }
              href={`${SWEATWORKS_URL}/benefits`}
            >
              BENEFITS
            </a>
            <a
              className={
                "nav-link ColorCharcoal" + colorIsFaded("/blog", window)
              }
              href={`${SWEATWORKS_URL}/blog`}
            >
              BLOG
            </a>
            <a
              className={
                "nav-link ColorCharcoal" + colorIsFaded("/experience", window)
              }
              href={`${SWEATWORKS_URL}/experience`}
            >
              EXPERIENCE
            </a>
            <a
              className={
                "nav-link ColorCharcoal" + colorIsFaded("/shop", window)
              }
              href={`${SWEATWORKS_URL}/shop`}
            >
              SHOP
            </a>
            <a
              className={
                "nav-link ColorCharcoal" + colorIsFaded("/benefits", window)
              }
              href={`${SWEATWORKS_URL}/book`}
            >
              <BookNowButton />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(NavBar);
