import React, { Component } from "react";
import "./Checkbox.css";
class Checkbox extends Component {
  render() {
    return (
      <div id={this.props?.id}>
        {console.log("this.props: ", this.props)}
        <input
          type="checkbox"
          id="checkbox"
          onChange={() => {
            this.props.handleCheckboxChange();
          }}
        />
        <label className="checkbox-label" htmlFor="checkbox">
          {this.props.label}
        </label>
      </div>
    );
  }
}
export default Checkbox;
