const {
  REQUEST_USER_ACCESS_DATA_LOADING,
  REQUEST_USER_ACCESS_DATA_SUCCESS,
  REQUEST_USER_ACCESS_DATA_FAILURE,
  ADD_TOWEL_ACCESS_BUTTON,
} = require("../../sagas/actions");

const initAccessState = {
  userAccessDataIsLoading: null,
  data: null,
  error: null,
  credentialValid: null
};

const access = (state = initAccessState, action) => {
  switch (action.type) {
    case REQUEST_USER_ACCESS_DATA_LOADING:
      return {
        userAccessDataIsLoading: true,
        data: null,
        error: null,
        credentialValid: null
      };
    case REQUEST_USER_ACCESS_DATA_SUCCESS:
      return {
        userAccessDataIsLoading: false,
        data: action.payload,
        error: null,
        credentialValid: true
      };
    case REQUEST_USER_ACCESS_DATA_FAILURE:
      return {
        userAccessDataIsLoading: false,
        data: null,
        error: action.error,
        credentialValid: false
      };
    case ADD_TOWEL_ACCESS_BUTTON:
      return {
        ...state,
        data: {
          ...state.data,
          entries: [
            ...state.data.entries,
            {
              name: "Towel Door",
              tag: "towelDoor",
            },
          ],
        },
      };
    default:
      return state;
  }
};

export default access;
