import React, { Component } from "react";
// import { Card, Col } from "react-bootstrap";
import Card from "../General/Card";
import "./Memberships.css";
// import { ParallaxBanner } from "react-scroll-parallax";
// import ImageOne from "../../images/BlurryBackground.jpg";

class MembershipCardGroup extends Component {
  cardStyles = {
    height: "5%",
    minHeight: "120px",
    maxHeight: "180px",
    width: "10%",
    minWidth: "300px",
    maxWidth: "400px",
  };
  cardData = [
    {
      cost: "65",
      title: "",
      linkId: "133",
      context: "5 Sessions Per Month",
    },
    {
      cost: "95",
      title: "",
      linkId: "134",
      context: "8 Sessions Per Month",
    },
    {
      cost: "125",
      title: "",
      linkId: "135",
      context: "12 Sessions Per Month",
    },
    {
      cost: "155",
      title: "",
      linkId: "121",
      context: "UNLIMITED Sessions Per Month",
    },
  ];
  createWidgetLink = (id, buttonName) => {
    return (
      <>
        <script
          src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
          type="text/javascript"
        />
        <healcode-widget
          data-version="0.2"
          data-link-class="healcode-contract-text-link"
          data-site-id="26503"
          data-mb-site-id="43256"
          data-bw-identity-site="false"
          data-type="contract-link"
          data-inner-html={buttonName}
          data-service-id={id}
        />
      </>
    );
  };
  createCards = () => {
    return this.cardData.map((card, i) => {
      return (
        <Card key={i} className="single-card">
          <div className="SW-Card-Body">
            <div className="CardMoneyGroup ColorBlack">
              <div className="CardMoneySign SWMedium">$</div>
              <div className="CardMoneyValue SWMedium">{card.cost}</div>
              <div className="CardMoneyText SWMedium">/mo</div>
            </div>
            <div className="SW-Card-Context ColorBlack">{card.context}</div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                {this.createWidgetLink(card.linkId, "Add To Cart")}
              </small>
            </div>
          </div>
        </Card>
      );
    });
  };
  render() {
    return (
      <>
        <div className="PackagesIntro">
          <h1 className="MembershipsIntroTextSubtext">or</h1>
          <h1 className="PackagesIntroText TitleFont">Choose A Membership</h1>
        </div>
        <div className="CardGroup">{this.createCards()}</div>
      </>
    );
  }
}
export default MembershipCardGroup;

/* <div className="SW-Card">
            <div className="SW-Card-Header">
              <h1 className="">Deluxe</h1>
            </div>
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">95</div>
                <div className="CardMoneyText SWMedium">/mo</div>
              </div>
              <div>8 Sessions Per Month</div>
          </div>
        <div style={{ height: "40px" }}>
          <small className="text-muted">
            <script
              src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
              type="text/javascript"
            ></script>
            <healcode-widget
              data-version="0.2"
              data-link-class="healcode-contract-text-link"
              data-site-id="26503"
              data-mb-site-id="43256"
              data-bw-identity-site="false"
              data-type="contract-link"
              data-inner-html="Buy Now"
              data-service-id="134"
            />
          </small>
          <div className="SW-Card">
            <div className="contractCardBody">
              <div>
            <div className="SW-Card-Header">
              <h1 className="">Pro</h1>
            </div>
          </div>
          <div className="CardMoneyGroup">
            <div className="CardMoneySign SWMedium">$</div>
            <div className="CardMoneyValue SWMedium">125</div>
            <div className="CardMoneyText SWMedium">/mo</div>
          </div>
          <div>12 Sessions Per Month</div>
        </div>
        <div style={{ height: "40px" }}>
          <small className="text-muted">
            <script
              src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
              type="text/javascript"
            ></script>
            <healcode-widget
              data-version="0.2"
              data-link-class="healcode-contract-text-link"
              data-site-id="26503"
              data-mb-site-id="43256"
              data-bw-identity-site="false"
              data-type="contract-link"
              data-inner-html="Buy Now"
              data-service-id="135"
            />
          </small>
          <div className="SW-Card">
            <div className="contractCardBody">
              <div>
            <h1 className="SW-Card-Header">Elite</h1>
          </div>
          <div className="CardMoneyGroup">
            <div className="CardMoneySign SWMedium">$</div>
            <div className="CardMoneyValue SWMedium">155</div>
            <div className="CardMoneyText SWMedium">/mo</div>
          </div>
          <div>UNLIMITED Sessions Per Month</div>
        </div>
        <div style={{ height: "40px" }}>
          <small className="text-muted">
            <script
              src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
              type="text/javascript"
            ></script>
            <healcode-widget
              data-version="0.2"
              data-link-class="healcode-contract-text-link"
              data-site-id="26503"
              data-mb-site-id="43256"
              data-bw-identity-site="false"
              data-type="contract-link"
              data-inner-html="Buy Now"
              data-service-id="121"
            />
          </small> */
