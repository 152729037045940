import { RESET_COOR, SET_COOR } from "../../sagas/actions";

const initAccessState = {
  lat: 44.93440483032277,
  lng: -93.17737065347846,
};

const mapCoor = (state = initAccessState, action) => {
  switch (action.type) {
    case SET_COOR:
      return {
        ...action.payload,
      };
    case RESET_COOR:
      return {
        lat: null,
        lng: null,
      };
    default:
      return state;
  }
};

export default mapCoor;
