import React from "react";
import HomeLocationWidget from "../Map/HomeLocationWidget";
import "./Home.css";

export default class VideoOverlay extends React.Component {
  state = {
    windowWidth: 500,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWidthChange);
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }

  handleWidthChange = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  render() {
    return (
      <div className="VideoOverlay">
        <div className="home-location-widget-background">
          <div className="home-location-widget-wrapper">
            {this.state.windowWidth > 1000 ? (
              <HomeLocationWidget widget={true} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
