import React from "react";
import { connect } from "react-redux";
import FailureModal from "./FailureModal";
import LoadingModal from "./LoadingModal";

export const LoadingWrapper = ({
  children,
  isLoading,
  isSuccess,
  isFailure,
}) => {
    if (isLoading) {
      console.log('LoadingWrapper isLoading: ', isLoading);
      return <LoadingModal>{children}</LoadingModal>;
    } else if (isSuccess) {
      console.log('LoadingWrapper isSuccess: ', isSuccess);
        return children;
    } else if (isFailure) {
      console.log('LoadingWrapper isFailure: ', isFailure);
        return <FailureModal>{children}</FailureModal>;
    }
};

const mapStateToProps = (reduxState, props) => {
  const { stateReader, children } = props;
  const { isLoading, error } = stateReader(reduxState) || {};

  return {
    isLoading,
    isSuccess: !isLoading && !error,
    isFailure: !!error,
    children
  };
};
export default connect(mapStateToProps)(LoadingWrapper);
