import React, { Component } from "react";
import MembershipCardGroup from "./MembershipCardGroup";
import SingleSessionGroup from "./SingleSessionGroup";
import PackagesCardGroup from "./PackagesCardGroup";
import "./Pricing.css";
import { SET_POSITION } from "../../sagas/actions";
import { connect } from "react-redux";
import PageTemplate from "../General/Pages/PageTemplate";
import MindBodyComponent from "../mindbody/MindBodyComponent";

class Pricing extends Component {
  render() {
    return (
      <PageTemplate>
        <div className="PricingWrapper">
          <div className="PricingContent">
            <SingleSessionGroup />
            <MembershipCardGroup />
            <PackagesCardGroup />
            <MindBodyComponent /> {/* This need to be here for the links to load */}
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNavPos: (payload) => {
      dispatch({
        type: SET_POSITION,
        payload: payload,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
