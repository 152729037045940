import React from "react";
import "./roomViewCommands.css";
import { connect } from "react-redux";
import { EI_ROOM_COMMAND, SEND_REQUEST } from "../../../sagas/actions";
import requestEISaunaCommand from "../../../modules/api/ei/requestEISaunaCommand";

const RoomViewCommands = ({
  requestRoomCommand,
  staffId,
  siteId,
  roomName,
  reduxState,
}) => {
  const handleClick = (command) => {
    console.log("command: ", command);
    requestRoomCommand(siteId, staffId, command, localStorage.getItem("token"));
  };
  return (
    <div className="ei-room-commands-wrapper">
      <div className="ei-room-commands-group">
        <div className="ei-room-commands-header">
          <strong className="ei-commands-title">SAUNA CONTROLS</strong>
          <div>{roomName}</div>
        </div>
        <div className="ei-room-commands-center">
          <div className="ei-room-command-row">
            <div className="ei-room-command-title">
              TURN <strong>ON</strong> RED LIGHT
            </div>
            <button
              value="turn_on_red_light"
              className="ei-room-command-send glow-on-hover"
              onClick={(e) => {
                handleClick(e.target.value);
              }}
            >
              SEND
            </button>
          </div>
          <div className="ei-room-command-row">
            <div className="ei-room-command-title">
              TURN <strong>OFF</strong> RED LIGHT
            </div>
            <button
              value="turn_off_red_light"
              className="ei-room-command-send glow-on-hover"
              onClick={(e) => {
                handleClick(e.target.value);
              }}
            >
              SEND
            </button>
          </div>
          <div className="ei-room-command-row">
            <div className="ei-room-command-title">
              <strong>ABORT</strong> CURRENT PREHEAT REQUEST
            </div>
            <button
              value="abort_preheat"
              className="ei-room-command-send glow-on-hover"
              onClick={(e) => {
                handleClick(e.target.value);
              }}
            >
              SEND
            </button>
          </div>
          <div className="ei-room-command-row">
            <div className="ei-room-command-title">
              TOGGLE F°/C° Button
            </div>
            <button
              value="toggle_celcius"
              className="ei-room-command-send glow-on-hover"
              onClick={(e) => {
                handleClick(e.target.value);
              }}
            >
              SEND
            </button>
          </div>
          <div className="ei-room-command-row">
            <div className="ei-room-command-title">
              <div>RESET ALL RELAYS (Use if Sauna Panel is Frozen)</div>
              <strong>WARNING: THIS WILL CAUSE DISRUPTIONS TO THE SAUNA!</strong>
              <div>It will cycle all the control and power relays which resets & reboots the sauna.</div>
            </div>
            <button
              value="reset_all_relays"
              className="ei-room-command-send glow-on-hover"
              onClick={(e) => {
                handleClick(e.target.value);
              }}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
      <div className="ei-room-response-group">
        <div className="ei-room-response-status-wrapper">
          <div className="ei-room-response-status-group">
            <strong className="ei-room-response-status-title">STATUS:</strong>
            <div className="ei-room-response-status">
              {reduxState.eiCommandsMessageBox.status}
            </div>
          </div>
          <div className="ei-room-response-status-checkmark">
            {/* <p className="mark-check">&#x2714;</p> */}
          </div>
        </div>
        <div className="ei-room-response-message-wrapper">
          <strong className="ei-room-response-status-title">Message:</strong>
          <strong className="ei-room-response-message">
            {reduxState.eiCommandsMessageBox.message}
          </strong>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestRoomCommand: async (siteId, staffId, command, token) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_ROOM_COMMAND,
        apiCall: requestEISaunaCommand,
        apiCallParams: {
          token: token,
          siteId: siteId,
          staffId: staffId,
          command: command,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewCommands);
