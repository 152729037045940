import React, { Component } from "react";
import SWPageTemplate from "../General/Pages/PageTemplate";

class ServicesPage extends Component {
  render() {
    return (
      <div className="ServicesPage">
        <SWPageTemplate>
          <h1 style={{ height: "100vh" }}> Services Page </h1>{" "}
        </SWPageTemplate>{" "}
      </div>
    );
  }
}
export default ServicesPage;
