import React from "react";
import { BackButton } from "../EmployeeInterface/components/BackButton";

export const FailureModal = (props) => {
  return (
    <div className="ei-failure-modal-wrapper">
      <BackButton set={props.set} viewValue="home"/>
      <div>Failed to Load Data!</div>
    </div>
  );
};

export default FailureModal;
