import {
  RED_LIGHT_MESSAGE_BOX_RESET,
  RED_LIGHT_MESSAGE_BOX_EXPIRED,
  RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR,
  RED_LIGHT_MESSAGE_BOX_INVALID,
  RED_LIGHT_MESSAGE_BOX_DISABLED,
} from "../../sagas/actions";

const initAccessState = {
  message: null,
  buttonDisabled: false,
};

const redLightMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case RED_LIGHT_MESSAGE_BOX_RESET:
      return {
        message: null,
        buttonDisabled: false,
      };
    case RED_LIGHT_MESSAGE_BOX_EXPIRED:
      return {
        message: `This pass has expired!`,
        buttonDisabled: true,
      };
    case RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR:
      return {
        message: `Oh no! An Internal Error occured, we were unable to process this request.`,
        buttonDisabled: true,
      };
    case RED_LIGHT_MESSAGE_BOX_INVALID:
      return {
        message: `Sorry! This pass Is Invalid!`,
        buttonDisabled: true,
      };
      case RED_LIGHT_MESSAGE_BOX_DISABLED:
      return {
        ...state,
        buttonDisabled: true,
      };
    default:
      return state;
  }
};

export default redLightMessageBox;
