import React, { useState } from "react";
import "./EmployeeInterfaceLogin.css";
import { connect } from "react-redux";
import requestEIAuthToken from "../../../modules/api/ei/requestEIAuthToken";
import { SEND_REQUEST, EI_LOGIN } from "../../../sagas/actions";
import Roller from "../../General/animations/Roller";

function EILoginView(props) {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  return (
    <div className="admin-login-container">
      <div className="admin-login-title">EMPLOYEE LOGIN</div>
      <div className="login-input-wrapper">
        <input
          placeholder="Username"
          value={loginUsername}
          onChange={(e) => {
            setLoginUsername(e.target.value);
          }}
        ></input>
        <input
          placeholder="Password"
          type="password"
          value={loginPassword}
          onChange={(e) => {
            setLoginPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              props.requestLoginToken(
                loginUsername,
                loginPassword,
                props.refreshTokenStatus
              );
            }
          }
          }
        ></input>
      </div>
      <div className="ei-signup-response-bar">
        <Roller />
        {props.reduxState.loginMessageBox.message}
      </div>
      <div className="ei-login-button-row">
        <div className="admin">
          <button
            className="ei-login-button glow-on-hover"
            onClick={() => {
              props.requestLoginToken(
                loginUsername,
                loginPassword,
                props.refreshTokenStatus
              );
            }}
          >
            SUBMIT
          </button>
        </div>
        <button
          className="ei-login-button glow-on-hover"
          onClick={() => {
            props.set('loginView', 'signUp');
          }}
        >
          SIGN UP
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestLoginToken: async (loginUsername, loginPassword, callback) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_LOGIN,
        apiCall: requestEIAuthToken,
        apiCallParams: {
          username: loginUsername,
          password: loginPassword,
          callBack: callback,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EILoginView);
