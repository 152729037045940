import React, { Component } from "react";
import "./Card.css";
import "./Card-custom.css";
import { connect } from "react-redux";

export class Card extends Component {
  render() {
    return (
      <div className={"SW-Card"}>
        <div
          className={
            this.props.headerClassname
              ? this.props.headerClassname
              : "SW-Card-Header ColorWhite"
          }
          style={{ fontWeight: 500 }}
        >
          {this.props.header}
        </div>
        <div
          className={
            this.props.bodyClassname ? this.props.bodyClassname : "SW-Card-Body"
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(Card);
