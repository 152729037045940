import React, { Component } from "react";
import "./BookPage.css";
import { connect } from "react-redux";
import { SWEATWORKS_URL } from "../../TestingVariables";
import MindbodyChoiceSelection from "./MindbodyChoiceSelection";
import SWPageTemplate from "../General/Pages/PageTemplate";
import Checkbox from "../General/Checkbox";
import Cookies from "js-cookie";

class BookPage extends Component {
  state = {
    optIn: false,
    optedIn: Cookies.get("optedIn") === "true" ? true : false,
  };
  toggleCheckBox = () => {
    console.log("in toggle checkbox");
    this.setState({ optIn: !this.state.optIn });
  };
  submit = () => {
    if (this.state.optIn === true) {
      this.setState({
        optedIn: true,
      });
      Cookies.set("optedIn", "true");
    }
  };

  render() {
    return (
      <SWPageTemplate className="BookPage">
        <div
          className="BookContent"
          style={{
            width: "80%",
            minHeight: "90vh",
            maxWidth: "1400px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <strong className="book-note">
            NOTE: Make sure you have purchased a session or appointment package before proceeding!
          </strong>
          <a className="havent-purchased" href={`${SWEATWORKS_URL}/pricing`}>
            <small className="havent-purchased">Haven't purchased?</small>
          </a>
          <br />
          <br />
          <strong className="book-note">If you wish to puchase for the "NE Wellness" locations, please continue</strong>
          <a href="https://newellnessmpls.com/" className="havent-purchased">Here!</a>
          {/* <strong className="book-attention">
            If you are booking an appointment at the Bismark, ND location please continue{" "}
            <a href="https://newellnessmpls.com/">Here</a>!
            </strong> */}
          <hr />
          {!this.state.optedIn ? (
            <div>
              <div className="opt-in-group">
                <strong className="opt-in-title">
                  Before booking an appointment you must opt-in to receive text messages from our parent company Awaken
                  For Wellness.
                </strong>
                <div>
                  If you book an unstaffed appointment, you will receive two text messages per appointment. By checking
                  the box and submitting, you agree to receive text messages from Awaken For Wellness. Future Msg & Data
                  rates may apply.
                </div>
              </div>
              <div className="opt-in-container">
                <Checkbox label={"I agree"} handleCheckboxChange={this.toggleCheckBox} id="opt-in-checkbox" />
                <button
                  className="glow-on-hover"
                  type="button"
                  disabled={!this.state.optIn}
                  onClick={() => {
                    this.submit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div>
              <MindbodyChoiceSelection />
            </div>
          )}
        </div>
      </SWPageTemplate>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(BookPage);

// <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script><healcode-widget data-version="0.2" data-link-class="healcode-contract-text-link" data-site-id="26503" data-mb-site-id="43256" data-bw-identity-site="false" data-type="contract-link" data-inner-html="Buy Now" data-service-id="133" />
