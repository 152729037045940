import { EI_RESET_COMMANDS_MESSAGE, EI_SET_COMMANDS_MESSAGE } from "../../sagas/actions";

const initAccessState = {
  message: null,
  status: null
};

const eiCommandsMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case EI_SET_COMMANDS_MESSAGE:
      return {
        message: action.payload.message,
        status: action.payload.status
      };
    case EI_RESET_COMMANDS_MESSAGE:
      return {
        message: null,
        status: null,
      };
    default:
      return state;
  }
};

export default eiCommandsMessageBox;
