import axios from "axios";
import { put } from "redux-saga/effects";
import {
  EI_SIGNUP_MESSAGE_BOX_RESET,
  EI_SET_VIEW_LOGIN,
  EI_SIGNUP_MESSAGE_BOX_FAILED,
  EI_SIGNUP_MESSAGE_BOX_INTERNAL_ERROR,
  EI_SIGNUP_MESSAGE_BOX_INVALID,
  EI_SIGNUP_MESSAGE_BOX_SUCCESS,
} from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEISignup(apiCallParams) {
  console.log("in requestEISignup apiCallParams: ", apiCallParams);
  yield put({ type: EI_SIGNUP_MESSAGE_BOX_RESET });
  const data = {
    username: apiCallParams.username,
    password: apiCallParams.password,
    invite: apiCallParams.invite,
  };
  const headers = {
    "content-type": "application/json",
  };
  const result = yield axios
    .post(`${SWEATWORKS_API}/ei/signup`, data, headers)
    .then((res) => {
      console.log("res", res);
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("requestEISignup error: ", error);
      return error;
    });
  if (result.status === 200) {
    yield put({ type: EI_SIGNUP_MESSAGE_BOX_SUCCESS });
    yield put({ type: EI_SET_VIEW_LOGIN });
    return true;
  } else if (
    result.status === 240 ||
    result.status === 250 ||
    result.status === 500
  ) {
    console.log("result.data.error: ", result.data.error);
    if (
      result.data.error === "Internal Server Error" ||
      result.status === 500
    ) {
      yield put({ type: EI_SIGNUP_MESSAGE_BOX_INTERNAL_ERROR });
      return false;
    } else if (result.data.error === "Credentials Are Invalid") {
      yield put({ type: EI_SIGNUP_MESSAGE_BOX_INVALID });
      return false;
    }
  } else {
    console.log("requestEISignup: UNKOWN ERROR!");
    yield put({ type: EI_SIGNUP_MESSAGE_BOX_FAILED });
    return false;
  }
}

export default requestEISignup;
