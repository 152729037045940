import React, { Component } from "react";
import "./HomeLocationWidget.css";
import PhoneIcon from "../../images/assets/phone-30.png";
import TargetIcon from "../../images/assets/teal-map-marker_edit.png";
import { ReactComponent as SearchIcon } from "../../images/assets/sw-search-icon.svg";
import "aos/dist/aos.css";
import SWLoadingModal from "../General/SWLoadingModal";
import SWFailed from "../General/SWFailed";
import { getDistanceBetween } from "./getDistanceBetween";
import LocationAutocomplete from "./LocationAutocomplete";
import Geocode from "react-geocode";
import { SWEATWORKS_URL } from "../../TestingVariables";
import BookNowButton from "../Nav/BookNowButton";
import { SEND_LOCATION_DATA, SEND_REQUEST } from "../../sagas/actions";
import sendLocationDataApiCall from "../../modules/api/sweatworks_web/sendLocationData";
import { connect } from "react-redux";
Geocode.setApiKey("AIzaSyBFhZtq6KpukyULm_u77oJcPfsMV0PYhKo");
Geocode.setRegion("us");

class HomeLocationWidget extends Component {
  state = {
    center: { lat: 44.9346103614, lng: -93.1773953441 }, //initial map center
    lat: 0,
    lng: 0,
    loading: false,
    failed: false,
    closestLocations: [],
    displayMore: false,
    searchQuery: "",
    widget: this.props.widget,
  };

  locations = [
    {
      locationId: 1,
      location: "Awaken For Wellness - St Paul",
      lat: 44.93440483032277,
      lng: -93.17737065347846,
      addressFH: "1821 St Clair Ave,",
      addressSH: "St Paul, MN 55105",
      phoneNumber: "651-493-0459",
    },
    {
      locationId: 2,
      location: "Awaken For Wellness - Hudson",
      lat: 44.97726233819796,
      lng: -92.71782387767144,
      addressFH: "411 Co Rd UU,",
      addressSH: "Hudson, WI 54016",
      phoneNumber: "715-531-4035",
    },
    {
      locationId: 3,
      location: "The Salt Room Woodbury",
      lat: 44.91847441142029,
      lng: -92.90182056646333,
      addressFH: "2110 Eagle Creek Ln Suite 350,",
      addressSH: "Woodbury, MN 55129",
      phoneNumber: "715-531-4035",
    },
  ];

  setSearchQuery = (x) => {
    this.setState({
      searchQuery: x,
    });
  };

  handleLocationInputChange = (action) => {
    this.setState({
      loading: false,
      failed: false,
      locationInputValue: action.value,
    });
  };

  updateClosestStores = (lat, lng) => {
    let storeDistances = [];
    for (let i = 0; i < this.locations.length; i++) {
      const location = this.locations[i];
      const dist = getDistanceBetween(
        lat,
        lng,
        location.lat,
        location.lng,
        "N"
      );
      storeDistances.push({ ...location, dist: dist });
    }
    storeDistances.sort((a, b) => (a.dist > b.dist ? 1 : -1));
    this.setState({
      closestLocations: storeDistances,
      loading: false,
      failed: false,
    });
    return storeDistances;
  };

  updateStores = () => {
    if (!!navigator.geolocation && this.state.searchQuery === "") {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!!position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.setState({
            lat: lat,
            lng: lng,
            failed: false,
          });
          this.props.sendLocationData({ lat: lat, lng: lng }, "located");
          this.updateClosestStores(lat, lng);
        }
      });
    } else if (this.state.searchQuery !== "" && !!this.state.searchQuery) {
    } else {
      console.log("Geolocation not supoorted by the browser");
      this.setState({
        failed: true,
      });
    }
  };

  geolocate = async (location) => {
    try {
      return Geocode.fromAddress(location).then((geocodeResult) => {
        const { lat, lng } = geocodeResult.results[0].geometry.location;
        console.log("geocodeResult: ", geocodeResult);
        console.log("geolocate lat, lng: ", lat, lng);
        this.props.sendLocationData({ lat: lat, lng: lng }, "searched");
        return { lat: lat, lng: lng };
      });
    } catch (error) {
      console.log("geolocate error: ", error);
      return false;
    }
  };

  componentDidMount() {
    this.updateStores();
  }
  handleSearchClick = async () => {
    console.log("CLICK HANDLED for search query: ", this.state.searchQuery);
    if (this.state.searchQuery !== "") {
      this.geolocate(this.state.searchQuery).then((location) => {
        console.log("search location lat, lng: ", location);
        this.updateClosestStores(location.lat, location.lng);
      });
    }
  };
  createCards = (closestLocations) => {
    return closestLocations.map((location, i) => {
      if (i === 0 || this.state.displayMore) {
        return (
          <div key={location.location} className="LocationCardWidget">
            <div className="CardSection">
              <div className="location-card-title S">
                <a
                  className="location-widget-title-wrapper ColorCharcoal"
                  href={`${SWEATWORKS_URL}/locations/${location.locationId}`}
                >
                  {location.location}
                </a>
              </div>
              <div className={"LocationAddressText"}>
                {location.addressFH}
                <br />
                {location.addressSH}
              </div>
            </div>
            <div className="CardBottomSection">
              <div className="CardPhoneGroup">
                <img
                  src={PhoneIcon}
                  alt="Map Icon"
                  className="LocationCardIcon"
                />
                <a
                  href={`tel:${location.phoneNumber}`}
                  className="LocationPhoneText sw-color-primary-hover"
                >
                  {location.phoneNumber}
                </a>
              </div>
              <a
                className="location-widget-find-more-group"
                href={`${SWEATWORKS_URL}/locations/${location.locationId}`}
              >
                <div className="location-widget-view-map ColorCharcoal">
                  Find on Map
                </div>
                <img
                  className="TargetIcon"
                  src={TargetIcon}
                  alt="Center Map to Location"
                  onClick={() => {
                    this.handleLocationClick(location.lat, location.lng);
                  }}
                />
              </a>
            </div>
            <div className="location-distance">
              {location.dist > 0
                ? `Distance ${Math.round(location.dist * 10) / 10} miles`
                : ""}
            </div>
            <div className="book-a-session-wrapper">
              <a
                href={`${SWEATWORKS_URL}/book`}
                className="book-a-session sw-color-primary sw-color-primary-hover"
              >
                <BookNowButton />
              </a>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div
        className={this.props.widget ? "HomeLocationWidget" : "HomeLocationRow"}
      >
        <div
          className={this.props.widget ? "LocationsList" : "LocationsListRow"}
        >
          <div className="map-widget-title-group">
            <div className="MapWidgetTitle TitleFont divorWhite L Left">
              Find a Studio Near You
            </div>
            <div className="zip-search-container">
              <LocationAutocomplete
                handleInputClick={this.updateStores}
                searchQuery={this.state.searchQuery}
                setSearchQuery={this.setSearchQuery}
              />
              <SearchIcon
                className="sw-search-icon"
                alt="search"
                onClick={() => {
                  this.handleSearchClick(this.state.searchInput);
                }}
              />
            </div>
          </div>
          {this.state.closestLocations.length > 0 ? (
            <div className="location-widget-closest-location">
              Closest Studio:
            </div>
          ) : null}
          <div className="locations-widget-wrapper">
            {!!this.state.loading
              ? false
              : this.createCards(this.state.closestLocations)}
            <div
              className={
                this.state.loading && !this.state.failed
                  ? "loading-modal-open"
                  : "loading-modal-closed"
              }
            >
              <SWLoadingModal message="Nearest Studio" />
            </div>
            <div
              className={
                this.state.failed
                  ? "loading-modal-open"
                  : "loading-modal-closed"
              }
            >
              <SWFailed message="Failed To Load." />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendLocationData: (location, type) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: SEND_LOCATION_DATA,
        apiCall: sendLocationDataApiCall,
        apiCallParams: { position: location, type: type },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(HomeLocationWidget);
