const {
  REQUEST_ENTRY_UNLOCK_LOADING,
  REQUEST_ENTRY_UNLOCK_SUCCESS,
  REQUEST_ENTRY_UNLOCK_FAILURE,
  REQUEST_ENTRY_UNLOCK_RESET,
  LOADING,
  SUCCESS,
  FAILURE,
} = require("../../sagas/actions");

const initialState = {
  access: {
    frontDoor: null,
    towelDoor: null,
    frontDoorError: null,
    towelDoorError: null,
  },
};

const accessIconStatuses = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTRY_UNLOCK_LOADING: {
      console.log("REQUEST_ENTRY_UNLOCK_LOADING action: ", action);
      return {
        ...state,
        [action.params.entry]: LOADING,
      };
    }
    case REQUEST_ENTRY_UNLOCK_SUCCESS:
      console.log("REQUEST_ENTRY_UNLOCK_SUCCESS HIT!!!");
      return {
        ...state,
        [action.params.entry]: SUCCESS,
      };
    case REQUEST_ENTRY_UNLOCK_FAILURE:
      return {
        ...state,
        [action.params.entry]: FAILURE,
        [action.params.entry + 'Error']: action.error,
      };
    case REQUEST_ENTRY_UNLOCK_RESET:
      return {
        frontDoor: null,
        towelDoor: null,
        frontDoorError: null,
        towelDoorError: null,
      };
    default:
      return state;
  }
};

export default accessIconStatuses;
