import React, {Component} from 'react';
 import './BookNowButton.css'
class BookNowButton extends Component {
    render() {
        return (
            <div className='book_now_button'>
                <button className="glow-on-hover" type="button">Book Now</button>
            </div>
        )
    }
}
export default BookNowButton;