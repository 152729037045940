import React, { Component } from "react";
import SWPageTemplate from "../General/Pages/PageTemplate";

class ProductsPage extends Component {
  render() {
    return (
      <div className="ProductsPage">
        <SWPageTemplate>
          <h1 style={{ height: "100vh" }}> Products Page </h1>{" "}
        </SWPageTemplate>{" "}
      </div>
    );
  }
}
export default ProductsPage;
