import React, { Component } from "react";
import { BenefitsContent, benefitPositions } from "./BenefitsContent";
import "./Benefits.css";
import "../Pricing/CardGroup.css";
import "../General/pulse.css";
import GirlWithTowel from "../../images/female-with-towel_2.png";
import { connect } from "react-redux";
import { CLOSE_ALL_BENEFITS, OPEN_BENEFIT } from "../../sagas/actions";
import BenefitsModal from "./BenefitsModal";
import { SWEATWORKS_URL } from "../../TestingVariables";

export class BenefitGrid extends Component {
  state = {
    size: "72px",
    modal: false,
    modalType: 1,
    title: "title",
    content: "content",
    hrefReadMore: null,
    windowWidth: 500,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWidthChange);
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }

  handleWidthChange = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  show = () => {
    this.setState({ modal: true });
  };

  hide = () => {
    this.setState({ modal: false });
  };

  getPosDetails = (posId) => {
    for (let i = 0; i < benefitPositions.length; i++) {
      const position = benefitPositions[i];
      if (position.id === posId) {
        return position;
      }
    }
  };

  click = (itemId) => {
    console.log("handling icon click: ", itemId);
    console.log("!this.state.modal: ", !this.state.modal);
    BenefitsContent.forEach((benefit) => {
      if (itemId === benefit.id) {
        console.log("setAtate: ", benefit.title, benefit.content);
        this.setState({
          title: benefit.title,
          content: benefit.content,
          modalType: itemId,
          modal: !this.state.modal,
          hrefReadMore: benefit.url,
        });
      }
    });
  };

  createBenefitOverlays = () => {
    return BenefitsContent.map((benefit) => {
      const posInfo = this.getPosDetails(benefit.id);
      const side = posInfo.facing === "right" ? "Right" : "Left";
      const baseSide = posInfo.facing === "right" ? "left" : "right";
      return (
        <div
          className="BenefitWrapper"
          key={benefit.id}
          id={`BenefitWrapper${side}`}
          style={{ top: posInfo.top, [baseSide]: posInfo.left }}
        >
          <div
            className="BenefitIconWrapper blob"
            id={`BenefitTileIconWrapper`}
          >
            <img
              className="BenefitIcon"
              onClick={() => {
                this.click(benefit.id);
              }}
              src={benefit.icon}
              alt={benefit.title}
            />
          </div>
          <div className="LabelGroup" id={`LabelGroup${side}`}>
            <div className="BenefitUnderline"> </div>
            <div className="BenefitTitle ItalicContentFont">
              {benefit.title}
            </div>
          </div>
        </div>
      );
    });
  };

  createMobileBenefits = () => {
    return BenefitsContent.map((benefit) => {
      const posInfo = this.getPosDetails(benefit.id);
      const side = posInfo.facing === "right" ? "Left" : "Right";
      const baseSide = posInfo.facing === "right" ? "left" : "right";
      return (
        // <div className="BenefitWrapperGroup">
        <div
          className="MobileBenefitWrapper"
          key={benefit.id}
          id={`MobileBenefitWrapper${side}`}
          style={{
            top: `calc(${posInfo.top} + 15%)`,
            [baseSide]: `calc(${posInfo.left} - 15%)`,
          }}
          onClick={() => {
            this.click(benefit.id);
          }}
        >
          {/* <div
            className="BenefitIconWrapper "
            id={`BenefitTileIconWrapper`}
          > */}
          <img
            className="MobileBenefitIcon blob"
            src={benefit.icon}
            alt={benefit.title}
          />
          {/* </div> */}
          <div className="mobile-benefit-title  ItalicContentFont">{benefit.title}</div>
        </div>
        // </div>
      );
    });
  };

  render() {
    return (
      <div className="BenefitsGrid">
        <div className="BenefitsContentWrapper">
          <div
            className="benefit-grid-overlay-wrapper"
            style={{ backgroundImage: `url(${GirlWithTowel})` }}
          >
            {this.state.modalType ? (
              <BenefitsModal
                title={this.state.title}
                color={"#42bca9"}
                content={this.state.content}
                modal={this.state.modal}
                hide={this.hide}
                hrefReadMore={`${SWEATWORKS_URL}/benefits/${this.state.hrefReadMore}`}
              />
            ) : null}
            <div className="benefits-grid-title-wrapper">
              <div className="benefits-grid-title TitleFont">
                SEE HOW INFRARED SAUNA SESSIONS CAN BENEFIT YOU !
              </div>
            </div>
            <div className="BenefitsGridOverlay">
              {this.state.windowWidth < 600
                ? this.createMobileBenefits()
                : this.createBenefitOverlays()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openBene: (id) => {
      dispatch({
        type: OPEN_BENEFIT,
        payload: id,
      });
    },
    closeAllBenes: () => {
      dispatch({
        type: CLOSE_ALL_BENEFITS,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitGrid);
