import React from "react";
import { BackButton } from "../EmployeeInterface/components/BackButton";
import "./Modal.css";
export const LoadingModal = (props) => {
  return (
    <div className="ei-failure-modal-wrapper">
      {/* <BackButton set={props.set} viewValue="home" /> */}
      <div className="ei-loading-group">
        {/* <div className="ei-loading-text">Loading Data</div> */}
        <div className="ei-loader-wheel"></div>
      </div>
    </div>
  );
};

export default LoadingModal;
