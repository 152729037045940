import { SET_EI_AUTH_TOKEN, GET_EI_AUTH_TOKEN } from "../../sagas/actions";

const initAccessState = null;

const eiAuth = (state = initAccessState, action) => {
  switch (action.type) {
    case SET_EI_AUTH_TOKEN:
      return {
        ...action.payload,
      };
    case GET_EI_AUTH_TOKEN:
      return state;
    default:
      return state;
  }
};

export default eiAuth;
