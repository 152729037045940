import axios from "axios";
import { put } from "redux-saga/effects";
import { EI_RESET_LOCATION_DATA } from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* eiRemoveLocationScheduleException(apiCallParams) {
  yield put({ type: EI_RESET_LOCATION_DATA });
  const { siteId, locationId, token, date, open, close } = apiCallParams;
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
  };
  console.log("eiRemoveLocationScheduleException request data!: ", token, date, open, close);
  const body = {
    siteId: siteId,
    date: date,
    open: open,
    close: close,
  };

  yield axios
    .post(
      `${SWEATWORKS_API}/ei/schedule/remove_exception/${locationId}`,
      body,
      { headers: headers }
    )
    .then((res) => {
      console.log("eiRemoveLocationScheduleException res.status: ", res.status);
      console.log("res.data: ", res?.data);
    });
}

export default eiRemoveLocationScheduleException;
