import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import { Card } from "../General/Card";
const { timeFormatter, dateFormatter } = require("../../modules/tools");

export class TimeFrameCard extends Component {
  render() {
    return (
      <div className="TimeFrameCardWrapper">
        <Card header="ACCESS PASS">
          <div>
            {timeFormatter(this.props.reduxState.access.data.startDateTime)}
            {" - "}
            {timeFormatter(this.props.reduxState.access.data.endDateTime)}
          </div>
          <h5 className="AccessSubtitle">
            This Pass is valid on {dateFormatter(this.props.reduxState.access.data.startDateTime)}
          </h5>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(TimeFrameCard);
