import React from "react";
import sweatworksStudioMainBgVideo from "../../media/sweatworks_studio_onyx_red_light.mp4";
import RedLightBg from "../../images/red-light-background-1.jpg";

export default class RedLightVideoPlayer extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundImage: `url(${RedLightBg})`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="back-drop-video-wrapper"
          style={{
            backgroundSize: "cover",
            backrgoundPosistion: "center",
          }}
        >
          <video
            className="back-drop-video"
            autoPlay
            loop
            muted
            style={{
              maxWidth: "1600px",
              objectFit: "cover",
              backgroundPosition: 'center',
              zIndex: 1,
              width: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <source
              src={sweatworksStudioMainBgVideo}
              type="video/mp4"
              style={{ zIndex: 1 }}
            />{" "}
          </video>{" "}
        </div>{" "}
      </div>
    );
  }
}
