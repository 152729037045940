import React, { Component } from "react";
import { WaveLoading } from "react-loadingg";
import "./AccessContent.css";
export class LoadingAnimation extends Component {
  render() {
    return (
      <div className="LoadingAnimationContainer">
        <WaveLoading />
      </div>
    );
  }
}

export default LoadingAnimation;
