import { CLOSE_ALL_BENEFITS, CLOSE_BENEFIT, INITIALIZE_BENEFIT_STATUSES, OPEN_BENEFIT } from "../../sagas/actions";
const initState = {
  1: true,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

const benefitStatuses = (state = initState, action) => {
  switch (action.type) {
    case INITIALIZE_BENEFIT_STATUSES:
      return {
        ...action.payload,
      };
    case OPEN_BENEFIT:
      return {
        ...state,
        [action.payload]: true,
      };
    case CLOSE_BENEFIT:
      return {
        ...state,
        [action.payload]: false,
      };
    case CLOSE_ALL_BENEFITS:
      return {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      };
    default:
      return state;
  }
};

export default benefitStatuses;
