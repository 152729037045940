import axios from "axios";
import { put } from "redux-saga/effects";
import {
  ACCESS_MESSAGE_BOX_DISABLED,
  RED_LIGHT_ADDED,
  RED_LIGHT_MESSAGE_BOX_EXPIRED,
  RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR,
  RED_LIGHT_MESSAGE_BOX_INVALID,
  RED_LIGHT_MESSAGE_BOX_RESET,
  TOWEL_SERVICE_MESSAGE_BOX_DISABLED,
  UVB_LIGHT_MESSAGE_BOX_DISABLED,
} from "../../sagas/actions";
import { SWEATWORKS_API } from "../../TestingVariables";

// const accessDataApiCall = async({accessKey}) => {
function* addRedLightApiCall(apiCallParams) {
  const accessKey = apiCallParams.accessKey;
  const result = yield axios
    .post(`${SWEATWORKS_API}/appointment/add_service/red_light/${accessKey}`)
    .then(async (res) => {
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("addRedLightApiCall error: ", error);
      return false;
    });
  if (result.status === 200) {
    yield put({ type: RED_LIGHT_ADDED });
    yield put({ type: RED_LIGHT_MESSAGE_BOX_RESET });
    navigator.vibrate([50]);
    return true;
  } else if (result.status === 210 || result.status === 240 || result.status === 250) {
    if (result.data.error === "Appointment has Expired") {
      yield put({ type: RED_LIGHT_MESSAGE_BOX_EXPIRED });
      yield put({ type: TOWEL_SERVICE_MESSAGE_BOX_DISABLED });
      yield put({ type: UVB_LIGHT_MESSAGE_BOX_DISABLED });
      yield put({ type: ACCESS_MESSAGE_BOX_DISABLED });
      return false;
    } else if (result.data.error === "Internal Server Error") {
      yield put({ type: RED_LIGHT_MESSAGE_BOX_INTERNAL_ERROR });
      return false;
    } else if (result.data.error === "Credential Invalid") {
      yield put({ type: RED_LIGHT_MESSAGE_BOX_INVALID });
      return false;
    }
  }
}

export default addRedLightApiCall;
