import React from "react";
import "./led.css"
function Led(props) {
  return (
    <div className="led-box">
      <div className={props.clName} />
    </div>
  );
}
export default Led;
