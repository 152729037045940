const {
  LOADING,
  SUCCESS,
  FAILURE,
  REQUEST_ADD_UVB_LIGHT_LOADING,
  REQUEST_ADD_UVB_LIGHT_SUCCESS,
  REQUEST_ADD_UVB_LIGHT_FAILURE,
  REQUEST_ADD_UVB_LIGHT_RESET,
} = require("../../sagas/actions");

const initialState = {
    status: null
};

const uvbLightIconStatus = (state = initialState, action) => {
  switch (action.type) {
  case REQUEST_ADD_UVB_LIGHT_LOADING: {
      return {
        status: LOADING,
      };
    }
    case REQUEST_ADD_UVB_LIGHT_SUCCESS:
      return {
        status: SUCCESS,
      };
    case REQUEST_ADD_UVB_LIGHT_FAILURE:
      return {
        status: FAILURE,
      };
    case REQUEST_ADD_UVB_LIGHT_RESET:
      return {
        status: null,
      };
    default:
      return state;
  }
};

export default uvbLightIconStatus;
