import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import { TbInfoCircleFilled } from "react-icons/tb";

export class NotWorking extends Component {
  render() {
    return (
      <div className="AccessNotWorkingWrapper">
        <div className="notWorkingText" onClick={()=>{this.props.setAccessModal(true)}}>
          Access Not Working? <TbInfoCircleFilled className="accessNotWorkingIcon"/>
        </div>
        <div className="notWorkingText" onClick={()=>{this.props.setSaunaModal(true)}}>
          Have Sauna Issues? <TbInfoCircleFilled className="accessNotWorkingIcon"/>
        </div>
      </div>
    );
  }
};
const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(NotWorking);