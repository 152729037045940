import React from "react";
import "./EmployeeInterfaceLogin.css";
import { connect } from "react-redux";
import EiLoginView from "./EILoginView";
import EiSignUpView from "./EISignUpView";
import sweatworkPic from "../../../images/sweatworks_logo_transparent_bg.png";
function EmployeeInterfaceLogin(props) {
  return (
    <div className="admin-login-wrapper">
      <img
        className="sweatworks-banner-pic"
        alt="SweatWorks Studio Welcome Header"
        src={sweatworkPic}
      />
      <div className="employee-interface-title-wrapper">
        <div className="employee-interface-title">EMPLOYEE INTERFACE</div>
      </div>
      {props.loginView === "signUp" ? (
        <EiSignUpView set={props.set}  />
      ) : (
        <EiLoginView
          set={props.set}
          refreshTokenStatus={props.refreshTokenStatus}
        />
      )}
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(EmployeeInterfaceLogin);