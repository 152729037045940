import HandIcon from "../../images/assets/custom/SW_1_clean_hands.png";
import SweatIcon from "../../images/assets/custom/SW_1_hot.png";
import ImmuneIcon from "../../images/assets/custom/SW_1_medical-protection.png";
import BloodPressureIcon from "../../images/assets/custom/SW_1_blood_pressure.png";
import ScaleIcon from "../../images/assets/custom/SW_1_scale.png";
import HealthIcon from "../../images/assets/custom/SW_1_arthritis.png";

const BenefitsContent = [{
        id: 1,
        title: "Detoxification",
        url: "detox",
        content: `Sweating is really good for you, seriously! Sweating is one of the body's safest and most natural ways to heal and detoxify. Infrared sauna's are specifically designed to promote a deep, healthy and natural detoxifying sweat.
        The average American has 34% body fat. Body fat is a known reservoir for toxicity as it has an affinity for many kinds of chemicals and heavy metals.  As we consume food and breathe impure air, many toxic compounds can enter our system. Far infrared targets our fat cells to help release these toxins through our sweat.
        *Adding our ONYX RED light causes the fat cells to release triglycerides, resulting in reduced volume and a measurable loss of fat.`,
        icon: SweatIcon,
    },
    {
        id: 2,
        title: "Skin Purification",
        url: "skin_purification",
        content: `Far infrared wavelengths target deep into your subcutaneous layers of your skin which target the fat cells. Our fat cells harbor many toxins; from heavy metals to chemicals. The vasodilating effect of far infrared circulates more blood flow to the surface of your skin to deliver oxygen and nutrients and carry away toxins.
        The next advantage to skin purification is sweating.  During your session you will activate all of your sweat glands and cleanse all of our pores from the inside out!  You can use all the topical skin cleansers in the world but nothing can take the place of sweat. In our saunas you will also experience Near infrared wavelengths. NIR is known to create a collagen stimulating effect for baby-soft, wrinkle reducing, healthy skin.`,
        icon: HandIcon,
    },
    {
        id: 3,
        title: "Cellular Health",
        url: "cell_health",
        content: `Far infrared therapy stimulates the circulatory system and help to deliver more oxygen and nutrients to the body’s cells. Near infrared helps your cells utilize the oxygen to create more ATP or cellular energy! The healthier your cells are the more energy you have, the better immune system you have, and the faster you recover!`,
        icon: ImmuneIcon,
    },
    {
        id: 4,
        title: "Cardiovascular Health",
        url: "cardiovascular_health",
        content: `As you heat up your body in a sauna, your blood vessels dilate (open wider) & your heart rate increases to help cool your body. Not only will your heart rate increase but your blood will slightly thin with a slight elevation in your body temperature. This combination of blood thinning, vasodilation, and elevated heart rate flushes blood through your blood vessels to help reduce blood pressure and flush your capillaries and smaller blood vessels.
        A 20 year Finnish study, with over 2300 participants concluded that using a sauna 1x per week vs 4x or more per week resulted in a significant reduction in heart disease, stroke, reduced blood pressure, & overall mortality. Our saunas give you the combination of full spectrum infrared and capabilities to reach high temperatures of 175 degrees.`,
        icon: BloodPressureIcon,
    },
    {
        id: 5,
        title: "Weight Management",
        url: "weight_management",
        content: `Studies have shown that benefits of an infrared sauna session can burn 250+ calories while you relax! As the body works to cool itself, there is a substantial increase in heart rate, cardiac output, and metabolic rate, causing the body to burn more calories!
        Binghamton University in New York concluded that people who used an infrared sauna three times a week for 30 minutes per session dropped an average of 4 percent body fat over a four-month period. For a 175-pound man, that represents a weight reduction of seven pounds. The study findings illustrate the significant boost that infrared therapy provides for our weight loss goals.`,
        icon: ScaleIcon,
    },
    {
        id: 6,
        title: "Pain Relief",
        url: "pain_relief",
        content: `Infrared saunas are very effective at flushing out the main source of pain for most people; inflammation.  FIR can can produce a pain relieving result by reversing inflammation in the tissue and in inflammatory arthritic joints.
        The increase of bloodflow helps to bring nutrition and oxygen throughout the body and carry away carbon dioxide and other inflammatory biproducts from every area of the body.  A Japanese study showed that chronic pain patients experienced a significant reduction in pain levels (nearly 70%!) after the first session of infrared sauna therapy.`,
        icon: HealthIcon,
    },
];

const benefitPositions = [
    { id: 1, top: "20%", left: "80%", facing: "left" },
    { id: 2, top: "50%", left: "80%", facing: "left" },
    { id: 3, top: "80%", left: "80%", facing: "left" },
    { id: 4, top: "20%", left: "80%", facing: "right" },
    { id: 5, top: "50%", left: "80%", facing: "right" },
    { id: 6, top: "80%", left: "80%", facing: "right" },
];

export { BenefitsContent, benefitPositions };