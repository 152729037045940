import {
  EI_LOCATION_DATA,
  EI_REQUEST_UPDATE_LOCATION_DATA,
} from "../../sagas/actions";
import { makeLoadingActionTypes } from "../../sagas/loadingSaga";

const defaultState = {
  schedule: [
    { day: "Monday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Tuesday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Wednesday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Thursday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Friday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Saturday", open: "0:00 AM", close: "0:00 AM" },
    { day: "Sunday", open: "0:00 AM", close: "0:00 AM" },
  ],
  exceptions: [],
};

const {
  loading: EI_LOCATION_DATA_LOADING,
  success: EI_LOCATION_DATA_SUCCESS,
  failure: EI_LOCATION_DATA_FAILURE,
  reset: EI_LOCATION_DATA_RESET,
} = makeLoadingActionTypes(EI_LOCATION_DATA);

const {
  loading: EI_REQUEST_UPDATE_LOCATION_DATA_LOADING,
  success: EI_REQUEST_UPDATE_LOCATION_DATA_SUCCESS,
  failure: EI_REQUEST_UPDATE_LOCATION_DATA_FAILURE,
  reset: EI_REQUEST_UPDATE_LOCATION_DATA_RESET,
} = makeLoadingActionTypes(EI_REQUEST_UPDATE_LOCATION_DATA);

const eiLocationData = (state = defaultState, action) => {
  switch (action.type) {
    case EI_LOCATION_DATA_LOADING:
      return { ...state, isLoading: true, isUpdating: false, error: null };
    case EI_REQUEST_UPDATE_LOCATION_DATA_LOADING:
      return { ...state, isLoading: false, isUpdating: true, error: null };
    case EI_LOCATION_DATA_SUCCESS:
      console.log("called EI_LOCATION_DATA! payload: ", action.payload);
      return {
        ...state,
        payload: action.payload,
        isLoading: false,
        isUpdating: false,
        error: null,
      };
    case EI_REQUEST_UPDATE_LOCATION_DATA_SUCCESS:
      console.log(
        "called EI_REQUEST_UPDATE_LOCATION_DATA_SUCCESS! payload: ",
        action.payload
      );
      return {
        ...state,
        exceptions: state.payload.exceptions,
        schedule: action.payload,
        isLoading: false,
        isUpdating: false,
        updatingSuccess: true,
        error: null,
      };
    case EI_LOCATION_DATA_FAILURE || EI_REQUEST_UPDATE_LOCATION_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        updatingSuccess: false,
        isUpdating: false,
        error: action.error,
      };
    case EI_LOCATION_DATA_RESET:
      return { defaultState, isLoading: false, isUpdating: false, error: null };
    case EI_REQUEST_UPDATE_LOCATION_DATA_RESET:
      return { ...state, isUpdating: false, error: null };
    default:
      return state;
  }
};

export default eiLocationData;
