import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import { REQUEST_ADD_TOWEL_SERVICE, SEND_REQUEST } from "../../sagas/actions";
import TowelButtonStatusIconManager from "./TowelButtonStatusIconManager";
import MessageBox from "./MessageBox";
// import { Button } from "react-bootstrap";
import addTowelServiceApiCall from "../../modules/api/addTowelServiceApiCall";

export class AddTowelServiceLightView extends Component {
  render() {
    return (
      <div className="AddTowelServiceLightViewWrapper">
        <div className="CardBodyText">
          <section>Forgot towels? Don't sweat it! </section>
          <br />
          <section>Want to add towel service to your appointment?</section>
          <h3 className="CardBodyText">A $2 charge will be added to your account.</h3>
        </div>
        <div className="ButtonWrapper">
          <button
            className={
              !this.props.reduxState.uvbLightMessageBox.buttonDisabled ? "access-button" : "btn btn-primary-outline"
            }
            type="button"
            size="medium"
            style={{ zIndex: 0, minWidth: "150px", minHeight: "50px" }}
            disabled={this.props.reduxState.towelServiceMessageBox.buttonDisabled}
            id="addTowelServiceButton"
            onClick={() => {
              this.props.addTowelService(this.props.reduxState.access.data.accessKey);
            }}>
            ADD TOWELS
          </button>
          <TowelButtonStatusIconManager className="ButtonStatusIcon" id="addTowelServiceIconStaus" />
        </div>

        {this.props.reduxState.towelServiceMessageBox.message ? (
          <MessageBox message={this.props.reduxState.towelServiceMessageBox.message} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addTowelService: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_ADD_TOWEL_SERVICE,
        apiCall: addTowelServiceApiCall,
        apiCallParams: { accessKey: accessKey },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTowelServiceLightView);
