import React, { Component } from "react";
import "./Benefits.css";
import Scroll from "react-scroll";
import { SWEATWORKS_URL } from "../../TestingVariables";
// import BenefitsIntroPara from "./BenefitsIntroPara";
const Element = Scroll.Element;
const { BenefitsPageContent } = require("./BenefitsPageContent");

export class FullBenefitImagesList extends Component {
  state = {};
  // createSubContent = (index) => {
  //   return BenefitsPageContent[index].subcontent.map((subcontent, i) => {
  //     return (
  //       <div className="FullBenefitWrapper" key={i}>
  //         <div className="FullBenefitSubtitle TitleFont L">{subcontent.subtitle}</div>
  //         <div className="BenefitContentWrapper  M">{subcontent.content}</div>
  //       </div>
  //     );
  //   });
  // };

  createFullBenfitList = () => {
    return BenefitsPageContent.map((benefit, i) => {
      return (
        <Element
          key={i}
          name={`${benefit.url}`}
          className="FullBenefitWrapper"
          id={`${benefit.url}`}
        >
          <div
            className="benefit-bg-image"
            style={{ backgroundImage: `url(${benefit.picture})` }}
          >
            <div className="BenefitContentWrapper ColorWhite ItalicContentFont">
              <div className="benefit-content-group">
                <div className="FullBenefitIconWrapper">
                  {/* <img
                    className="BenefitIcon"
                    src={benefit.icon}
                    alt={benefit.title}
                  /> */}
                  <div className="full-benefit-title TitleFont ColorWhite">
                    {benefit.title}
                  </div>
                </div>
                <div className="benefit-modal-content full-benefit-content">{benefit.content}</div>
                <a
                  className="benefit-blog-anchor"
                  href={`${SWEATWORKS_URL}/blog`}
                >
                  <div className="full-benefit-footnote sw-color-primary sw-color-primary-hover">
                    <div className="full-benefit-footnote-text TitleFont">
                      Read more in our blogs!
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Element>
      );
    });
  };

  render() {
    return (
      <div className="FullBenefitsGroup">{this.createFullBenfitList()}</div>
    );
  }
}

export default FullBenefitImagesList;
