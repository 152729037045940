import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import "./AccessNotWorkingModal.css";
import { GrFormClose } from "react-icons/gr";
import { PiCopyLight } from "react-icons/pi";
import { CopyToClipboard } from "react-copy-to-clipboard";

export class AccessNotWorkingModal extends Component {
  state = {
    copied: false
  }
  modalClassName = (status) => {
    if (status) {
      return "open";
    } else {
      return "closed";
    }
  };
  render() {
    return (
      <div className={`accessModalWrapper accessModalWrapper-${this.modalClassName(this.props.modalStatus)}`}>
        <div className="accessNotWorkingModalTitle">
          <div
            className="accessModalCloseIcon"
            onClick={() => {
              this.props.setAccessModal(false);
            }}
          >
            <GrFormClose color="white" />
          </div>
        </div>
        <div className="notWorkingModalContent">
          <div>If you cannot gain access,</div>
          <div>you can text this code</div>
          <div className="accessCode">
            {this.props.reduxState.access.data.accessKey}
            <CopyToClipboard
              text={this.props.reduxState.access.data.accessKey}
              onCopy={() => this.setState({ copied: true })}
            >
              <div className="accessCodeCopyWrapper">
                <PiCopyLight className="accessCodeCopyButton" />
              </div>
            </CopyToClipboard>
          </div>
          <div className="accessNotWorkingMessage">{this.state.copied === true ? "Copied Access Code!" : null}</div>
          <div className="accessApiNumberWrapper" type="tel">
            <div>to</div>
            <a href="tel:6124456278" className="accessApiNumber">
              612-445-6278
            </a>
          </div>
          <div>
            as an alternate method
            <br /> to unlock the door.
          </div>
          <div className="accessModalOutro">
            This will not work if access is expired, or if it has not started yet.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(AccessNotWorkingModal);
