import React, { Component } from "react";
import "../../Benefits/DesktopBenefits.css";
import NavBar from "../../Nav/NavBar";
import Footer from "../../Footer/Footer";
import { connect } from "react-redux";
import MobileNav from "../../Nav/MobileNav";
import "./PageTemplate.css";

export class SWPageTemplate extends Component {
  state = {
    scrollTop: 0,
    windowWidth: 500,
  };
  componentDidMount() {
    document
      .getElementById("root")
      .addEventListener("scroll", this.listenToScroll);
    window.addEventListener("resize", this.handleWidthChange);
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("scroll", this.listenToScroll);
    window.removeEventListener("resize", this.handleWidthChange);
  }

  listenToScroll = () => {
    const scrollTop = document.getElementById("root").scrollTop;
    this.setState({
      scrollTop: scrollTop,
    });
  };

  handleWidthChange = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    return (
      <div className="SWPageTemplate">
        {this.state.windowWidth > 700 ? (
          <>
            <NavBar type="fixed" color={"transparent"} />
            <NavBar
              type="dropdown"
              active={this.state.scrollTop > 60 ? true : false}
              color={"transparent"}
            />
          </>
        ) : (
          <>
            {/* <MobileNav type="fixed" color={"transparent"} /> */}
            <MobileNav
              type="dropdown"
              active={this.state.scrollTop > 60 ? true : false}
              color={this.state.scrollTop > 60 ? "white" : "transparent"}
            />
          </>
        )}
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(SWPageTemplate);
