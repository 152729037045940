import axios from "axios";
import { put } from "redux-saga/effects";
import {
  SET_EI_AUTH_TOKEN,
  EI_LOGIN_MESSAGE_BOX_RESET,
  EI_SIGNUP_MESSAGE_BOX_RESET,
  EI_SET_VIEW_LOCATIONS,
  EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR,
  EI_LOGIN_MESSAGE_BOX_INVALID,
} from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEIAuthToken(apiCallParams) {
  console.log("in requestEIAuthToken params: ", apiCallParams);
  yield put({ type: EI_LOGIN_MESSAGE_BOX_RESET });
  yield put({ type: EI_SIGNUP_MESSAGE_BOX_RESET });
  const data = {
    username: apiCallParams.username,
    password: apiCallParams.password,
  };
  const headers = {
    "content-type": "application/json",
    timeout: 2000,
  };
  const result = yield axios
  .post(`${SWEATWORKS_API}/ei/login`, data, headers)
  .then((res) => {
    console.log("res", res);
    return { data: res.data, status: res.status };
  })
  .catch((error) => {
    console.log("requestEIAuthToken error: ", error);
    return { error: error };
  });
  if (result.status === 200) {
    const { token, expires } = result.data;
    localStorage.setItem("token", token);
    localStorage.setItem("expires", expires);
    apiCallParams.callBack();
    yield put({ type: SET_EI_AUTH_TOKEN, payload: result.data });
    // yield put({ type: SAVE_EI_AUTH_TOKEN_COOKIE }); //TODO
    yield put({ type: EI_LOGIN_MESSAGE_BOX_RESET });
    yield put({ type: EI_SIGNUP_MESSAGE_BOX_RESET });
    yield put({ type: EI_SET_VIEW_LOCATIONS });
    return true;
  }
  if (result.status === 240 || result?.data?.error === "Internal Server Error") {
    yield put({ type: EI_LOGIN_MESSAGE_BOX_INTERNAL_ERROR });
    return false;
  } else if (
    result.status === 250 ||
    result?.data?.error === "Credentials Are Invalid" || result?.error
  ) {
    yield put({ type: EI_LOGIN_MESSAGE_BOX_INVALID });
    return false;
  } else {
    console.log("requestEIAuthToken: UNKOWN ERROR!");
  }
}

export default requestEIAuthToken;
