import React, { Component } from "react";
import "./Benefits.css";
import Modal from "react-modal";
import "./Modal.css";
import { RiCloseFill } from "react-icons/ri";

export class BenefitsModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.modal}
        onRequestClose={() => {
          this.props.hide();
        }}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={500}
      >
        <div className="benefits-modal  ColorWhite">
          <div className="benefit-modal-title-group TitleFont">
            <div
              className="benefit-modal-title"
              style={{ color: `${this.props.color}` }}
            >
              {this.props.title}
            </div>
            <RiCloseFill
              className="modal-close-icon"
              color="white"
              onClick={() => {
                this.props.hide();
              }}
            />
            {/* </div> */}
          </div>
          <div className="benefit-modal-content-wrapper">
            <div className="benefit-modal-content">{this.props.content}</div>
            <div className="learn-more-button-group">
              {this.props.hrefReadMore ? (
                <a
                  className="learn-more-button-wrapper learn-more-button TitleFont sw-color-primary sw-color-primary-hover"
                  href={this.props.hrefReadMore}
                >
                  Discover More!
                </a>
              ) : null}
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modal>
    );
  }
}

export default BenefitsModal;
