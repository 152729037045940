import { connect } from "react-redux";
import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import mapStyles from "./MapStyles";
import "./HomeLocationWidget.css";
import "./Marker.css";
import "./LocationsPage.css";
import Marker from "./Marker";
import { RESET_COOR, SET_COOR } from "../../sagas/actions";

class LocationsMap extends Component {
  state = {
    center: null,
    locationId: null,
    defaultCenter: { lat: 44.9346103614, lng: -93.1773953441 },
  };
  componentDidMount() {
    const url = window.location.pathname;
    if (url !== "/locations") {
      const id = url.replace("/locations/", "");
      if (parseInt(id)) {
        this.setState({
          locationId: parseInt(id),
        });
        console.log(
          "this.props?.locations[id - 1]: ",
          this.props?.locations[id - 1]
        );
        if (this.props?.locations[id - 1]) {
          const location = this.props?.locations[id - 1];
          this.handleClick(location.lat, location.lng);
        }
      }
    }
  }

  mapMarkers = (locations) => {
    return locations.map((location, i) => {
      return (
        <Marker
          key={i}
          className="MapMarker"
          handleClick={() => {
            this.handleClick(location.lat, location.lng);
          }}
          lat={location.lat}
          lng={location.lng}
        />
      );
    });
  };

  handleClick = async (lat, lng) => {
    console.log("CLICK HANDLED");
    await this.props.resetCoor();
    await this.props.setCoor({ lat: lat, lng: lng });
  };
  render() {
    return (
      <div id="LocationMapWidget" style={this.props.dimensions}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBFhZtq6KpukyULm_u77oJcPfsMV0PYhKo" }}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          center={this.props.reduxState.mapCoor}
          defaultCenter={this.state.defaultCenter}
          options={{ styles: mapStyles, fullscreenControl: false }}
          className={"LocationMapWidget"}
        >
          {this.mapMarkers(this.props.locations)}
        </GoogleMapReact>
        {/* <Marker/> */}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetCoor: () => {
      dispatch({
        type: RESET_COOR,
      });
    },
    setCoor: (payload) => {
      dispatch({
        type: SET_COOR,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsMap);

// import React, { Component } from "react";
// import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";
// // import Marker from "./Marker";

// const GoogleMapReact = () => {
//   return (
//     <GoogleMap
//       disableDefaultUI={true}
//       defaultZoom={10}
//       defaultCenter={{ lat: 44.9778, lng: -93.265 }}
//     />
//   );
// };
// console.log("API KEY: ", process.env.REACT_APP_MAPS);

// const WrappedMap = withScriptjs(withGoogleMap(GoogleMapReact));

// class LocationsMap extends Component {
//   render() {
//     return (
//       // Important! Always set the container height explicitly
//       <div style={{ height: "400px", width: "400px" }}>
//         <WrappedMap
//           googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry.drawing.places&key=AIzaSyBFhZtq6KpukyULm_u77oJcPfsMV0PYhKo`}
//           style={{ height: "400px", width: "400px" }}
//           loadingElement={<div style={{ height: "100%" }} />}
//           containerElement={<div style={{ height: "100%" }} />}
//           mapElement={<div style={{ height: "100%" }} />}
//           // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
//           // defaultCenter={this.state.defaultCenter}
//           // center={this.state.center}
//           // defaultZoom={this.state.zoom}>
//         >
//           {/* {this.renderMarkers()} */}
//         </WrappedMap>
//       </div>
//     );
//   }
// }
// export default LocationsMap;
