import React, { useState, useEffect, useRef } from "react";
import "./google.css";

export const LocationAutocomplete = ({handleInputClick, searchQuery, setSearchQuery}) => {
  const autoCompleteRef = useRef(null);
  const [loadOnce, setLoadOnce] = useState(false);
  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["(cities)"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const forattedAddress = addressObject.formatted_address;
    updateQuery(forattedAddress);
    console.log(addressObject);
  }
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBFhZtq6KpukyULm_u77oJcPfsMV0PYhKo&libraries=places`,
      () => handleScriptLoad(setSearchQuery, autoCompleteRef)
    );
    // eslint-disable-next-line
  }, []);
  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        onClick={() => {
          if (!loadOnce) {
            handleInputClick();
            setLoadOnce(true);
          }
        }}
        onChange={(event) => setSearchQuery(event.target.value)}
        placeholder="Enter a City"
        value={searchQuery}
      />
    </div>
  );
};

export default LocationAutocomplete;
