import {
  UVB_LIGHT_MESSAGE_BOX_RESET,
  UVB_LIGHT_MESSAGE_BOX_EXPIRED,
  UVB_LIGHT_MESSAGE_BOX_INTERNAL_ERROR,
  UVB_LIGHT_MESSAGE_BOX_INVALID,
  UVB_LIGHT_MESSAGE_BOX_DISABLED,
} from "../../sagas/actions";

const initAccessState = {
  message: null,
  buttonDisabled: false,
};

const uvbLightMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case UVB_LIGHT_MESSAGE_BOX_RESET:
      return {
        message: null,
        buttonDisabled: false,
      };
    case UVB_LIGHT_MESSAGE_BOX_EXPIRED:
      return {
        message: `This pass has expired!`,
        buttonDisabled: true,
      };
    case UVB_LIGHT_MESSAGE_BOX_INTERNAL_ERROR:
      return {
        message: `Oh no! An Internal Error occured, unfortunately we were unable to process this request.`,
        buttonDisabled: true,
      };
    case UVB_LIGHT_MESSAGE_BOX_INVALID:
      return {
        message: `Sorry! This pass Is Invalid!`,
        buttonDisabled: true,
      };
      case UVB_LIGHT_MESSAGE_BOX_DISABLED:
      return {
        ...state,
        buttonDisabled: true,
      };
    default:
      return state;
  }
};

export default uvbLightMessageBox;
