const {
  LOADING,
  SUCCESS,
  FAILURE,
  REQUEST_ADD_TOWEL_SERVICE_LOADING,
  REQUEST_ADD_TOWEL_SERVICE_SUCCESS,
  REQUEST_ADD_TOWEL_SERVICE_FAILURE,
  REQUEST_ADD_TOWEL_SERVICE_RESET,
} = require("../../sagas/actions");

const initialState = {
    status: null
};

const towelServiceIconStatus = (state = initialState, action) => {
  switch (action.type) {
  case REQUEST_ADD_TOWEL_SERVICE_LOADING: {
      return {
        status: LOADING,
      };
    }
    case REQUEST_ADD_TOWEL_SERVICE_SUCCESS:
      return {
        status: SUCCESS,
      };
    case REQUEST_ADD_TOWEL_SERVICE_FAILURE:
      return {
        status: FAILURE,
      };
    case REQUEST_ADD_TOWEL_SERVICE_RESET:
      return {
        status: null,
      };
    default:
      return state;
  }
};

export default towelServiceIconStatus;
