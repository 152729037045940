import React, { Component } from "react";
import MindBodyComponent from "../mindbody/MindBodyComponent";
import NeWellnessAppointmentWidget from "./NeWellnessAppointmentWidget.js";
import { connect } from "react-redux";
import SweatWorksBackgroundImage from "../../images/SweatworksStudiosHeaderImage.png";
import NE_Wellness_logo from "../../images/NE_Wellness_logo.jpg";

class MindbodyChoiceSelection extends Component {
  state = {
    site: null,
  };
  renderMindbodySiteSelection = (site) => {
    if (site === "awaken-for-wellness") {
      return (
        <div>
          <MindBodyComponent
            dataWidgetVersion="0"
            dataVersion="0.2"
            dataLinkClass="healcode-contract-text-link"
            dataServiceId="133"
            dataSiteId="26503"
            dataMbSiteId="43256"
            dataBwIdentitySite="false"
            dataInnerHtml="Buy Now"
            dataType="contract-link"
            dataWidgetPartner="object"
            dataWidgetId="a689925fdcf"
          />
          <healcode-widget //for some reason this needs to be here otherwise widget doesn't load
            data-type="appointments"
            data-widget-id="a689925fdcf"
          />
        </div>
      );
    } else if (site === "ne-wellness") {
      return <NeWellnessAppointmentWidget />;
    }
  };

  render() {
    return (
      <div>
        {!this.state.site && (
          <>
            <div className="mindbody-selection-prompt">Please select a site to proceed to booking</div>
            <div className="MindbodyChoiceSelection">
              <div
                className="site-group"
                onClick={() => {
                  this.setState({
                    site: "awaken-for-wellness",
                  });
                }}
              >
                <img
                  className="mindbody-choice-selection-img"
                  alt="SweatWorks Studio logo"
                  src={SweatWorksBackgroundImage}
                />
                <div className="mindbody-choice-selection-locations-group">
                  <div className="mindbody-choice-selection-locations">Locations:</div>
                  <div className="mindbody-choice-selection-location">Awaken For Wellness - St Paul</div>
                  <div className="mindbody-choice-selection-location">Awaken For Wellness - Hudson</div>
                  <div className="mindbody-choice-selection-location">The Salt Room - Woodbury</div>
                </div>
              </div>
              <div
                className="site-group"
                onClick={() => {
                  this.setState({
                    site: "ne-wellness",
                  });
                }}
              >
                <img className="mindbody-choice-selection-img" alt="NE Wellness logo" src={NE_Wellness_logo} />
                <div className="mindbody-choice-selection-locations-group">
                  <div className="mindbody-choice-selection-locations">Locations:</div>
                  <div className="mindbody-choice-selection-location">NE Wellness - Minneapolis</div>
                  <div className="mindbody-choice-selection-location">NE Wellness - Bismarck</div>
                </div>
              </div>
            </div>
            {/* <div className="mindbody-rememeber-selection-group">
              <div className="mindbody-rememeber-selection-text">Remember selection for next time</div>
              <div className="mindbody-rememeber-selection-checkbox">Remember selection for next time</div>
            </div> */}
          </>
        )}
        {this.state.site && this.renderMindbodySiteSelection(this.state.site)}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(MindbodyChoiceSelection);
