import axios from "axios";
import { put } from "redux-saga/effects";
import {
  ACCESS_MESSAGE_BOX_EXPIRED,
  ACCESS_MESSAGE_BOX_FAILED,
  ACCESS_MESSAGE_BOX_INVALID,
  ACCESS_MESSAGE_BOX_NOT_YET,
  ACCESS_MESSAGE_BOX_RESET,
} from "../../sagas/actions";
import { SWEATWORKS_API } from "../../TestingVariables";

// const accessDataApiCall = async({accessKey}) => {
function* accessUnlockApiCall(apiCallParams) {
  const accessKey = apiCallParams.accessKey;
  const entry = apiCallParams.entry;
  const data = {
    headers: {
      entry: entry,
    },
  };
  const result = yield axios
    .get(`${SWEATWORKS_API}/access/unlock/${accessKey}`, data)
    .then(async (res) => {
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("accessUnlockApiCall error: ", error);
      return false;
    });
  if (result.status === 204) {
    yield put({ type: ACCESS_MESSAGE_BOX_RESET });
    navigator.vibrate([30]);
    return true;
  } else if (result.status === 210 || result.status === 215) {
    navigator.vibrate([100, 30, 100, 30]);
    if (result.data.error === "Not Started Yet") {
      yield put({ type: ACCESS_MESSAGE_BOX_NOT_YET });
      return false;
    } else if (result.data.error === "Access Has Expired") {
      yield put({ type: ACCESS_MESSAGE_BOX_EXPIRED });
      return false;
    } else if (result.data.error === "Credential Invalid") {
      yield put({ type: ACCESS_MESSAGE_BOX_INVALID });
      return false;
    }
  } else {
    yield put({ type: ACCESS_MESSAGE_BOX_FAILED });
    return false;
  }
}

export default accessUnlockApiCall;
