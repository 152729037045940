import axios from "axios";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEIUnlockEntry(apiCallParams) {
  const token = apiCallParams.token;
  const locationId = apiCallParams.locationId;
  const siteId = apiCallParams.siteId;
  const entry = apiCallParams.entry;
  console.log('requestEIUnlockEntry token: ', token);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "SiteId": siteId,
    "LocationId": locationId,
    "Entry": entry
  };
  console.log('headers: ', headers);
  return yield axios
    .post(`${SWEATWORKS_API}/ei/access/entry/unlock`, {}, {
      headers: headers,
    })
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log('requestEIUnlockEntry error: ', err);
    }
    );
}

export default requestEIUnlockEntry;