import React, { Component } from "react";
import "./AccessContent.css";
import { IconContext } from "react-icons";
// import { BsX } from "react-icons/bs";

export class FailedIcon extends Component {
  render() {
    return (
      <div className="StatusIconWrapper">
        <IconContext.Provider value={{ color: "#DDDD00", size: "50px" }}>
          {/* <BsX className="StatusIcon" /> */}
        </IconContext.Provider>
      </div>
    );
  }
}

export default FailedIcon;
