import React, { useEffect, useState } from "react";
// import { Container } from "@material-ui/core";
import loadMBody from "./loadMBody";

const MindBodyComponent = (props) => {
  const [mindBody, setMindBody] = useState(false);

  useEffect(() => {
    loadMBody(() => {
      setMindBody(true);
    });
  }, []);

  return (
    <div style={{ visibility: "hidden", height: "0px", width: "0px" }}>
      {mindBody && (
        <healcode-widget
          data-widget-version={props.dataWidgetVersion}
          data-version={props.dataVersion}
          data-link-class={props.dataLinkClass}
          data-service-id={props.dataServiceId}
          data-site-id={props.dataSiteId}
          data-mb-site-id={props.dataMbSiteId}
          data-bw-identity-site={props.dataBwIdentity}
          data-inner-html={props.dataInnerHtml}
          data-type={props.dataType}
          data-widget-partner={props.dataWidgetPartner}
          data-widget-id={props.dataWidgetId}
        />
      )}
    </div>
  );
};

MindBodyComponent.propTypes = {};

export default MindBodyComponent;