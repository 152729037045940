import { SET_POSITION } from "../../sagas/actions";

const initialState = false;

const navBarPostition = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITION:
      return action.payload;
    default:
      return state;
  }
};

export default navBarPostition;
