import React, { Component } from "react";
import "./ExperiencePage.css";
import Icon1 from "../../images/assets/Sweatworks_Drop_Teal_1.png";
import Icon2 from "../../images/assets/Sweatworks_Drop_Teal_2.png";
import Icon3 from "../../images/assets/Sweatworks_Drop_Teal_3.png";
import Icon4 from "../../images/assets/Sweatworks_Drop_Teal_4.png";
class SweatIconNum extends Component {
  getIcon = () => {
    const num = this.props.num;
    switch (num) {
      case 1:
        return Icon1;
      case 2:
        return Icon2;
      case 3:
        return Icon3;
      case 4:
        return Icon4;
      default:
        return Icon1;
    }
  };

  render() {
    const icon = this.getIcon();
    return (
      <div className="SweatIconNum">
        <img className="SweatDropNum" src={icon} alt={`Sweat Drop Number ${this.props.numm}`} />
      </div>
    );
  }
}
export default SweatIconNum;
