import React from "react";
import Facebook from "../../images/assets/facebook_icon.png";
import Instagram from "../../images/assets/instagram_icon.png";
import Twitter from "../../images/assets/twitter_icon.png";
import { SWEATWORKS_URL } from "../../TestingVariables";
import SweatworksLogo from "../../images/sweatworks_logo_transparent_bg.png";

import "./FooterStyles.css";

const Footer = () => {
  return (
    <div className="FooterWrapper BackgroundColorCharcoal">
      <div className="Footer-top-margin" />
      <div className="Footer">
        <div className="sw-footer-col-group">
          <div className="sw-footer-col">
            <a href={`${SWEATWORKS_URL}/home`}>
              <img
                className="sweatworks-logo-footer"
                src={SweatworksLogo}
                alt="Sweatworks Logo Home Button"
              />
            </a>
          </div>
          <div className="sw-footer-col">
            <div className="sw-footer-col-center">
              <a
                className="FooterLink ItalicContentFont ColorWhite"
                href="https://www.facebook.com/awakenforwellness"
              >
                <img
                  className="FooterIcon ColorWhite"
                  src={Facebook}
                  alt="Facebook Icon"
                />
              </a>
              <a
                className="FooterLink ItalicContentFont ColorWhite"
                href="https://www.instagram.com/Awakenforwellness/"
              >
                <img
                  className="FooterIcon ColorWhite"
                  src={Instagram}
                  alt="Instagram Icon"
                />
              </a>
              <a
                className="FooterLink ItalicContentFont ColorWhite"
                href="https://twitter.com/awaken4wellness"
              >
                <img
                  className="FooterIcon ColorWhite"
                  src={Twitter}
                  alt="Twitter Icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="sw-footer-col-group">
          <div className="sw-footer-col">
            <div className="LinkWrapper">
              <a
                className="FooterLink ItalicContentFont M ColorWhite"
                href="/locations"
              >
                Find a Location
              </a>
            </div>
            <div className="LinkWrapper">
              <a
                className="FooterLink ItalicContentFont M ColorWhite"
                href="/experience"
              >
                Experience
              </a>
            </div>
            <div className="LinkWrapper">
              <a
                className="FooterLink ItalicContentFont M ColorWhite"
                href="/pricing"
              >
                Pricing
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-bottom-margin" />
    </div>
  );
};
export default Footer;
