import React, { Component } from "react";
import "./AccessContent.css";
import "./AccessButton.css";
import { connect } from "react-redux";
import AccessButtonStatusIconManager from "./AccessButtonStatusIconManager";
import { REQUEST_ENTRY_UNLOCK, SEND_REQUEST } from "../../sagas/actions";
import accessUnlockApiCall from "../../modules/api/accessUnlockApiCall";
import { BiLockAlt } from "react-icons/bi";
import moment from "moment-timezone";

// import { Button } from "@material-ui/core";

// import { AwesomeButton } from "react-awesome-button";

export class ButtonsWrapper extends Component {
  state = {
    early: null,
    late: null,
    disabled: true,
    earlyTowel: null,
    lateTowel: null,
    disabledTowel: true,
  };
  checkIfDuringAccess = () => {
    const startTime = this.props.reduxState.access.data.startDateTime;
    const endTime = this.props.reduxState.access.data.endDateTime;
    const now = moment();
    if (moment(startTime).isBefore(now) && !moment(endTime).isAfter(now)) {
      this.setState({
        early: false,
        late: true,
        disabled: true,
      });
    } else if (!moment(startTime).isBefore(now) && moment(endTime).isAfter(now)) {
      this.setState({
        early: true,
        late: false,
        disabled: true,
      });
    } else if (moment(startTime).isBefore(now) && moment(endTime).isAfter(now)) {
      this.setState({
        early: false,
        late: false,
        disabled: false,
      });
    } else {
      this.setState({
        disabled: true,
      });
    }
  };

  checkIfDuringAppointment = () => {
    const accessStartTime = this.props.reduxState.access.data.startDateTime;
    const startTime = moment(accessStartTime);
    const endTime = moment(startTime).add(65, "minutes");
    const now = moment();
    if (moment(startTime).isBefore(now) && !moment(endTime).isAfter(now)) {
      this.setState({
        earlyTowel: false,
        lateTowel: true,
        disabledTowel: true,
      });
    } else if (!moment(startTime).isBefore(now) && moment(endTime).isAfter(now)) {
      this.setState({
        earlyTowel: true,
        lateTowel: false,
        disabledTowel: true,
      });
    } else if (moment(startTime).isBefore(now) && moment(endTime).isAfter(now)) {
      this.setState({
        earlyTowel: false,
        lateTowel: false,
        disabledTowel: false,
      });
    } else {
      this.setState({
        disabled: true,
      });
    }
  };

  componentDidMount() {
    this.checkIfDuringAccess();
    this.checkIfDuringAppointment();
  }

  message = () => {
    if (this.state.earlyTowel) {
      return <div className="MessageBox">Please refresh this page after access has started.</div>;
    } else if (this.state.lateTowel) {
      return <div className="MessageBox">Access has expired!</div>;
    } else if (this.state.early) {
      return <div className="MessageBox">Please refresh this page after access has started.</div>;
    } else if (this.state.late) {
      return <div className="MessageBox">Access has expired!</div>;
    }
  };

  render() {
    return (
      <>
        <div className="ButtonsWrapper">
          {this.props.reduxState.access.data.entries.map((entry) => (
            <div key={entry.tag} className="ButtonWrapper">
              <button
                className={
                  !this.props.reduxState.accessMessageBox.buttonsDisabled && !this.state.disabled
                    ? "access-button"
                    : "btn btn-primary-outline"
                }
                type="button"
                size="medium"
                // className="AccessButtons"
                style={{ zIndex: 0, minWidth: "150px", minHeight: "50px" }}
                disabled={this.props.reduxState.accessMessageBox.buttonsDisabled || this.state.disabled}
                id={entry.tag}
                onClick={() => {
                  this.props.requestEntryUnlock(this.props.reduxState.access.data.accessKey, entry.tag);
                }}
              >
                <BiLockAlt
                  style={{
                    height: "1.5em",
                    width: "1.5em",
                    marginRight: "10%",
                  }}
                />
                {entry.name}
              </button>
              <AccessButtonStatusIconManager className="ButtonStatusIcon" id={entry.tag} />
            </div>
          ))}
        </div>
        {this.message()}
      </>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestEntryUnlock: (accessKey, entry) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_ENTRY_UNLOCK,
        apiCall: accessUnlockApiCall,
        apiCallParams: { accessKey: accessKey, entry: entry },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsWrapper);
