import React, { useState } from "react";
import "./EmployeeInterfaceLogin.css";
import { connect } from "react-redux";
import { SEND_REQUEST, EI_SIGNUP } from "../../../sagas/actions";
import requestEISignup from "../../../modules/api/ei/requestEISignup";
import Roller from "../../General/animations/Roller";

function EISignUpView(props) {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordConfirm, setLoginPasswordConfirm] = useState("");
  const [message, setMessage] = useState("Password must be more than 8 characters, atleast 1 upper case, 1 lower, and 1 number.");

  const [invite, setInvite] = useState("");
  const validateData = (password, cPassword, username, invite) => {
    if (password !== cPassword) {
      setMessage('Passwords Do Not Match!')
      return false
    }
    let checkLower = /(?=.*[a-z])/;
    let checkUpper = /(?=.*[A-Z])/;
    let checkNumber = /(?=.*[0-9])/;
    let checkLength = /(?=.{8,})/;
    let checkUsernameLength = /(?=.{4,})/;
    console.log("password: ", password);
    const lower = password.match(checkLower) !== null;
    const upper = password.match(checkUpper) !== null;
    const number = password.match(checkNumber) !== null;
    const length = password.match(checkLength) !== null;
    const lengthUsername = username.match(checkUsernameLength) !== null;
    console.log("lower, upper, number, length: ", lower, upper, number, length);
    if (!lengthUsername) {
      setMessage('Username is Too short. Must be atleast 4 charaters long.')
      return false
    }
    if (!length) {
      setMessage('Password too short! must be atleast 8 charaters long.')
      return false
    }
    if (!number) {
      setMessage('Password must contain a number')
      return false
    }
    if (!lower) {
      setMessage('Password must have a lowercase.')
      return false
    }
    if (!upper) {
      setMessage('Password must have an uppercase.')
      return false
    }
    if(invite === '') {
      setMessage('No Invite Entered!')
      return false
    }
    setMessage('')
    return true
  };

  return (
    <form className="admin-login-container" autoComplete="off">
      <div className="admin-login-title">EMPLOYEE SIGNUP</div>
      <div className="login-input-wrapper">
        <div className="login-input-row">
          <input
            placeholder="Username"
            type="text"
            autoComplete="off"
            value={loginUsername}
            onChange={(e) => {
              setLoginUsername(e.target.value);
            }}
          ></input>
        </div>
        <div className="login-input-row">
          <input
            placeholder="Password"
            className="ei-sensitive-input"
            autoComplete="off"
            value={loginPassword}
            type="password"
            onChange={(e) => {
              setLoginPassword(e.target.value);
            }}
          ></input>
        </div>
        <div className="login-input-row">
          <input
            placeholder="Confirm Password"
            className="ei-sensitive-input"
            autoComplete="off"
            value={loginPasswordConfirm}
            type="password"
            onChange={(e) => {
              setLoginPasswordConfirm(e.target.value);
            }}
          ></input>
        </div>
        <div className="login-input-row">
          <input
            placeholder="Invite Code"
            className="ei-sensitive-input"
            autoComplete="new-password"
            value={invite}
            type="password"
            onChange={(e) => {
              setInvite(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className="login-input-row">{message}</div>
      <div className="ei-signup-response-bar">
        <Roller />
        {props.message}
      </div>
      <div className="ei-login-button-row">
        <button
          className="ei-login-button glow-on-hover"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            props.set("loginView", "login");
          }}
        >
          SIGN IN
        </button>
        <button
          className="ei-login-button glow-on-hover"
          onClick={(e) => {
            e.preventDefault();
            const reqValid = validateData(loginPassword, loginPasswordConfirm, loginUsername, invite);
            if (reqValid) {
              props.requestSignup(loginUsername, loginPassword, invite);
            }
          }}
        >
          SUBMIT
        </button>
      </div>
    </form>
  );
}

const mapStateToProps = (reduxState) => ({
  message: reduxState?.signupMessageBox?.message,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestSignup: (loginUsername, loginPassword, invite) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_SIGNUP,
        apiCall: requestEISignup,
        apiCallParams: {
          username: loginUsername,
          password: loginPassword,
          invite: invite,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EISignUpView);
