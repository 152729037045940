import React from "react";
import "./DropDownMenu.css";

const DropDownMenu = (props) => {
  const options = [
    "12:00 AM - 6:00 AM",
    "6:00 AM - 12:00 PM (Noon)",
    "12:00 PM - 6:00 PM",
    "6:00 PM - 12:00 AM",
  ];
  return (
    <select
      className="drop-down-menu"
      value={props.dayQuarter - 1}
      onChange={(e) => {
        props.set("dayQuarter", Number(e.target.value) + 1);
      }}
    >
      {options.map((option, i) => {
        return (
          <option
            key={i}
            className="drop-down-menu-option"
            value={i}
            // value={props.selected - 1 === i ? true : null }
            // value={options[option -1]}
            // defaultValue={props.selected - 1 === i ? true : null }
            // value={options[option -1]}
            // selected={props.selected - 1 === i ? true : null }
          >
            {option}
          </option>
        );
      })}
    </select>
  );
};

export default DropDownMenu;
