import React from "react";
import "./EmployeeInterfaceRoomView.css";
import RoomViewCommands from "./RoomViewCommands";
import RoomViewHeader from "./RoomViewHeader";
import RoomViewData from "./RoomViewData";
import { connect } from "react-redux";
import RoomViewAppointments from "./RoomViewAppointments";
import { EI_SAUNA_DATA } from "../../../sagas/actions";
import LoadingWrapper from "../../General/LoadingWrapper";

const EmployeeInterfaceRoomView = ({
  siteId,
  staffId,
  roomName,
  saunaType,
  set,
  dataDate,
  dayQuarter,
  currentData,
  automations,
  data,
  appointments,
  credentials,
}) => {
  return (
    <LoadingWrapper
      actionType={EI_SAUNA_DATA}
      stateReader={(reduxState) => {
        const { isLoading, error } = reduxState.eiSaunaData;
        console.log("isLoading, error: ", isLoading, error);
        return { isLoading, error };
      }}
    >
      <div className="ei-room-wrapper">
        <RoomViewHeader
          roomName={roomName}
          set={set}
          saunaType={saunaType}
          siteId={siteId}
          staffId={staffId}
          status={currentData?.status}
          saunaAuto={automations?.sauna}
          redLightAuto={automations?.redLight}
          temp={currentData?.data?.temperature}
          preheatTempGoal={currentData?.data?.preheat_temp_goal}
          maxSaunaTemp={currentData?.data?.max_sauna_temp}
          preheatMode={currentData?.data?.pre_heat_mode}
          redLightStatus={currentData?.data?.red_light}
        />
        <RoomViewData
          set={set}
          siteId={siteId}
          roomName={roomName}
          dataDate={dataDate}
          dayQuarter={dayQuarter}
          staffId={staffId}
          plotData={data}
        />
        <RoomViewCommands siteId={siteId} roomName={roomName} staffId={staffId} />
        <RoomViewAppointments
          roomName={roomName}
          appointments={appointments}
          credentials={credentials}
        />
      </div>
    </LoadingWrapper>
  );
};

const mapStateToProps = (reduxState) => {
  return {
    eiSaunaData: reduxState.eiSaunaData,
    currentData: reduxState.eiSaunaData?.payload?.data?.currentData,
    automations: reduxState.eiSaunaData?.payload?.data?.automations,
    data: reduxState.eiSaunaData?.payload?.data?.data,
    appointments: reduxState.eiSaunaData?.payload?.appointments,
    credentials: reduxState.eiSaunaData?.payload?.credentials,
  };
};

export default connect(mapStateToProps)(EmployeeInterfaceRoomView);