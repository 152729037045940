import SweatIcon from "../../images/assets/custom/SW_1_hot.png";
import HandIcon from "../../images/assets/custom/SW_1_clean_hands.png";
import ImmuneIcon from "../../images/assets/custom/SW_1_medical-protection.png";
import BloodPressureIcon from "../../images/assets/custom/SW_1_blood_pressure.png";
import ScaleIcon from "../../images/assets/custom/SW_1_scale.png";
import HealthIcon from "../../images/assets/custom/SW_1_arthritis.png";

import SweatPic from "../../images/blood_orange_wet_glass.png";
import HandPic from "../../images/woman_smiling_close_up.png";
import ImmunePic from "../../images/sauna_blue_shade.png";
import BloodPressurePic from "../../images/blood_cells_graphic.png";
import ScalePic from "../../images/man_woman_red_light.png";
import HealthPic from "../../images/man_shoulder_pain.png";

const BenefitsPageContent = [
  {
    id: 1,
    title: "Detox",
    url: "/benefits/detox",
    content: `Detoxification and the benefits of infrared saunas
    Infrared saunas can help increase blood circulation and stimulate the sweat glands, releasing built-up toxins in the body.

    Daily sauna sweating can help detoxify the body as it releases heavy metals (lead, mercury, nickel, and cadmium) as well as alcohol, nicotine, sulfuric acid and other organic and inorganic compounds.
    
    It has been known for decades that sweating is a wonderful way to get rid of stored chemicals.1
    
    Infrared saunas are also used at the Hippocrates Health Institute in West Palm Beach, Beth Israel Hospital in New York, and the Acadia Cancer Institute in Germany.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: SweatIcon,
    picture: SweatPic,
  },
  {
    id: 2,
    title: "Skin Purification",
    url: "/benefits/skin_purification",
    content: `The profuse sweating achieved after just a few minutes in a sauna carries off deeply embedded impurities and dead skin cells, leaving the skin glowing and clean. Increased circulation draws the skin’s own natural nutrients to the surface for a natural glow.
     
     Many clients report improved skin tone, color, elasticity and texture. Increased blood circulation has also been shown to help improve acne, eczema, psoriasis, and aid wound healing with reduced scarring.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
         sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
        sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
        sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: HandIcon,
    picture: HandPic,
  },
  {
    id: 3,
    title: "Cell Health",
    url: "/benefits/cell_health",
    content: `The penetrating infrared wavelengths from infrared saunas raises the core body temperature, inducing an artificial fever. A fever is the body’s mechanism to strengthen and accelerate the immune response, as seen in the case of infection.

    This enhances the immune system, and combined with the improved elimination of toxins and wastes via intense sweating, it can increase overall health and resistance to disease.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: ImmuneIcon,
    picture: ImmunePic,
  },
  {
    id: 4,
    title: "Cardiovascular Health",
    url: "/benefits/cardiovascular_health",
    content: `The Department of Family Practice at the University of British Columbia in Vancouver found that infrared sauna therapy can help keep blood pressure within healthy levels, lower cholesterol, reduce chronic pain and be of therapeutic value to patients with chronic congestive heart disease (CHD).

    A study published in the Journal of the Japanese Circulation Society showed that infrared sauna therapy reduced heart arrhythmias and the symptoms of chronic heart failure in patients with cardiovascular disease.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: BloodPressureIcon,
    picture: BloodPressurePic,
  },
  {
    id: 5,
    title: "Weight Management",
    url: "/benefits/weight_management",
    content: `An article in the Journal of the American Medical Association has stated that regular use of an infrared sauna imparts a similar boost to the cardiovascular system as running. While relaxing in the gentle heat, the benefits of infrared sauna include the body producing sweat, pumping blood and burning calories.

    This is called a “passive aerobic workout,” because although the body is receiving all these benefits, it is not being stressed in the same way as a normal workout. The body is more relaxed and is in parasympathetic mode during that time.
    
    As the body increases sweat production to cool itself, the heart works harder to pump blood while boosting circulation. This increase in metabolism is also burning more calories.
    
    A 30-minute infrared sauna session can burn somewhere between 300–800 calories.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
      sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: ScaleIcon,
    picture: ScalePic,
  },
  {
    id: 6,
    title: "Pain Relief",
    url: "/benefits/pain_relief",
    content: `Muscle aches, arthritis and joint pain
      Too much of anything can take its toll, including exercise. The benefits of infrared saunas include helping relieve inflammation, stiffness and soreness by increasing blood circulation and allowing the deep, penetrating infrared heat to relax muscles and carry off metabolic waste products, while delivering oxygen-rich blood to the muscles for a faster recovery.
      
      Infrared helps warm the muscles for greater flexibility and range of motion, while relieving muscle tension and pain.
      
      Studies have also shown that time spent in an infrared sauna can bring relief from different forms of arthritis.3 Radiant heat has been effective in the treatment of sprains, neuralgia, bursitis, muscle spasms, joint stiffness and many other musculoskeletal ailments. Much of the normal stiffness, aches and pains that come with aging can be lessened with regular sauna usage.`,
    subcontent: [
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
        sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
        sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
      {
        subtitle: "Hey this a subtitle, this needs to be changed!",
        content: ` sdfl
        sadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwcsadsdds sdfs  wewe  wqewec wqefwc  asd aaaaa qwefw qwefwc`,
      },
    ],
    icon: HealthIcon,
    picture: HealthPic,
  },
];

export { BenefitsPageContent };
