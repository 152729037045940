import React from "react";
import backButton from "../assets/back-button-orange.png";
import './BackButton.css'
export const BackButton = ({ set, viewValue }) => {
  return (
    <div className="ei-back-button-wrapper">
      <img
        alt="back_button"
        src={backButton}
        className={`ei-back-button`}
        onClick={() => {
          set("view", viewValue);
        }}
      />
    </div>
  );
};
