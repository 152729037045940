import axios from "axios";
import { put } from "redux-saga/effects";
import { EI_UPDATE_SAUNA_DATA_AUTOMATIONS } from "../../../sagas/actions";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEIAutomationsUpdate(apiCallParams) {
  const token = apiCallParams.token;
  console.log("in requestEIAutomationsUpdate token: ", token);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    siteId: apiCallParams.siteId
  };
  console.log("requestEIAutomationsUpdate headers, data: ", headers, apiCallParams.data);
  const result = yield axios
    .post(
      `${SWEATWORKS_API}/ei/data/room_automations/${apiCallParams.route}/${apiCallParams.staffId}`,
      apiCallParams.data,
      {
        headers: headers,
      }
    )
    .then((res) => {
      console.log("requestEIAutomationsUpdate data: ", res.data);
      return { data: res.data, status: res.status };
    })
    .catch((error) => {
      console.log("ERROR MESSAGE GOES HEREL");
      console.log("requestEIAutomationsUpdate error: ", error);
      return { error: error };
    });
  if (result.status === 200) {
    console.log("result: ", result);
    if (result?.data?.automations) {
      const saunaResult = result.data.automations.saunaResult;
      const redLightResult = result.data.automations.redLightResult;
      yield put({
        type: EI_UPDATE_SAUNA_DATA_AUTOMATIONS,
        payload: {
          sauna: saunaResult,
          redLight: redLightResult,
        },
      });
      return true;
    }
  } else {
    console.log("requestEIAutomationsUpdate: ERROR!");
    return false;
  }
}

export default requestEIAutomationsUpdate;
