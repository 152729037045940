import React, { Component } from "react";
import "./ExperiencePage.css";
import { connect } from "react-redux";
import ExperienceContent from "./ExperienceContent";
import SWPageTemplate from "../General/Pages/PageTemplate";
class ExperiencePage extends Component {
  render() {
    return (
      <div className="ExperiencePage">
        <SWPageTemplate>
          <ExperienceContent />
        </SWPageTemplate>
      </div>
    );
  }
}
const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(ExperiencePage);
