import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import access from "./reducers/access/access";
import accessMessageBox from "./reducers/access/accessMessageBox";
import redLightMessageBox from "./reducers/access/redLightMessageBox";
import uvbLightMessageBox from "./reducers/access/uvbLightMessageBox";
import towelServiceMessageBox from "./reducers/access/towelServiceMessageBox";
import accessIconStatuses from "./reducers/access/accessIconStatuses";
import redLightIconStatus from "./reducers/access/redLightIconStatus";
import towelServiceIconStatus from "./reducers/access/towelServiceIconStatus";
import uvbLightIconStatus from "./reducers/access/uvbLightIconStatus";
import addonStatuses from "./reducers/access/addonStatuses";
import mapCoor from "./reducers/browser/mapCoor";
import navBarPostition from "./reducers/browser/navBarPostition";
import benefitStatuses from "./reducers/browser/benefitStatuses";
import signupMessageBox from "./reducers/ei/signupMessageBox";
import loginMessageBox from "./reducers/ei/loginMessageBox";
import eiAuth from "./reducers/ei/eiAuth";
import eiView from "./reducers/ei/eiView";
import eiSaunas from "./reducers/ei/eiSaunas";
import eiSaunaData from "./reducers/ei/eiSaunaData";
import eiCommandsMessageBox from "./reducers/ei/eiCommandsMessageBox";
import eiLocationData from "./reducers/ei/eiLocationData";
import eiLocationScheduleMessageBox from "./reducers/ei/eiLocationScheduleMessageBox";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App/App";
import rootSaga from "./sagas/rootSaga";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { ParallaxProvider } from "react-scroll-parallax";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  // other store enhancers if any
);


const store = createStore(
  combineReducers({
    access,
    accessIconStatuses,
    redLightIconStatus,
    towelServiceIconStatus,
    uvbLightIconStatus,
    accessMessageBox,
    redLightMessageBox,
    uvbLightMessageBox,
    towelServiceMessageBox,
    addonStatuses,
    mapCoor,
    navBarPostition,
    benefitStatuses,
    signupMessageBox,
    loginMessageBox,
    eiAuth,
    eiView,
    eiSaunas,
    eiSaunaData,
    eiCommandsMessageBox,
    eiLocationData,
    eiLocationScheduleMessageBox
  }),
  enhancer
);
sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(document.getElementById("root")); // Create a root.
root.render(
  <Provider store={store}>
    <Router>
      {/* <ParallaxProvider> */}
        <App />
      {/* </ParallaxProvider> */}
    </Router>
  </Provider>
);
