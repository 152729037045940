import axios from "axios";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* eiUpdateLocationSchedule(apiCallParams) {
  // yield put({ type: EI_REQUEST_UPDATE_LOCATION_DATA_RESET });
  const token = apiCallParams.token;
  const schedule = apiCallParams.schedule;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log("schedule: ", schedule);
  if (!schedule) {
    return false;
  }
  const data = {
    schedule: schedule,
    siteId: apiCallParams.siteId
  };
  return yield axios
    .post(
      `${SWEATWORKS_API}/ei/schedule/update/${apiCallParams.locationId}`,
      data,
      { headers: headers }
    )
    .then((res) => {
      return schedule;
    });
}

export default eiUpdateLocationSchedule;
