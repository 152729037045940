import React, { Component } from "react";
import Card from "../General/Card";
import "./CardGroup.css";
// import MindBodyComponent from "../mindbody/MindBodyComponent";
class PackagesCardGroup extends Component {
  state = {
    loadedHealcode: false,
  };
  cardStyles = {
    height: "5%",
    minHeight: "120px",
    maxHeight: "180px",
    width: "10%",
    minWidth: "300px",
    maxWidth: "400px",
  };
  cardData = [
    {
      cost: "125",
      title: "",
      text: "Add To Cart",
      linkId: "000000101011",
      context: "5 Sessions",
    },
    {
      cost: "200",
      title: "",
      text: "Add To Cart",
      linkId: "10103",
      // linkId: "000000101035",
      context: "10 Sessions",
    },
    {
      cost: "360",
      title: "",
      text: "Add To Cart",
      linkId: "000000101653",
      context: "20 Sessions",
    },
    {
      cost: "480",
      title: "",
      text: "Add To Cart",
      linkId: "000000101660",
      context: "30 Sessions",
    },
    {
      cost: "600",
      title: "",
      text: "Add To Cart",
      linkId: "000000101677",
      context: "50 Sessions",
    },
  ];

  createWidgetLink = (id, buttonName) => {
    return (
      <>
        <script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript" />
        <healcode-widget
          data-version="0.2"
          data-link-class="healcode-pricing-option-text-link"
          data-site-id="26503"
          data-mb-site-id="43256"
          data-bw-identity-site="false"
          data-type="pricing-link"
          data-inner-html={buttonName}
          data-service-id={id}
        />
      </>
    );
  };
  createCards = () => {
    return this.cardData.map((card, i) => {
      return (
        <Card key={i} header={card.title} className="single-card">
          <div className="SW-Card-Body">
            <div className="CardMoneyGroup">
              <div className="CardMoneySign SWMedium">$</div>
              <div className="CardMoneyValue SWMedium">{card.cost}</div>
              <div className="CardMoneyText SWMedium">/mo</div>
            </div>
            <div className="SW-Card-Context">{card.context}</div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">{this.createWidgetLink(card.linkId, "Add To Cart")}</small>
            </div>
          </div>
        </Card>
      );
    });
  };
  render() {
    return (
      <div className="PackagesCardGroup">
        {/* <MindBodyComponent /> */}
        <div className="PackagesIntro">
          <h1 className="MembershipsIntroTextSubtext TitleFont">or</h1>
          <h1 className="PackagesIntroText TitleFont">Choose A Package</h1>
        </div>
        <div className="CardGroup">
          <div className="MembershipCard SW-Card">
            <div className="PricingCardBody">
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">125</div>
              </div>
              <div>5 Sauna Sessions</div>
            </div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                <script
                  src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
                  type="text/javascript"
                ></script>
                <healcode-widget
                  data-version="0.2"
                  data-link-class="healcode-pricing-option-text-link"
                  data-site-id="26503"
                  data-mb-site-id="43256"
                  data-bw-identity-site="false"
                  data-type="pricing-link"
                  data-inner-html="Add To Cart"
                  data-service-id="10101"
                />
              </small>
            </div>
          </div>
          <div className="MembershipCard SW-Card">
            <div className="PricingCardBody">
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">200</div>
              </div>
              <div>10 Sauna Sessions</div>
            </div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                <script
                  src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
                  type="text/javascript"
                ></script>
                <healcode-widget
                  data-version="0.2"
                  data-link-class="healcode-pricing-option-text-link"
                  data-site-id="26503"
                  data-mb-site-id="43256"
                  data-bw-identity-site="false"
                  data-type="pricing-link"
                  data-inner-html="Add To Cart"
                  data-service-id="10103"
                />
              </small>
            </div>
          </div>
          <div className="MembershipCard SW-Card">
            <div className="PricingCardBody">
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">360</div>
              </div>
              <div>20 Sauna Sessions</div>
            </div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                <script
                  src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
                  type="text/javascript"
                ></script>
                <healcode-widget
                  data-version="0.2"
                  data-link-class="healcode-pricing-option-text-link"
                  data-site-id="26503"
                  data-mb-site-id="43256"
                  data-bw-identity-site="false"
                  data-type="pricing-link"
                  data-inner-html="Add To Cart"
                  data-service-id="10165"
                />
              </small>
            </div>
          </div>

          <div className="MembershipCard SW-Card">
            <div className="PricingCardBody">
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">480</div>
              </div>
              <div>30 Sauna Sessions</div>
            </div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                <script
                  src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
                  type="text/javascript"
                ></script>
                <healcode-widget
                  data-version="0.2"
                  data-link-class="healcode-pricing-option-text-link"
                  data-site-id="26503"
                  data-mb-site-id="43256"
                  data-bw-identity-site="false"
                  data-type="pricing-link"
                  data-inner-html="Add To Cart"
                  data-service-id="10166"
                />
              </small>
            </div>
          </div>
          <div className="MembershipCard SW-Card">
            <div className="PricingCardBody">
              <div className="CardMoneyGroup">
                <div className="CardMoneySign SWMedium">$</div>
                <div className="CardMoneyValue SWMedium">650</div>
              </div>
              <div>50 Sauna Sessions</div>
            </div>
            <div style={{ height: "40px" }}>
              <small className="text-muted">
                <script
                  src="https://widgets.mindbodyonline.com/javascripts/healcode.js"
                  type="text/javascript"
                ></script>
                <healcode-widget
                  data-version="0.2"
                  data-link-class="healcode-pricing-option-text-link"
                  data-site-id="26503"
                  data-mb-site-id="43256"
                  data-bw-identity-site="false"
                  data-type="pricing-link"
                  data-inner-html="Add To Cart"
                  data-service-id="10167"
                />
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PackagesCardGroup;
