import React, { Component } from "react";
import { BenefitsContent } from "./BenefitsContent";
import "../Benefits/Benefits.css";
import "../Pricing/CardGroup.css";
import "../General/pulse.css";
import ImageRedWomanTowel from "../../images/woman_towel_red_bg_4_bg_only.png";
import ImageWoman from "../../images/woman_towel_red_bg_4_woman_only_3.png";
import ImageWoman2 from "../../images/woman_towel_red_bg_4_woman_only_2.png";
// import WideRedLight from "../../images/red_light_pic_wide_crop.png";
import { connect } from "react-redux";
import { CLOSE_ALL_BENEFITS, OPEN_BENEFIT } from "../../sagas/actions";
import BenefitsModal from "../Benefits/BenefitsModal";
// import RedLightImage from "../../images/led_red_light_therapy.png";
// import { SWEATWORKS_URL } from "../../TestingVariables";
import RedLightPanel from "./RedLightPanel";

export class BenefitGridOnyx extends Component {
  state = {
    size: "72px",
    modal: false,
    modalType: 1,
    title: "title",
    content: "content",
    hrefReadMore: null,
    hovering: null,
    redLightEnagaged: false,
    windowWidth: 500,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleWidthChange);
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }

  handleWidthChange = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  listenScrollEvent = () => {
    const scrollPos = document.scrollingElement.scrollTop;
    const moreThen = scrollPos > 500;
    const lessThen = scrollPos < 850;
    if (lessThen && moreThen) {
      console.log("less than");
      this.setState({ redLightEnagaged: true });
    } else {
      console.log("more than");
      this.setState({ redLightEnagaged: false });
    }
  };
  show = () => {
    this.setState({ modal: true });
  };
  hide = () => {
    this.setState({ modal: false });
  };
  createlinePivot = (posInfo) => {
    return (
      <div className="onyx-icon-pointer-group">
        <div
          className="onyx-pointer-first ColorSWRed"
          style={{
            top: posInfo.topOne,
            left: posInfo.leftOne,
            width: posInfo.lineOne,
            transform: `rotate(${posInfo.angleOne}deg)`,
            rotationOrigin: "0% 0%",
            borderTop: "1px solid",
          }}
        >
          <div
            className="onyx-pointer-last"
            style={{
              top: posInfo.topTwo,
              left: posInfo.leftTwo,
              width: posInfo.lineTwo,
              transform: `rotate(${posInfo.angleTwo}deg)`,
              rotationOrigin: "0% 0%",
              borderTop: "1px solid",
            }}
          ></div>
        </div>
      </div>
    );
  };

  handleIconHover = (benId) => {
    this.setState({
      hovering: benId,
    });
  };

  click = (itemId) => {
    console.log("handling icon click: ", itemId);
    console.log("!this.state.modal: ", !this.state.modal);
    BenefitsContent.forEach((benefit) => {
      if (itemId === benefit.id) {
        console.log("setAtate: ", benefit.title, benefit.content);
        this.setState({
          title: benefit.title,
          content: benefit.content,
          modalType: itemId,
          modal: !this.state.modal,
          hrefReadMore: benefit.url,
        });
      }
    });
  };

  createBenefitOverlays = () => {
    return BenefitsContent.map((benefit, i) => {
      const posInfo = benefit.position.icon;
      const side = posInfo.facing === "right" ? "Right" : "Left";
      const baseSide = posInfo.facing === "right" ? "left" : "right";
      return (
        <div key={i}>
          <div
            className="BenefitWrapper"
            id={`BenefitWrapper${side}`}
            style={{ top: posInfo.top, [baseSide]: posInfo.left }}
          >
            <div
              className="onyx-benefit-wrapper BenefitIconWrapper blob"
              id={`BenefitTileIconWrapper`}
            >
              <img
                className="onyx-benefit-icon"
                onClick={() => {
                  this.click(benefit.id);
                }}
                onMouseEnter={() => {
                  this.handleIconHover(benefit.id);
                }}
                onMouseLeave={() => {
                  this.setState({
                    hovering: null,
                  });
                }}
                src={benefit.icon}
                alt={benefit.title}
              />
            </div>
            {this.state.hovering === benefit.id ? (
              <div
                className={`onyx-icon-hover-pop-out-${posInfo.facing} ItalicContentFont L`}
                id={
                  this.state.hovering === benefit.id
                    ? "onyx-icon-hovering-active"
                    : ""
                }
              >
                {benefit.title}
              </div>
            ) : null}
            <div className="LabelGroup" id={`LabelGroup${side}`}>
              {this.createlinePivot(benefit.position.pointer)}
            </div>
          </div>
        </div>
      );
    });
  };
  createMobileOverlays = (sideFacing) => {
    let i = 0;
    return BenefitsContent.map((benefit, index) => {
      const posInfo = benefit.position.icon;
      if (posInfo.facing === sideFacing) {
        const side = posInfo.facing === "right" ? "Right" : "Left";
        const baseSide = posInfo.facing === "right" ? "left" : "right";
        const top = i * 100;
        i += 1;
        return (
          <div
            key={index}
            className="MobileBenefitsWrapper"
            onClick={() => {
              this.click(benefit.id);
            }}
          >
            <div
              className="MobileBenefitWrapper"
              id={`MobileBenefitWrapper${side}`}
              style={{ top: `${top - 50}px`, [baseSide]: "15%" }}
            >
              <div
                className="onyx-benefit-wrapper BenefitIconWrapper blob"
                id={`BenefitTileIconWrapper`}
              >
                <img
                  className="mobile-onyx-benefit-icon"
                  onMouseEnter={() => {
                    this.handleIconHover(benefit.id);
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      hovering: null,
                    });
                  }}
                  src={benefit.icon}
                  alt={benefit.title}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const picSetting = !this.state.redLightEnagaged
      ? "onyx-benefits-grid-shader-none"
      : "onyx-benefits-grid-shader-red";
    return (
      <div className="onyx-benefits-grid">
        <div className="onyx-content-wrapper">
          <div
            className={`onyx-benefits-grid-bg`}
            style={{ backgroundImage: `url(${ImageRedWomanTowel})` }}
          >
            {/* {this.state.windowWidth > 1000 ? ( */}
              <div className="onyx-red-light-bg-group">
                <RedLightPanel
                  side="left"
                  engaged={this.state.redLightEnagaged}
                />
                <RedLightPanel
                  side="right"
                  engaged={this.state.redLightEnagaged}
                />
              </div>
            {/* ) : null} */}
            <div>
              {/* <div className="benefit-grid"> */}
              {this.state.modalType ? (
                <BenefitsModal
                  title={this.state.title}
                  color={"#ee6423"}
                  content={this.state.content}
                  modal={this.state.modal}
                  hide={this.hide}
                  // hrefReadMore={`${SWEATWORKS_URL}/benefits/${this.state.hrefReadMore}`}
                />
              ) : null}
              <div className="benefits-grid-title-wrapper">
                <div className="benefits-grid-title TitleFont">
                  ONYX RED LIGHT THERAPY
                </div>
              </div>
              <div className="BenefitsGridOverlayOnyx">
                {this.state.windowWidth < 1000 ? (
                  <div className="onyx-mobile-benefits-group">
                    <div className="onyx-benefits-group ">
                      {this.createMobileOverlays("right")}
                    </div>
                    <div className="onyx-benefits-group onyx-benefits-group-left">
                      {this.createMobileOverlays("left")}
                    </div>
                  </div>
                ) : (
                  this.createBenefitOverlays()
                )}
              </div>
            </div>
            <div className="onyx-benefits-grid-bg-woman-wrapper">
              <div
                className={`onyx-benefits-grid-bg-woman  ${picSetting}`}
                style={{
                  backgroundImage: `url(${
                    this.state.redLightEnagaged ? ImageWoman : ImageWoman2
                  })`,
                }}
              />
            </div>
          </div>
          {/* <div className="sw-red-light-wide-img-wrapper">
            <div className="onyx-website-text-wrapper">
              <div className="onyx-website-text">
                Add ONYX Red light therapy to your session
                <div></div>
              </div>
            </div>
            <img
              alt="Red Light Wide Cropped"
              src={WideRedLight}
              className="sw-red-light-wide-img"
            />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openBene: (id) => {
      dispatch({
        type: OPEN_BENEFIT,
        payload: id,
      });
    },
    closeAllBenes: () => {
      dispatch({
        type: CLOSE_ALL_BENEFITS,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitGridOnyx);
