import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";

export class MessageBox extends Component {
  render() {
    return (
      <div className="MessageBoxWrapper">
        {this.props.reduxState.accessMessageBox.message ? (
          <div className="MessageBox">{this.props.message}</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(MessageBox);
