import axios from "axios";
import { SWEATWORKS_API } from "../../../TestingVariables";

function* requestEISaunas(apiCallParams) {
  const token = apiCallParams.token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return yield axios
    .get(`${SWEATWORKS_API}/ei/data/general`, {
      headers: headers,
    })
    .then((res) => {
      return res.data;
    }).catch((err) => {
      console.log('requestEISaunas error: ', err);

    }
    );
}

export default requestEISaunas;