import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";

export class ThankYouPurchasedView extends Component {
  render() {
    return (
      <div className="ThankYouPurchasedViewWrapper">
        <h1 className="CardBodyText">{this.props.type} has been added! </h1>
        {this.props.towelService ?
          <h5 className="CardBodyText">Please use the access button at the top of this page to unlock the towel door. </h5>
          : <h3 className="CardBodyText">Please allow a brief moment of processing before it will be activated.</h3>
        }
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(ThankYouPurchasedView);
