import saunaImage1 from "../assets/sauna_type_1.png";
import saunaImage2 from "../assets/sauna_type_2.png";
import saunaImage3 from "../assets/sauna_type_3.png";

const genSaunaImage = (type) => {
    switch (type) {
      case 1:
        return saunaImage1;
      case 2:
        return saunaImage2;
      case 3:
        return saunaImage3;
      default:
        return saunaImage1;
    }
  };

export default genSaunaImage;