import React from "react";
import sweatworksStudioMainBgVideo from "../../media/sweatworks_studio_main_bg_video_original.mp4";
import VideoOverlay from "./VideoOverlay";

export default class HomeVideoPlayer extends React.Component {
  state = {
    width: "500px",
  };
  render() {
    return (
      <div style={{ backgroundColor: "black" }}>
        <div className="back-drop-video-group">
          <VideoOverlay />
          <div
            className="back-drop-video-wrapper"
            style={{
              width: "100vw",
              backgroundSize: "cover",
              backrgoundPosistion: "center",
            }}
          >
            <video
              autoPlay
              loop
              muted
              className="back-drop-video"
              style={{
                width: "auto",
                transform: "scaleX(-1)",
                left: 0,
                backgroundPosition: "center",
                top: 0,
                maxWidth: "1600px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <source src={sweatworksStudioMainBgVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    );
  }
}
