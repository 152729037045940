import React, { Component } from "react";
import { connect } from "react-redux";
import { FAILURE, LOADING, SUCCESS } from "../../sagas/actions";
import { FcCheckmark } from "react-icons/fc";
import "./AccessContent.css";
import FailedIcon from "./FailedIcon";

export class AccessButtonStatusIconManager extends Component {
  render() {
    return (
      <div className="ButtonIconWrapper">
        {this.props.accessIconStatuses[this.props.id] === LOADING ? <div size='36px' className="ei-loader-wheel StatusIcon" id="LoadingIcon" /> : null}
        {this.props.accessIconStatuses[this.props.id] === SUCCESS ? <FcCheckmark className="StatusIcon" id="SuccessIcon"/> : null}
        {this.props.accessIconStatuses[this.props.id] === FAILURE ? <FailedIcon  id="FailedIcon"/> : null}
      </div>
    );
  }
}
const mapStateToProps = (reduxState) => ({
  accessIconStatuses: reduxState.accessIconStatuses,
});

export default connect(mapStateToProps)(AccessButtonStatusIconManager);
