import React, { Component } from "react";
import "../Benefits/DesktopBenefits.css";
import { BlogPageContent } from "./BlogPageContent";
import SWPageTemplate from "../General/Pages/PageTemplate";

export class BlogPage extends Component {
  state = {};
  render() {
    return (
      <SWPageTemplate>
        <div id="Content">
          <BlogPageContent />
        </div>
      </SWPageTemplate>
    );
  }
}

export default BlogPage;
