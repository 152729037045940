import SweatIcon from "../../images/assets/custom/SW_1_hot.png";
import SweatPic from "../../images/blood_orange_wet_glass.png";

const BlogContent = [
  {
    id: 1,
    title: "Yes, Infrared saunas do assist in detoxification!",
    type: "Detoxification",
    date: "8/1/2022",
    url: "/blog/blog_page/1",
    content: `\nA simple online search for “detoxification by sweating” may lead you to more questions than answers or even tell you “No you cannot sweat out toxins”. The reality is, based on multiple studies, sweat contains many toxic compounds such as cadmium, lead, arsenic, mercury, POP’s and many more.[i]\n\nA study assessed the concentration of various toxic elements in three body fluids: blood, urine and sweat. All were collected from 20 individuals (10 healthy participants and 10 participants with various health problems) and analyzed for approximately 120 various compounds. Many toxic elements appeared to be preferentially excreted through sweat. Presumably stored in tissues, some toxic elements readily identified in the perspiration of some participants were not found in their serum. The study concluded that “Induced sweating appears to be a potential method for elimination of many toxic elements from the human body.” [ii]\n\nThe average American has 34% body fat.[iii] Body fat is a known reservoir for toxicity as it has an affinity for many kinds of chemicals and heavy metals.[iv] As we consume food and breathe impure air, many toxic compounds can enter our system.\n\n
    If our body detoxed to perfection, we would consume them and excrete them. However, in our imperfection, toxins circulate and absorb in our tissues and in most cases, our fat traps them. This can help protect harmful agents from remaining in the blood stream and effecting our cells and tissues, however over time, toxins can accumulate and leach back into our body. It is the accumulation of toxins that can add to the dysfunction of our cells and increase potential for disease and metabolic deficiencies as we age.\n\nThis is where infrared saunas can play a big role!\n\nAs we lose body fat, we can reintroduce toxins into the blood stream. This is why heating up the body and excreting some of these toxins out through your pores during perspiration will help your body better handle weight loss. During fat metabolism, our fat cells break down into carbon dioxide and water. Water we must sweat and pee out, and carbon dioxide we breathe out! Yes! Essentially we need to breathe more to lose body fat!  Even if weight loss is not your goal, beginning to eliminate chemicals from your body will always help in the long run.\n\nWe must look at detoxification in a new light. It is not just your liver and kidneys that provide detoxification in your body! While infrared saunas can increase blood flow and vasodilate blood vessels (open up) which can assist our liver and kidneys in detoxification, we have many other pathways of detoxification. Detoxification is multifaceted!
    \n\t1.\tBreath exercises and physical exercise increases the amount of carbon dioxide and waste product we remove from our blood stream and body. We increase our breathe rate and depth while our heart rates reach up to 150 beats per minute in a sauna.
    \n\t2.\tOur billions of capillaries must be functioning optimally to bring nutrients and oxygen to nearby tissue and to deliver CO2 and metabolic waste from the tissue. Microcirculation (or Capillaries) compose 70% of our blood flow! Infrared Saunas can increase microcirculation through the gentle heating to vasodilate blood vessels and reduce the viscosity of our blood all while increasing our heart rate. This combination makes it easier for blood to pass through our smallest blood vessels called the capillaries. [v]
    \n\t3.\tSweating purifies our body from the built up toxins that have been absorbed by our fat cells. While this is a slow process of releasing toxins through the pores, it is very effective. The direct heating of infrared saunas helps to release the toxins of the fat cells and accelerate the quantity of sweat released. Some of our clients utilize flushing niacin to open up the blood vessels as well as take activated charcoal during fasting periods to bind to many of the toxins that re-enter the blood stream.\n\nOur combination of high temperature, full-spectrum infrared saunas and our ONYX red light panels can be a tremendous boost to your detoxification regimen. According to a 2011 study in Obesity Surgery, red light causes the adipocytes to release triglycerides, resulting in reduced volume and a measurable loss of fat.\n\nThis is a powerful combination that can take your detoxification to the next level!\n\nHEAL. TRANSFORM. OPTIMZE.
    \n\nSWEATWORKS STUDIO. \n\n\n\n(SOURCES)\n\n[i]Arsenic, Cadmium, Lead, and Mercury in Sweat: A Systematic ReviewJ Environ Public Health. 2012; 2012: 184745. Published online 2012 Feb 22. doi: 10.1155/2012/184745\n\n[ii] Blood, urine, and sweat (BUS) study: monitoring and elimination of bioaccumulated toxic elements
    \nStephen J Genuis 1, Detlef Birkholz, Ilia Rodushkin, Sanjay Beesoon\nAffiliations expand
    \n\tPMID: 21057782
    \n\tDOI: 10.1007/s00244-010-9611-5
    \n[iii] Obesity (Silver Spring). Author manuscript; available in PMC 2013 Nov 22.\nPublished in final edited form as:\nObesity (Silver Spring). 2010 Nov; 18(11): 10.1038/oby.2010.103.\ndoi: 10.1038/oby.2010.103\nhttps://www.ncbi.nlm.nih.gov/pmc/articles/PMC3837418/\n\n[iv] Adipose Tissue as a Site of Toxin Accumulation\nCompr Physiol. 2017 Sep 12; 7(4): 1085–1135.\nPublished online 2017 Sep 12. doi: 10.1002/cphy.c160038\n\n[v] Blood viscosity and blood pressure: role of temperature and hyperglycemia\nYildirim Çinar,  A. Mete Şenyol,  Kamber Duman\nAmerican Journal of Hypertension, Volume 14, Issue 5, May 2001, Pages 433–438, https://doi.org/10.1016/S0895-7061(00)01260-7`,

    icon: SweatIcon,
    picture: SweatPic,
  },
];

export { BlogContent };
