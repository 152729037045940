import React, { Component } from "react";
import { connect } from "react-redux";
import { SET_POSITION } from "../../sagas/actions";
import { SWPageTemplate } from "../General/Pages/PageTemplate";
import Locations from "./Locations";
import "./LocationsPage.css";

class LocationsPage extends Component {
  

  render() {
    return (
      <SWPageTemplate>
        <div className="LocationsPage">
          {/* <DesktopNavPopDown color="blue"  zIndex={100} styleSize={this.props.styleSize} navColor={"white"} /> */}
          <div className="LocationsGroupWrapper">
            <Locations />
          </div>
        </div>
      </SWPageTemplate>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNavPos: (payload) => {
      dispatch({
        type: SET_POSITION,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
