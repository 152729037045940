import AwakenRoomSauna1 from "../../images/AwakenRoomSauna.jpg";
import AwakenRoomSauna2 from "../../images/AwakenRoomSauna2.jpg";
import AwakenRoomSauna3 from "../../images/AwakenRoomSauna3.jpg";

export const ReviewsContent = [
  {
    id: 1,
    stars: 5,
    date: 'January, 2020',
    image: AwakenRoomSauna1,
    name: 'Stephanie M.',
    content:
      "Sweatworks Studio in Hudson Wisconsin is a fantastic place to meet your detoxification needs through use of saunas. The staff is most friendly and helpful. The environment is relaxing a nd clean, and due to their membership pricing , its definately affordable. I also just experienced a massage with Cierra and hand-down it was the best massage of my life!",
  },
  {
    id: 3,
    stars: 5,
    date: 'May 2021',
    image: AwakenRoomSauna3,
    name: 'Kritine M.',
    content: "Great value private sauna membership. Clean and private facilities. Excellent customer service - the front desk team is great.",
  },
  {
    id: 4,
    stars: 5,
    date: 'December 2020',
    image: AwakenRoomSauna1,
    name: 'Rebekah L.',
    content: "Lisa was great and the service was excellent.  Awaken was attentive and courteous. I was able to get the relief I needed for an injury. And already recommended them to others! Will be back again soon!",
  },
  {
    id: 5,
    stars: 5,
    date: 'March 2020',
    image: AwakenRoomSauna2,
    name: 'Courtney B.',
    content: "Fantastic spa with great prices (especially for new clients via the website or Groupon). I had a massage here as well as a session in the infrared sauna. The staff were all wonderful and friendly. Booking appointments online was easy. The services were wonderful and the lobby is calming/relaxing. I'll be back!",
  },
  {
    id: 6,
    stars: 5,
    date: 'February 2020',
    image: AwakenRoomSauna3,
    name: 'Lyn S.',
    content:
      "Amazing experience! The saunas are just all around  wonderful. You definitely need two bottles of water. I'm glad I listened to their instruction.",
  },
  {
    id: 7,
    stars: 5,
    date: 'January 2020',
    image: AwakenRoomSauna2,
    name: 'Nick F.',
    content:
      "I badly pulled my left hip flexor from too much xc skiing two weekends ago, and it was slow to heal, making it so I could only sleep in one position, and also walking with a limp for a few days.\nI went in and did the last infrared session (off of a three pack) and WOW!  As the heat and perspiration increased, so did the relief from the dull ache that had persisted for almost a week, and kept waking me up.\n\nThough time was a factor in the healing process, I had a noticeable improvement from when I left the sauna, compared to when I entered the infrared sauna, and I was pretty much 100% the next day!\n\nAlways really nice folks working the desk there too.",
  },
  {
    id: 2,
    stars: 5,
    date: 'January, 2020',
    image: AwakenRoomSauna2,
    name: 'Mac G.',
    content:
      "I have lived in pain for over thirty years with a debilitating autoimmune disease that causes excruciating pain in my spine and hips. An acupuncturist recommended an infrared sauna. Turns out Awaken for Wellness was a mile away! I purchased the introductory package of five sessions at a very reasonable rate. I swear I could tell a difference in my pain level after one session! I also noticed changes in my skin. I have struggled with chronic eczema my entire life. At one time, 80% of my body was covered in eczema. After two sessions, I noticed my skin felt different, softer and \"more at peace.\" My recovery has been nothing short of miraculous. n\nDo yourself a favor if you struggle with pain or skin issues. Infrared saunas could be the answer for you too.",
  },
];
