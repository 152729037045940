import { call, put, all, takeEvery } from "redux-saga/effects";
import { SEND_REQUEST } from "./actions";

// Reusable function for creating actiontypes
export const makeLoadingActionTypes = (actionType) => ({
  loading: actionType + "_LOADING",
  success: actionType + "_SUCCESS",
  failure: actionType + "_FAILURE",
  reset: actionType + "_RESET",
});
function* loadData(action) {
  const { loadActionType, apiCall, apiCallParams } = action;
  const {
    loading: LOAD_ACTION_LOADING,
    success: LOAD_ACTION_SUCCESS,
    failure: LOAD_ACTION_FAILURE,
  } = makeLoadingActionTypes(loadActionType);
  try {
    // Handle this case in your reducer to set isLoading=true
    yield put({
      type: LOAD_ACTION_LOADING,
      params: apiCallParams
    });
    // Make API call or some other async function
    const data = yield call(apiCall, apiCallParams);
    if (!!data) {
      yield put({ type: LOAD_ACTION_SUCCESS, payload: data, params: apiCallParams });
    } else {
      yield put({ type: LOAD_ACTION_FAILURE, data: data, params: apiCallParams });
    }
  } catch (error) {
    console.log("error: ", error);
    // Handle this case in your reducer for failure and set your error if you need to
    console.log('LOAD_ACTION_FAILURE: ', LOAD_ACTION_FAILURE);
    yield put({ type: LOAD_ACTION_FAILURE, error: error });
  }
}

export default function* loadingSaga() {
  yield all([takeEvery(SEND_REQUEST, loadData)]);
}
