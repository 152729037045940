import React from "react";
import "./EmployeeInterfaceLocationView.css";
import LocationSchedule from "./LocationSchedule";
import { BackButton } from "../components/BackButton";
import LoadingWrapper from "../../General/LoadingWrapper";
import { EI_LOCATION_DATA, EI_REQUEST_UPDATE_LOCATION_DATA } from "../../../sagas/actions";
import { connect } from "react-redux";
import LocationScheduleExceptions from "./LocationScheduleExceptions";

const EmployeeInterfaceLocationView = (props) => {
  console.log('EmployeeInterfaceLocationView props: ', props);
  return (
    <div className="ei-location-wrapper">
      <LoadingWrapper
        actionType={EI_LOCATION_DATA}
        stateReader={(reduxState) => {
          const { isLoading, error } = reduxState.eiLocationData;
          console.log("isLoading, error: ", isLoading, error);
          return { isLoading, error };
        }}
      >
        <LoadingWrapper
          actionType={EI_REQUEST_UPDATE_LOCATION_DATA}
          stateReader={(reduxState) => {
            const { isUpdating, error } = reduxState.eiLocationData;
            console.log("isUpdating, error: ", isUpdating, error);
            return { isLoading: isUpdating, error };
          }}
        >
          <div className="ei-header-wrapper">
            <BackButton set={props.set} viewValue="home" />
            <div className="ei-header-title">LOCATION SETTINGS</div>
          </div>
          <LocationSchedule
            locationId={props.locationId}
            locationName={props.locationName}
            siteId={props.siteId}
          />
        </LoadingWrapper>
        <LocationScheduleExceptions
          locationId={props.locationId}
          locationName={props.locationName}
          refreshLocationData={props.refreshLocationData}
          siteId={props.siteId}
        />
      </LoadingWrapper>
    </div>
  );
};

const mapStateToProps = (reduxState) => ({
  eiLocationData: reduxState.eiLocationData,
});

export default connect(mapStateToProps)(EmployeeInterfaceLocationView);
