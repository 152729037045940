// import React from "react";
const moment = require("moment-timezone");

const dateFormatter = (dateTime) => {
  return moment(new Date(dateTime)).format("M/DD");
};

const timeFormatter = (startDateTime) => {
  return moment(startDateTime).format("h:mm a");
};

const currentQuarterDay = () => {
  const now = moment();
  const start = moment().startOf("day");
  const sixAm = moment(start).add(6, "hours");
  const noon = moment(start).add(12, "hours");
  const sixPm = moment(start).add(18, "hours");
  const end = moment().endOf("day");
  if (start.isBefore(now) && now.isBefore(sixAm)) {
    return 1;
  } else if (sixAm.isBefore(now) && now.isBefore(noon)) {
    return 2;
  } else if (noon.isBefore(now) && now.isBefore(sixPm)) {
    return 3;
  } else if (sixPm.isBefore(now) && now.isBefore(end)) {
    return 4;
  }
  return "whaaaack";
};

module.exports = {
  dateFormatter,
  timeFormatter,
  currentQuarterDay,
};
