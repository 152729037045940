import moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import eiUpdateLocationSchedule from "../../../modules/api/ei/eiUpdateLocationSchedule";
import { EI_REQUEST_UPDATE_LOCATION_DATA, SEND_REQUEST } from "../../../sagas/actions";
import "./EmployeeInterfaceLocationView.css";

class LocationSchedule extends Component {
  state = {
    errorMessage: null,
    schedule: {}
  };
  componentDidMount() {
    this.setState({
      schedule: { ...this.createPrevSchedule() },
    });
  }
  createPrevSchedule = () => {
    return {
      0: this.props.eiLocationData[0],
      1: this.props.eiLocationData[1],
      2: this.props.eiLocationData[2],
      3: this.props.eiLocationData[3],
      4: this.props.eiLocationData[4],
      5: this.props.eiLocationData[5],
      6: this.props.eiLocationData[6],
    };
  };

  createNewScheduleObj = () => {
    const previousSchedule = this.createPrevSchedule();
    const newSchedule = {
      ...previousSchedule,
      ...this.state.schedule,
    };

    return Object.values(newSchedule);
  };
  updateLocalState = (i, data) => {
    this.setState({
      schedule: {
        ...this.state.schedule,
        [i]: data,
      },
    });
  };
  handleUpdate = (locationId) => {
    const newScheduleObj = this.createNewScheduleObj();
    console.log("newScheduleObj", newScheduleObj);
    for (let i = 0; i < newScheduleObj.length; i++) {
      const scheduleDay = newScheduleObj[i];
      if (
        !(
          moment(scheduleDay.open, "hh:mm a", true).isValid() ||
          moment(scheduleDay.open, "h:mm a", true).isValid() ||
          scheduleDay.open === "0:00 am"
        )
      ) {
        return this.setState({ errorMessage: "Incorrect OPEN format for day " + scheduleDay.day });
      }
      if (
        !(
          moment(scheduleDay.close, "hh:mm a", true).isValid() ||
          moment(scheduleDay.close, "h:mm a", true).isValid() ||
          scheduleDay.open === "0:00 am"
        )
      ) {
        return this.setState({ errorMessage: "Incorrect CLOSE format for day " + scheduleDay.day });
      }
    }
    this.setState({ errorMessage: null });
    const token = localStorage.getItem("token");
    this.props.requestUpdateLocationSchedule(this.props.siteId, locationId, token, newScheduleObj);
  };

  createDayRows = (scheduleData) => {
    console.log("scheduleData: ", scheduleData);
    return scheduleData.map((scheduleDay, i) => {
      if (i === 0) {
        console.log(scheduleDay);
      }
      return (
        <div className="ei-schedule-row-wrapper" key={i}>
          <div className="ei-schedule-day-title">{scheduleDay.day}</div>
          <div className="ei-schedule-time-wrapper">
            <input
              className="ei-schedule-time-input"
              value={this.state.schedule[`${i}`]?.open || ""}
              onChange={(e) => {
                console.log("this.state.schedule[i]: ", this.state.schedule[`${i}`]);
                const close = this.state.schedule[`${i}`]?.close
                  ? this.state.schedule[`${i}`].close
                  : this.props.eiLocationData[i].close;
                console.log("close: ", close);
                console.log("scheduleDay.day: ", scheduleDay.day);
                console.log("e.target.value: ", e.target.value);
                this.updateLocalState(i, {
                  day: scheduleDay.day,
                  open: e.target.value,
                  close: close,
                });
              }}
            />
            <input
              className="ei-schedule-time-input"
              value={this.state.schedule[`${i}`]?.close || ""}
              onChange={(e) => {
                const open = this.state.schedule[`${i}`]?.open
                  ? this.state.schedule[`${i}`].open
                  : this.props.eiLocationData[i].open;
                this.updateLocalState(i, {
                  ...this.state.schedule[i],
                  day: scheduleDay.day,
                  open: open,
                  close: e.target.value,
                });
              }}
            />
            <button
              className="glow-on-hover"
              onClick={(e) => {
                this.updateLocalState(i, {
                  ...this.state.schedule[i],
                  day: scheduleDay.day,
                  open: "0:00 am",
                  close: "0:00 am",
                });
              }}
            >
              CLOSED
            </button>
          </div>
        </div>
      );
    });
  };
  render() {
    console.log("before render data: ", this.props.eiLocationData);
    return (
      <div className="location-schedule-container">
        <div className="ei-schedule-title">{this.props.locationName} Schedule</div>
        <div>12:00 pm is NOON | 12:00 am is MIDNIGHT </div>
        <br />
        <div>
          If the day is scheduled to be closed, please select the CLOSED button.
        </div>
        <div className="ei-schedule-title">
          <div className="ei-flex ei-schedule-sub-title">
            <div className="ei-schedule-day-sub-holder"></div>
            <div className="ei-schedule-open-close-box">
              <div>OPEN</div>
              <div>CLOSE</div>
            </div>
          </div>
        </div>
        {this.createDayRows(this.props.eiLocationData)}
        <div className="ei-locations-button-wrapper">
          <button
            type="button"
            className="glow-on-hover"
            onClick={() => {
              this.handleUpdate(this.props.locationId);
            }}
          >
            UPDATE
          </button>
        </div>
        <div>{this.state.errorMessage ? this.state.errorMessage : ""}</div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  eiLocationData: reduxState.eiLocationData.payload.schedule,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUpdateLocationSchedule: async (siteId, locationId, token, schedule) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_REQUEST_UPDATE_LOCATION_DATA,
        apiCall: eiUpdateLocationSchedule,
        apiCallParams: {
          siteId: siteId,
          locationId: locationId,
          token: token,
          schedule: schedule,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSchedule);
