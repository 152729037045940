import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import AddUvbLightView from "./AddUvbLightView";
import ThankYouPurchasedView from "./ThankYouPurchasedView";
import { REQUEST_ADD_UVB_LIGHT, SEND_REQUEST } from "../../sagas/actions";
import addUvbLightApiCall from "../../modules/api/addUvbLightApiCall";
import AlreadyPurchasedUvbLight from "./AlreadyPurchasedUvbLight";
import UvbLightImage from "../../images/Uvb_ps.jpg";
import { Card } from "../General/Card";

export class Uvb extends Component {
  state = {
    addUvbLightApiCall: false,
  };
  uvbLightComnponentManager = () => {
    const uvb = this.props.reduxState.access.data.appointment.uvb;
    if (this.props.reduxState.addonStatuses.uvbLightAdded) {
      return <ThankYouPurchasedView type="Uvb" towelService={false} />;
    } else if (!uvb) {
      return <AddUvbLightView />;
    } else if (uvb) {
      return <AlreadyPurchasedUvbLight />;
    }
  };

  render() {
    return (
      <div className="uvbLightWrapper">
        <div className="WindowPane"></div>
        <Card header="UVB LIGHT THERAPY">
          <img alt="Uvb Light" src={UvbLightImage} id="UvbLightImage" />
          {/* <div id="UvbContentWrapper"> */}
          {this.props.reduxState.addonStatuses.uvbLightAdded
            ? this.uvbLightComnponentManager()
            : this.uvbLightComnponentManager()}
          {/* </div> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addUvbLight: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_ADD_UVB_LIGHT,
        apiCall: addUvbLightApiCall,
        apiCallParams: { accessKey: accessKey },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uvb);
