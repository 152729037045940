import React, { Component } from "react";
import LocationsMap from "./LocationsMap";
import "./HomeLocationWidget.css";
import { connect } from "react-redux";
import { RESET_COOR, SET_COOR } from "../../sagas/actions";
import LocationCards from "./LocationCards";
import "./Locations.css";
// import HomeLocationWidget from "./HomeLocationWidget";

class Locations extends Component {
  state = {
    center: { lat: 44.9346103614, lng: -93.1773953441 }, //initial map center
    windowWidth: 500,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWidthChange);
    this.setState({
      windowWidth: window.innerWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWidthChange);
  }

  handleWidthChange = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  locations = [
    {
      state: "mn",
      title: "Find a Location",
      locations: [
        {
          location: "stpaul",
          title: "Awaken For Wellness - St Paul",
          lat: 44.93440483032277,
          lng: -93.17737065347846,
          addressFH: "1821 St Clair Ave,",
          addressSH: "St Paul, MN 55105",
          phoneNumber: "651-493-0459",
        },
        {
          location: "woodbury",
          title: "The Salt Room - Woodbury",
          lat: 44.91847441142029,
          lng: -92.90182056646333,
          addressFH: "411 Co Rd UU,",
          addressSH: "Hudson, WI 54016",
          phoneNumber: "715-531-4035",
        },
        {
          location: "hudson",
          title: "Awaken For Wellness - Hudson",
          lat: 44.97726233819796,
          lng: -92.71782387767144,
          addressFH: "411 Co Rd UU,",
          addressSH: "Hudson, WI 54016",
          phoneNumber: "715-531-4035",
        },
      ],
    },
  ];

  places = [
    {
      locationId: 1,
      location: "Awaken For Wellness - St Paul",
      lat: 44.93440483032277,
      lng: -93.17737065347846,
      addressFH: "1821 St Clair Ave,",
      addressSH: "St Paul, MN 55105",
      phoneNumber: "651-493-0459",
    },
    {
      locationId: 2,
      location: "Awaken For Wellness - Hudson",
      lat: 44.97726233819796,
      lng: -92.71782387767144,
      addressFH: "411 Co Rd UU,",
      addressSH: "Hudson, WI 54016",
      phoneNumber: "715-531-4035",
    },
    {
      locationId: 3,
      location: "The Salt Room Woodbury",
      lat: 44.91847441142029,
      lng: -92.90182056646333,
      addressFH: "2110 Eagle Creek Ln Suite 350,",
      addressSH: "Woodbury, MN 55129",
      phoneNumber: "715-531-4035",
    },
  ];

  handleLocationClick = async (lat, lng) => {
    console.log("CLICK HANDLED");
    await this.props.resetCoor();
    await this.props.setCoor({ lat: lat, lng: lng });
    console.log('trying to scroll to top');
    document.getElementById("root").scrollTo({top: 0, left: 0,behavior: 'smooth'})
  };

  render() {
    return (
      <div className={"LocationsMapWrapper " + (this.state.windowWidth > 800 ? "Locations" : "LocationsMobile")}>
        <LocationsMap
          id="LocationMapFull"
          dimensions={{
            height: (this.state.windowWidth > 800 ? "60vh" : "40vh"),
            maxHeight: "600px",
            width: (this.state.windowWidth > 800 ? "50%" : "100%"),
            paddingTop: "5%",
          }}
          zoom={11}
          center={this.state.center}
          handleLocationClick={this.handleLocationClick}
          locations={this.places}
        />
        <div className={this.state.windowWidth > 800 ? "locations-side-menu" : "locations-side-menu-mobile"}>
          {/* <div className="FullLocationsTitle ItalicContentFont XXL">
            LOCATIONS
          </div> */}
          <LocationCards
            handleLocationClick={this.handleLocationClick}
            locations={this.locations}
          />
          {/* <HomeLocationWidget widget={false} /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetCoor: () => {
      dispatch({
        type: RESET_COOR,
      });
    },
    setCoor: (payload) => {
      dispatch({
        type: SET_COOR,
        payload: payload,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Locations);
