import React, { Component } from "react";
import "./DesktopBenefits.css";
import { FullBenefitImagesList } from "./FullBenefitImagesList";
import SWPageTemplate from "../General/Pages/PageTemplate";
const Scroll = require('react-scroll');
const scroller  = Scroll.scroller;

export class BenefitImagesPage extends Component {
  componentDidMount() {
    const url = window.location.pathname;
    if (url !== "/benefits") {
      // setTimeout(() => {
      //   console.log('clicking : ', url);
      //   document.getElementById(url).click();
      // }, 250);
      scroller.scrollTo(url, {
        offset: -50,
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId:  'root',
      })
    }
  }

  render() {
    return (
      <div className="BenefitImagesPage">
        <SWPageTemplate>
          <div className="BenefitsListGroup">
            <FullBenefitImagesList />
          </div>
        </SWPageTemplate>
      </div>
    );
  }
}

export default BenefitImagesPage;
