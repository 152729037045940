import React, { Component } from "react";
import RedLightImage from "../../images/led_red_light_therapy.png";
// import Ray from "../General/Ray";
// import RayRack from "../General/RayRack";
import "./RedLightPage.css";

class RedLightPanel extends Component {
  // createLongRayCluster = (i) => {
  //   return (
  //     <div className={`long-ray-cluster long-ray-cluster-${this.props.engaged ? 'open' : 'closed'}`}>
  //       <div className={`red-light-panel-ray-group-${this.props.side}`}>
  //         <Ray />
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //         <div className="ray-wrapper">
  //           <Ray />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // };

  render() {
    return (
      <div className="RedLightPanel">
        <div className={`red-light-panel-group-${this.props.side}`}>
          <img src={`${RedLightImage}`} alt="RedLightImage" className="onyx-red-light-bg" />
          {/* <div className={`ray-clusters-wrapper-${this.props.side}`}>
            <RayRack startFrom={this.props.side}/>
            <RayRack startFrom={this.props.side}/>
          </div> */}
          {/* {this.createLongRay()}
          {this.createLongRay()}
          {this.createLongRay()}
          {this.createLongRay()}
          {this.createLongRay()} */}
        </div>
      </div>
    );
  }
}
export default RedLightPanel;
