import axios from "axios";
import { SWEATWORKS_API } from "../../TestingVariables";

// const accessDataApiCall = async({accessKey}) => {
function* accessDataApiCall(apiCallParams) {
  const accessKey = apiCallParams.accessKey;
  return yield axios
    .get(`${SWEATWORKS_API}/access/data/${accessKey}`)
    .then((res) => {
      console.log("res", res);
      return res.data;
    });
}

export default accessDataApiCall;
