import axios from "axios";
import { put } from "redux-saga/effects";
import {
    ACCESS_MESSAGE_BOX_DISABLED,
    ADD_TOWEL_ACCESS_BUTTON,
    RED_LIGHT_MESSAGE_BOX_DISABLED,
    TOWEL_SERVICE_ADDED,
    TOWEL_SERVICE_MESSAGE_BOX_EXPIRED,
    TOWEL_SERVICE_MESSAGE_BOX_INTERNAL_ERROR,
    TOWEL_SERVICE_MESSAGE_BOX_INVALID,
    TOWEL_SERVICE_MESSAGE_BOX_RESET,
    UVB_LIGHT_MESSAGE_BOX_DISABLED,
} from "../../sagas/actions";
import { SWEATWORKS_API } from "../../TestingVariables";

// const accessDataApiCall = async({accessKey}) => {
function* addTowelServiceApiCall(apiCallParams) {
    const accessKey = apiCallParams.accessKey;
    console.log("in addTowelServiceApiCall");
    const result = yield axios
        .post(`${SWEATWORKS_API}/appointment/add_service/towel_service/${accessKey}`)
        .then((res) => {
            console.log("res", res);
            return { data: res.data, status: res.status };
        })
        .catch((error) => {
            console.log("error: ", error);
            return error;
        });
    if (result.status === 200) {
        yield put({ type: TOWEL_SERVICE_ADDED });
        yield put({ type: TOWEL_SERVICE_MESSAGE_BOX_RESET });
        yield put({ type: ADD_TOWEL_ACCESS_BUTTON });
        navigator.vibrate([50]);
        return true;
    } else if (result.status === 210 || result.status === 240 || result.status === 250) {
        console.log("result.data.error: ", result.data.error);
        if (result.data.error === "Appointment has Expired") {
            yield put({ type: TOWEL_SERVICE_MESSAGE_BOX_EXPIRED });
            yield put({ type: ACCESS_MESSAGE_BOX_DISABLED });
            yield put({ type: RED_LIGHT_MESSAGE_BOX_DISABLED });
            yield put({ type: UVB_LIGHT_MESSAGE_BOX_DISABLED });
            return false;
        } else if (result.data.error === "Internal Server Error") {
            yield put({ type: TOWEL_SERVICE_MESSAGE_BOX_INTERNAL_ERROR });
            return false;
        } else if (result.data.error === "Credential Invalid") {
            yield put({ type: TOWEL_SERVICE_MESSAGE_BOX_INVALID });
            return false;
        }
    }
}

export default addTowelServiceApiCall;