import React, { Component } from "react";
import "../Benefits/DesktopBenefits.css";
import SWPageTemplate from "../General/Pages/PageTemplate";
import SWArrow from "../../images/assets/pointer_right.png";

import "./BlogPageHome.css";
// import SearchIcon from "../../images/assets/sw-search-icon-charcoal.png.svg";
import { ReactComponent as SearchIcon } from "../../images/assets/sw-search-icon.svg";
import "./BlogPageHome.css";
import { SWEATWORKS_URL } from "../../TestingVariables";
const { BlogContent } = require("./BlogContent");

// import WaterTextureDark from "../../images/texture-water-2.jpg";
export class BlogPageHome extends Component {
  state = {
    searchQuery: "",
    searchResults: [],
    searched: false,
  };
  searchBlogs = (query) => {
    let results = [];
    for (let i = 0; i < BlogContent.length; i++) {
      const blog = BlogContent[i];
      for (const catagory in blog) {
        if (typeof blog[catagory] === "string") {
          if (blog[catagory].toUpperCase().match(query.toUpperCase())) {
            results = [...results, blog.id];
          }
        }
      }
    }
    return [...new Set(results)];
  };

  handleSearchClick = async (q) => {
    const results = this.searchBlogs(q);
    this.setState({
      searchResults: results,
      searched: true,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      searchQuery: e.target.value,
    });
  };
  handleTagClick = (e) => {
    this.handleInputChange(e);
    this.handleSearchClick(e.target.value);
  };
  createBlogSnippet = (word, text) => {
    const sentenceArray = text
      .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
      .split("|");
    return sentenceArray.filter((sentence) => sentence.includes(word));
  };
  render() {
    return (
      <SWPageTemplate>
        <div className="BlogPageHome">
          {/* <div className="blog-page-home-header-bg"> */}
          {/* <div className="blog-page-home-header">
            Learn more about Infrared Sauna Therapy from our blogs
          </div> */}
          {/* </div> */}
          <div className="blog-page-home-content-group">
            <div className="blog-page-home-sidebar">
              <div className="blog-page-sidebar-group"></div>
            </div>
            <div className="blog-page-home-content-wrapper">
              <div className="nav-tree">
                <a
                  className="sw-color-primary sw-color-primary-hover"
                  href={`${SWEATWORKS_URL}/`}
                >
                  home
                </a>
                <img
                  className="nav-tree-icon"
                  src={SWArrow}
                  alt="navigation-arrow"
                />
                <strong>blogs</strong>
              </div>
              <div className="blog-page-home-content">
                <div className="blog-search-bar-group">
                  <div className="blog-search-input-group">
                    <div className="blog-search-input-title">
                      Search our blog content
                    </div>
                    <div className="blog-search-input-button-group">
                      <input
                        className="blog-search-input"
                        value={this.state.searchQuery}
                        onChange={(e) => {
                          this.handleInputChange(e);
                        }}
                      />
                      <SearchIcon
                        className="sw-search-icon"
                        alt="search"
                        onClick={() => {
                          this.handleSearchClick(this.state.searchQuery);
                        }}
                      />
                    </div>
                  </div>
                  <div className="blog-search-button-wrapper">
                    <div className="blog-search-button-group">
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Detoxification"
                      >
                        Detoxification
                      </button>
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Skin Purification"
                      >
                        Skin Purification
                      </button>
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Cardiovascular"
                      >
                        Cardiovascular Health
                      </button>
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Weight Management"
                      >
                        Weight Management
                      </button>
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Cellular Health"
                      >
                        Cellular Health
                      </button>
                      <button
                        className="blog-search-button"
                        onClick={(e) => {
                          this.handleTagClick(e);
                        }}
                        value="Pain Relief"
                      >
                        Pain Relief
                      </button>
                    </div>
                  </div>
                </div>
                <div className="blog-post-cards-group">
                  {this.state.searched ? (
                    <div className="blog-search-results-number">
                      results: ({this.state.searchResults.length})
                    </div>
                  ) : null}
                  {this.state.searchResults.length > 0 ? (
                    <div className="blog-post-search-results-group">
                      {this.state.searchResults.map((result, i) => {
                        const blog = BlogContent.filter((blog) => {
                          return blog.id === result;
                        })[0];
                        return (
                          <div
                            className="blog-post-search-result-group"
                            key={i}
                          >
                            <a
                              href={`${SWEATWORKS_URL}/blog/blog_page/${blog.id}`}
                              className="blog-card-a"
                            >
                              <h3 className="blog-post-result-title sw-color-primary">
                                {blog.title}
                              </h3>
                              <section className="blog-post-snippet ColorBlack">
                                {blog.content.substring(0, 200)}...
                              </section>
                              <div className="blog-post-date-group">
                                <div className="blog-post-date sw-color-primary-dark">
                                  Posted: {blog.date}
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  {this.state.searchResults.length < 0 ? (
                    <div className="blog-search-results-number">
                      results: ({this.state.searchResults.length})
                    </div>
                  ) : null}
                  {this.state.searchResults.length === 0 ? (
                    <>
                      <div className="blog-post-recent-title">
                        Recent Blog Posts:
                      </div>
                      <div className="blog-post-search-results-group">
                        {BlogContent.map((blog, i) => {
                          return (
                            <div
                              className="blog-post-search-result-group"
                              key={i}
                            >
                              <a
                                href={`${SWEATWORKS_URL}/blog/blog_page/${blog.id}`}
                                className="blog-card-a"
                              >
                                <h3 className="blog-post-result-title sw-color-primary">
                                  {blog.title}
                                </h3>
                                <section className="blog-post-snippet ColorBlack">
                                  {blog.content.substring(0, 200)}...
                                  {/* {this.createBlogSnippet(
                                    this.state.searchQuery,
                                    blog.content
                                  )} */}
                                  {/* {blog.content.substring(0, 200)}... */}
                                </section>
                                <div className="blog-post-date-group">
                                  <div className="blog-post-date sw-color-primary-dark">
                                    Posted: {blog.date}
                                  </div>
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SWPageTemplate>
    );
  }
}

export default BlogPageHome;
