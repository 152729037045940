import React, { Component } from "react";
import "./Benefits.css";
// import "../General/Ray.css";
import Infrared from "../../images/assets/infrared_symbol_orange.png";
import Ray from "../General/Ray";
export class InfraredLogo extends Component {
  render() {
    return (
      <div className="InfraredLogoWrapper">
        <div className="ray-animations-wrapper">
          <div className="ir-logo-ray-group">
            <Ray id="Ray1" />
            <Ray id="Ray2" />
            <Ray id="Ray3" />
            <Ray id="Ray4" />
            <Ray id="Ray5" />
          </div>
        </div>
        <div className="infrared-logo-wrapper">
          <img src={Infrared} className="infrared-logo" alt="Infrared Icon" />
        </div>
      </div>
    );
  }
}

export default InfraredLogo;
