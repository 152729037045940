import {
  RED_LIGHT_ADDED,
  UVB_LIGHT_ADDED,
  TOWEL_SERVICE_ADDED,
} from "../../sagas/actions";

const initAccessState = {
  redLightAdded: false,
  uvbLightAdded: false,
  towelServiceAdded: false
};

const addonStatuses = (state = initAccessState, action) => {
  switch (action.type) {
    case RED_LIGHT_ADDED:
      return {
        ...state,
        redLightAdded: true,
      };
    case UVB_LIGHT_ADDED:
      return {
        ...state,
        uvbLightAdded: true,
      };
    case TOWEL_SERVICE_ADDED:
      return {
        ...state,
        towelServiceAdded: true,
      };
    default:
      return state;
  }
};

export default addonStatuses;
