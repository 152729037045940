import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import "./AccessNotWorkingModal.css";
import { GrFormClose } from "react-icons/gr";
import { REQUEST_SAUNA_RESET, SEND_REQUEST } from "../../sagas/actions";
import accessResetApiCall from "../../modules/api/accessResetApiCall";
import MessageBox from "./MessageBox";

export class SaunaNotWorkingModal extends Component {
  modalClassName = (status) => {
    if (status) {
      return "open";
    } else {
      return "closed";
    }
  };
  render() {
    return (
      <div className={`accessModalWrapper accessModalWrapper-${this.modalClassName(this.props.modalStatus)}`}>
        <div className="accessNotWorkingModalTitle">
          <div
            className="accessModalCloseIcon"
            onClick={() => {
              this.props.setSaunaModal(false);
            }}
          >
            <GrFormClose color="white" />
          </div>
        </div>
        <div className="notWorkingModalContent">
          <div>If you are experiencing issues with the sauna, you can try rebooting it by clicking this button.</div>
          <br/>
          <button
            className={"access-button"}
            type="button"
            size="medium"
            // className="AccessButtons"
            style={{ zIndex: 0, minWidth: "150px", minHeight: "50px" }}
            onClick={() => {
              console.log('SaunaNotWorkingModal accessKey: ', this.props.reduxState.access.data.accessKey);
              this.props.requestSaunaReset(this.props.reduxState.access.data.accessKey);
            }}
          >
            REBOOT SAUNA
          </button>
          {this.props.message ? <MessageBox message={this.props.message} /> : <MessageBox message={this.props.message} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
  message: reduxState?.saunaControlsMessageBox?.message || null,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestSaunaReset: (accessKey) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: REQUEST_SAUNA_RESET,
        apiCall: accessResetApiCall,
        apiCallParams: { accessKey: accessKey },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaunaNotWorkingModal);
