import { EI_SET_VIEW_LOCATIONS, EI_SET_VIEW_LOGIN, EI_SET_VIEW_ROOM } from "../../sagas/actions";

const initAccessState = "login";

const eiView = (state = initAccessState, action) => {
  switch (action.type) {
    case EI_SET_VIEW_LOGIN:
      return 'login'
    case EI_SET_VIEW_LOCATIONS:
      return 'locations'
    case EI_SET_VIEW_ROOM:
      return 'room'
    default:
      return 'login';
  }
};

export default eiView;
