import React, { Component } from "react";
import "../Benefits/DesktopBenefits.css";
import "./BlogPageContent.css";
import SWArrow from "../../images/assets/pointer_right.png";
import { SWEATWORKS_URL } from "../../TestingVariables";
const { BlogContent } = require("./BlogContent");

export class BlogPageContent extends Component {
  state = {
    foundBlog: false,
    blogId: null,
  };
  whichBlog = () => {
    const url = window.location.pathname;
    const id = url.replace("/blog/blog_page/", "");
    console.log("id: ", id);
    if (BlogContent[parseInt(id) - 1]) {
      this.setState({
        foundBlog: true,
        blogId: parseInt(id),
      });
    }
  };

  componentDidMount() {
    this.whichBlog();
  }

  navTree = (localActive, benefit) => {
    return (
      <div className="nav-tree">
        <a
          className="sw-color-primary sw-color-primary-hover"
          href={`${SWEATWORKS_URL}/`}
        >
          home
        </a>
        <img className="nav-tree-icon" src={SWArrow} alt="navigation-arrow" />
        <a
          className="sw-color-primary sw-color-primary-hover"
          href={`${SWEATWORKS_URL}/blog`}
        >
          blogs
        </a>
        {localActive ? (
          <img className="nav-tree-icon" alt="navigation-arrow" src={SWArrow} />
        ) : null}
        {localActive ? <strong>{benefit.type}</strong> : null}
      </div>
    );
  };

  createBlogPost = (num) => {
    const benefit = BlogContent[num - 1];
    return (
      <div name={benefit.id} className="blog-post-wrapper" id={benefit.id}>
        {this.navTree(true, benefit)}
        <strong className="blog-title-wrapper  L"> {benefit.title} </strong>
        <div className="blog-content-wrapper  L"> {benefit.content} </div>
        <div className="BenefitSubcontentWrapper"></div>
      </div>
    );
  };

  render() {
    return (
      <div className="blog-page-content">
        {this.state.blogId && this.state.foundBlog
          ? this.createBlogPost(this.state.blogId)
          : null}
        {this.state.foundBlog ? null : (
          <>
            {this.navTree(false)}
            <div className="blog-not-found-wrapper">Oops! Blog Not Found!</div>
          </>
        )}
      </div>
    );
  }
}

export default BlogPageContent;
