import React from "react";
import "./roomViewData.css";
import { connect } from "react-redux";
import DropDownMenu from "../components/DropDownMenu";
import DataPlot from "../components/DataPlot";
import requestEISaunaData from "../../../modules/api/ei/requestEISaunaData";
import { EI_SAUNA_DATA, SEND_REQUEST } from "../../../sagas/actions";

function RoomViewData(props) {
  const handleClick = (staffId, date, dayQuarter) => {
    console.log("staffId, date, timeFrame: ", staffId, date, dayQuarter);
    props.requestUpdateSaunaData(
      props.siteId,
      localStorage.getItem("token"),
      staffId,
      date,
      dayQuarter
    );
  };
  return (
    <div className="ei-room-data-plots-wrapper">
      <strong className="ei-room-header-title">SAUNA DATA: {props.roomName}</strong>
      <div className="ei-room-data-plots-header">
        <div>
          <strong className="ei-room-data-plots-header-title">DATE</strong>
          <input
            value={props.dataDate}
            onChange={(e) => {
              props.set("dataDate", e.target.value);
            }}
          ></input>
        </div>
        <div className="">
          <strong className="ei-room-data-plots-header-title">
            TIME FRAME
          </strong>
          <DropDownMenu set={props.set} dayQuarter={props.dayQuarter} />
        </div>
        <button
          className="ei-room-data-update-button glow-on-hover"
          onClick={() => {
            handleClick(props.staffId, props.dataDate, props.dayQuarter);
          }}
        >
          UPDATE
        </button>
      </div>
      <div className="ei-room-data-plots-group">
        <div className="ei-room-data-plots-temp-group">
          <DataPlot
            data={props.plotData}
            fill="colorBlue"
            domain={{ min: 70, max: 160 }}
            dataKey="temp"
            height={300}
            color={"#ffffff"}
            name={"Sauna Temperature °F"}
            tick={true}
          />
          <DataPlot
            data={props.plotData}
            fill="colorGreen"
            domain={{ min: 0, max: 1 }}
            dataKey="preheatMode"
            height={100}
            color={"#ffffff"}
            name={"Preheat Mode Active"}
            tick={true}
          />
          <DataPlot
            data={props.plotData}
            fill="colorYellow"
            dataKey="heaterRelay"
            domain={{ min: 0, max: 1 }}
            height={100}
            color={"#ffffff"}
            name={"Sauna Heaters"}
            tick={true}
          />
          <DataPlot
            data={props.plotData}
            fill="colorWhite"
            dataKey="saunaPower"
            domain={{ min: 0, max: 1 }}
            height={100}
            color={"#ffffff"}
            name={"Sauna Power (Panel is on)"}
            tick={true}
          />
          <DataPlot
            data={props.plotData}
            fill="colorRed"
            dataKey="activeRedLight"
            domain={{ min: 0, max: 1 }}
            height={100}
            color={"#ffffff"}
            name={"Red Light Engaged"}
            tick={true}
          />
          <DataPlot
            data={props.plotData}
            fill="colorBlack"
            dataKey="online"
            domain={{ min: 0, max: 1 }}
            height={100}
            color={"#ffffff"}
            name={"Sauna Online Status"}
            tick={true}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestUpdateSaunaData: async (siteId, token, staffId, date, dayQuarter) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_SAUNA_DATA,
        apiCall: requestEISaunaData,
        apiCallParams: {
          siteId: siteId,
          token: token,
          staffId: staffId,
          date: date,
          dayQuarter: dayQuarter,
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomViewData);
