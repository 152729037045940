import React, { Component } from "react";
import BenefitsIntro from "../Benefits/BenefitsIntro";
import HomeVideoPlayer from "./HomeVideoPlayer";
import Reviews from "../Reviews/Reviews";
import "./Home.css";
// import FaqCell from "../FAQ/faqCell";
import SWPageTemplate from "../General/Pages/PageTemplate";
// import HomeLocationRow from "../Map/HomeLocationRow";
import BenefitGrid from "../Benefits/BenefitGrid";
// import FaqCell from "../FAQ/faqCell";
import HomeLocationWidget from "../Map/HomeLocationWidget";
import BenefitGridOnyx from "../RedLight/BenefitGridOnyx";

class Home extends Component {
  render() {
    return (
      <div id="HomeContentWrapper">
        <SWPageTemplate>
          <HomeVideoPlayer />
          <BenefitsIntro />
          <HomeLocationWidget widget={false}/>
          <BenefitGrid />
          <BenefitGridOnyx/>
          {/* <FaqCell/> */}
          <Reviews />
        </SWPageTemplate>
      </div>
    );
  }
}

export default Home;
