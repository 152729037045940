import React, { Component } from "react";
import "./Benefits.css";
import InfraredLogo from "./InfraredLogo";

export class BenefitsIntro extends Component {
  render() {
    return (
      <div className="BenefitsIntro">
        <div className="home-intro">
          <div className="benefits-cell-intro-group">
            <div className="BenefitsCellIntroWrapper">
              <InfraredLogo />
              <div className="home-intro-container">
                <div className="BenefitsWhyUse TitleFont">
                  <div className="unlock-healing-power-group">
                    <div className="unlock-healing-power-part ColorWhite">
                      UNLOCK THE
                    </div>
                    <div className="unlock-healing-power-part sw-color-primary-dark">
                      HEALING
                    </div>
                    <div className="unlock-healing-power-part sw-color-secondary-light">
                      POWER
                    </div>
                  </div>
                  <div className="unlock-healing-power-part ColorWhite">
                    OF INFRARED
                  </div>
                </div>
                <div className="BenefitsIntroText ItalicContentFont ColorWhite">
                  Infrared is the healing wavelength of the sun and is essential
                  to life. It is the part of the invisible light spectrum that
                  safely resonates with the human tissue without heating the
                  surrounding air. Since infrared is produced by the human body,
                  infrared is a natural and safe way to heal the body. Using
                  infrared in specific wavelengths gives us the power to heal
                  the entire body.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BenefitsIntro;
