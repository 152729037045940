import axios from "axios";
import { put } from "redux-saga/effects";
import {
  SAUNA_CONTROLS_BOX_EXPIRED,
  SAUNA_CONTROLS_BOX_FAILED,
  SAUNA_CONTROLS_BOX_INVALID,
  SAUNA_CONTROLS_BOX_NOT_YET,
  SAUNA_CONTROLS_BOX_RESET,
} from "../../sagas/actions";
import { SWEATWORKS_API } from "../../TestingVariables";

function* accessResetApiCall(apiCallParams) {
  const accessKey = apiCallParams.accessKey;
  console.log('accessResetApiCall accessKey: ', accessKey);
  const headers = {
    "Content-Type": "application/json",
    accesskey: accessKey,
  };
  const result = yield axios
    .get(`${SWEATWORKS_API}/access/command/reset_sauna`, {headers: headers})
    .then(async (res) => {
      return { data: res?.data, status: res.status };
    })
    .catch((error) => {
      console.log("accessResetApiCall error: ", error);
      return false;
    });
  if (result.status === 204) {
    yield put({ type: SAUNA_CONTROLS_BOX_RESET });
    navigator.vibrate([30]);
    return true;
  } else if (result.status === 210 || result.status === 215) {
    navigator.vibrate([100, 30, 100, 30]);
    if (result.data.error === "Not Started Yet") {
      yield put({ type: SAUNA_CONTROLS_BOX_NOT_YET });
      return false;
    } else if (result.data.error === "Appointment Has Expired") {
      yield put({ type: SAUNA_CONTROLS_BOX_EXPIRED });
      return false;
    } else if (result.data.error === "Credential Invalid") {
      yield put({ type: SAUNA_CONTROLS_BOX_INVALID });
      return false;
    }
  } else {
    yield put({ type: SAUNA_CONTROLS_BOX_FAILED });
    return false;
  }
}

export default accessResetApiCall;
