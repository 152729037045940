import React, { Component } from "react";
import "./EmployeeInterfaceHome.css";
import { connect } from "react-redux";
import genSaunaImage from "../components/getSaunaImage";
import { EI_SAUNAS, EI_UNLOCK_ENTRY, SEND_REQUEST } from "../../../sagas/actions";
import requestEISaunas from "../../../modules/api/ei/requestEISaunas";
import requestEIUnlockEntry from "../../../modules/api/ei/requestEIUnlockEntry";
import Led from "../components/Led";
import { BsFillGearFill } from "react-icons/bs";
// import { LoadingWrapper } from "../../General/LoadingWrapper";
const formatType = require("../formatType");

class EmployeeInterfaceHome extends Component {
  componentDidMount() {
    this.refreshSaunas(this.props.tokenValid);
    this.props.requestSaunas(localStorage.getItem("token"));
    this.props.refreshTokenStatus();
  }
  refreshSaunas = (tokenValid) => {
    if (tokenValid && this.props.eiSaunas.payload.length === 0) {
      this.props.requestSaunas(localStorage.getItem("token"));
    }
  };

  returnLedClass = (activated, status) => {
    if (!activated) {
      return "led-grey";
    } else if (activated && (status === 200 || status === true)) {
      return "led-green";
    } else {
      return "led-red";
    }
  };

  generateRoomRows = (rooms, siteId) => {
    return Array.isArray(rooms)
      ? rooms.map((room, i) => {
          console.log("room: ", room);
          return (
            <div
              key={i}
              className="room-wrapper"
              onClick={() => {
                console.log("generateRoomRows: ", siteId, room.staffid, room.name, room.saunatype);
                this.props.refreshSaunaData(siteId, room.staffid, room.name, room.saunatype);
                this.props.set("view", "room");
              }}
            >
              <div className={`sauna-picture-wrapper-${formatType()}`}>
                <img
                  className="ei-locations-view-sauna-pic"
                  alt={`Sauna_pic_${room.saunatype}`}
                  src={genSaunaImage(room.saunatype)}
                />
              </div>
              <div className={`sauna-content-wrapper-${formatType()}`}>
                <div className={`sauna-room-name`}>{room.name}</div>
              </div>
              <div className="led-container">
                <Led clName={this.returnLedClass(room.activated, room.status)} />
              </div>
            </div>
          );
        })
      : null;
  };
  generateLocations = (locations) => {
    console.log("generateLocations locations: ", locations);
    return (
      Array.isArray(locations) &&
      locations.map((location, i) => {
        return (
          <div key={i} className="location-wrapper">
            <div className="city-name-wrapper">
              <div className="ei-location-title-left-group">
                <Led clName={this.returnLedClass(true, location.status)} />
                <div className="ei-city-name">{location.city}</div>
              </div>
              <div className="ei-location-title-right-group">
                {/* {location.status ? ( */}
                <>
                  {location["access_version"] === "V2" ? (
                    <button
                      onClick={() => {
                        this.props.requestUnlockEntry(
                          location.siteid,
                          location.locationid,
                          "Front Door",
                          localStorage.getItem("token")
                        );
                      }}
                      className="glow-on-hover ei-unlock-door-button"
                    >
                      UNLOCK DOOR
                    </button>
                  ) : null}
                  <div
                    className="ei-home-settings-group"
                    onClick={() => {
                      console.log("clicked location settings: ", location);
                      this.props.refreshLocationData(location.siteid, location.locationid, location.city);
                      this.props.set("view", "location");
                    }}
                  >
                    <div className="ei-city-settings-gear">SETTINGS</div>
                    <BsFillGearFill className="location-title-gear" />
                  </div>
                </>
              </div>
            </div>
            <div className="rooms-wrapper">{this.generateRoomRows(location.rooms, location.siteid)}</div>
          </div>
        );
      })
    );
  };
  render() {
    return (
      <div className="employee-interface-home-wrapper">
        <div className="ei-header-title">SWEATWORKS STUDIO INTERFACE</div>
        {/* <LoadingWrapper
          actionType={EI_SAUNAS}
          stateReader={(reduxState) => {
            console.log('in LoadingWrapper reduxState: ', reduxState);
            const { isLoading, error } = reduxState.eiSaunas;
            console.log("isLoading, error: ", isLoading, error);
            return { isLoading, error };
          }}
        > */}
          <div className="ei-home-content-wrapper">
            {this.generateLocations(this.props.eiSaunas.payload)}
          </div>
        {/* </LoadingWrapper> */}
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  eiSaunas: reduxState.eiSaunas,
});

const mapDispatchToProps = (dispatch) => {
  return {
    requestSaunas: async (token) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_SAUNAS,
        apiCall: requestEISaunas,
        apiCallParams: {
          token: token,
        },
      });
    },
    requestUnlockEntry: async (siteId, locationId, entry, token) => {
      dispatch({
        type: SEND_REQUEST,
        loadActionType: EI_UNLOCK_ENTRY,
        apiCall: requestEIUnlockEntry,
        apiCallParams: {
          token: token,
          siteId: siteId,
          locationId: locationId,
          entry: entry,
        },
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInterfaceHome);
