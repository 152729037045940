import {
  ACCESS_MESSAGE_BOX_DISABLED,
  ACCESS_MESSAGE_BOX_EXPIRED,
  ACCESS_MESSAGE_BOX_FAILED,
  ACCESS_MESSAGE_BOX_INVALID,
  ACCESS_MESSAGE_BOX_NOT_YET,
  ACCESS_MESSAGE_BOX_RESET,
  ACCESS_MESSAGE_BOX_UNAUTHORIZED,
} from "../../sagas/actions";

const initAccessState = {
  message: null,
  buttonsDisabled: false,
};

const accessMessageBox = (state = initAccessState, action) => {
  switch (action.type) {
    case ACCESS_MESSAGE_BOX_RESET:
      return {
        message: null,
        buttonsDisabled: false,
      };
    case ACCESS_MESSAGE_BOX_NOT_YET:
      return {
        message: `Access not started yet! Please refresh this page after the starting time stated below.`,
        buttonsDisabled: true,
      };
    case ACCESS_MESSAGE_BOX_EXPIRED:
      return {
        message: `Access has expired!`,
        buttonsDisabled: true,
      };
    case ACCESS_MESSAGE_BOX_INVALID:
      return {
        message: `Sorry! This pass Is Invalid!`,
        buttonsDisabled: true,
      };
    case ACCESS_MESSAGE_BOX_FAILED:
      return {
        message: `Server Error!`,
        buttonsDisabled: true,
      };
    case ACCESS_MESSAGE_BOX_UNAUTHORIZED:
      return {
        message: `Sorry! This Entry Point Is Unauthorized!`,
        buttonsDisabled: true,
      };
    case ACCESS_MESSAGE_BOX_DISABLED:
      return {
        ...state,
        buttonsDisabled: true,
      };
    default:
      return state;
  }
};

export default accessMessageBox;
