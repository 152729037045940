import React, { Component } from "react";
import { connect } from "react-redux";
import { FAILURE, LOADING, SUCCESS } from "../../sagas/actions";
import { Roller } from "react-awesome-spinners";
import { HiCheck } from "react-icons/hi";
import "./AccessContent.css";
import FailedIcon from "./FailedIcon";

export class UvbButtonStatusIconManager extends Component {
  render() {
    return (
      <div className="ButtonIconWrapper">
        {this.props.reduxState.uvbLightIconStatus.status === LOADING ? (
          <Roller size="36" className="StatusIcon" id="LoadingIcon" />
        ) : null}
        {this.props.reduxState.uvbLightIconStatus.status === SUCCESS ? (
          <HiCheck className="StatusIcon" id="SuccessIcon" />
        ) : null}
        {this.props.reduxState.uvbLightIconStatus.status === FAILURE ? <FailedIcon id="FailedIcon" /> : null}
      </div>
    );
  }
}
const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(UvbButtonStatusIconManager);
