import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import ButtonsWrapper from "./ButtonsWrapper";
import MessageBox from "./MessageBox";
import Greeting from "./Greeting";
import TimeFrameCard from "./TimeFrameCard";
import SweatWorksBackgroundImage from "../../images/SweatworksStudiosHeaderImageTransparent.png";
import { BsChevronCompactDown } from "react-icons/bs";
import NotWorking from "./NotWorking";
import AccessNotWorkingModal from "./AccessNotWorkingModal";
import SaunaNotWorkingModal from "./SaunaNotWorkingModal";
export class Access extends Component {
  state = {
    accessModal: false,
    saunaModal: false,
  };
  setAccessModal = (value) =>
    this.setState({
      accessModal: value,
      saunaModal: false,
    });

  setSaunaModal = (value) =>
    this.setState({
      saunaModal: value,
      accessModal: false,
    });
  render() {
    return (
      <div className="Access" style={{ backgroundColor: "#353d3b", zIndex: 0 }}>
        <img
          className="SweatWorksBackgroundImage"
          alt="SweatWorks Studio Welcome Header"
          src={SweatWorksBackgroundImage}
        />
        <AccessNotWorkingModal modalStatus={this.state.accessModal} setAccessModal={this.setAccessModal} />
        <SaunaNotWorkingModal modalStatus={this.state.saunaModal} setSaunaModal={this.setSaunaModal} />
        <Greeting />
        <ButtonsWrapper />
        {this.props.message ? <MessageBox message={this.props.message} /> : <MessageBox message={this.props.message} />}
        <NotWorking setAccessModal={this.setAccessModal} setSaunaModal={this.setSaunaModal} />
        <TimeFrameCard />
        <div className="AccessSubtext">
          <h4>ADD-ONS</h4>
          <BsChevronCompactDown />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  message: reduxState?.accessMessageBox?.message || null,
});

export default connect(mapStateToProps)(Access);
