import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import AddRedLightView from "./AddRedLightView";
import AlreadyPurchasedRedLight from "./AlreadyPurchasedRedLight";
import ThankYouPurchasedView from "./ThankYouPurchasedView";
import RedLightImage from "../../images/RedLight_2.jpeg";
import { Card } from "../General/Card";

export class RedLight extends Component {
  RedLightComponentManager = () => {
    const redLight = this.props.reduxState.access.data.appointment.redLight;
    if (this.props.reduxState.addonStatuses.redLightAdded) {
      return <ThankYouPurchasedView type="Red Light" towelService={false} />;
    } else if (!redLight) {
      return <AddRedLightView />;
    } else if (redLight) {
      return <AlreadyPurchasedRedLight />;
    }
  };

  render() {
    return (
      <div className="RedLightWrapper">
        <div className="WindowPane"></div>
        <Card header="RED LIGHT THERAPY">
          <img alt="Red Light" src={RedLightImage} id="RedLightImage" />
          {this.props.reduxState.addonStatuses.redLightAdded
            ? this.RedLightComponentManager()
            : this.RedLightComponentManager()}
        </Card>
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  reduxState,
});

export default connect(mapStateToProps)(RedLight);
