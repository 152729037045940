import React, { Component } from "react";
import "./AccessContent.css";
import { connect } from "react-redux";
import Bounce from "react-reveal/Bounce";

export class Greeting extends Component {
  render() {
    return (
      <div className="GreetingWrapper">
        <Bounce left>
          <p id="greeting">
            Welcome{this.props.clientFirstName}
          </p>
        </Bounce>
        <Bounce right>
          <p id="pressButtonText">To unlock an entry point<br/>please tap on the button</p>
        </Bounce>
      </div>
    );
  }
}

const mapStateToProps = (reduxState) => {
  console.log('Greeting reduxState: ', reduxState);
  return {
    clientFirstName: `, ${reduxState.access?.data?.clientFirstName}!` || '!',
    room:  `You've booked ${reduxState.access?.data?.room}` || '',
}};

export default connect(mapStateToProps)(Greeting);
